import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Button, LogoutButton } from '../utilities/Button';
import { NavbarItem } from './NavbarItem';
import { SidebarItem } from '../Sidebar/SidebarItem';
import styles from './Navbar.module.css'
import { motion, useCycle } from 'framer-motion'
import { MenuToggle } from '../utilities/MenuToggle'
import { fetchGetUser } from '../utilities/utilityFetch';


function Navbar({  setUser, user, setCookie }) {

    const [isOpen, toggleOpen] = useCycle(false, true);
    const [button, setButton] = useState(true);          // check if mobile view is active
    const [isLog, setIsLog] = useState(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton()
    }, []);

    useEffect(() => {
        fetchGetUser().then(r => {
            if (r) {
                setUser(r);
                setIsLog(true)
            }
        })
    }, [setUser, setCookie])

    useEffect(() => {
        if (user)
            setIsLog(true)
    }, [user])

    window.addEventListener('resize', showButton);


    if (button) {           //MOBILE NO ACTIVE
        return (
            <>
                <motion.nav className={sessionStorage.getItem('isMan') === 'true' ? styles.navbar_manage : styles.navbar}
                /*  initial={{ opacity: 0, y: '-100vh' }}
                 animate={{ opacity: 1, y: '0' }}
                 transition={{ ease: "easeOut", duration: 0.4, type: "spring", mass: 0.5 }} */
                >
                    <div className={styles.navbar_container}>
                        <Link to='/' className={styles.logo_container} onClick={() => { toggleOpen(); sessionStorage.setItem('isMan', false) }}>
                            <img src='/images/islab_logo.png' alt='src' className={styles.logo} />
                        </Link>
                        <div className={styles.logo_container} onClick={() => window.open("https://unimi.it", "_blank")}>
                            <img src='/images/unimi_scritta.png' alt='src' className={styles.logo_unimi} />
                        </div>


                        <ul className={styles.nav_menu}>
                            {
                                // these are the  navbar and sidebar items when mobile view there isn't active 
                                NavbarItem.map((item, index) =>
                                    <motion.li /* className={styles.nav_item} */
                                        key={index}
                                        whileHover={{ scale: 1.3 }}
                                        transition={{ type: 'spring', stiffness: 300, duration: 1 }}
                                    >
                                        <Link to={item.path} className={sessionStorage.getItem('isMan') === 'true' ? styles.nav_links_manage : styles.nav_links} onClick={() => { toggleOpen(); sessionStorage.setItem('isMan', false) }}>
                                            {item.icon}
                                            <span className={styles.span}>
                                                {item.title}
                                            </span>
                                        </Link>
                                    </motion.li>
                                )
                            }
                        </ul>

                        {
                            //this is choose for logout, login or account
                            sessionStorage.getItem('isMan') === 'true' ?
                                <LogoutButton buttonStyle='btn--outline' onClick={() => { toggleOpen(); sessionStorage.setItem('isMan', false) }} />
                                :
                                isLog ?
                                    <Button buttonStyle='btn--outline' children={user?.name} destinationPath='/manage/myuser' onClick={() => { toggleOpen(); sessionStorage.setItem('isMan', true) }} />
                                    :
                                    <Button buttonStyle='btn--outline' children='LOGIN' destinationPath='/login' />

                        }

                    </div>
                </motion.nav>
                <motion.div className={sessionStorage.getItem('isMan') === 'true' ? '' : `${styles.navbar} ${styles.navbar_orange}`}
                    /* initial={{ opacity: 0, y: '-100vh' }}
                    animate={{ opacity: 1, y: '0' }}
                    transition={{ ease: "easeOut", duration: 0.5, type: 'spring', mass: 0.5 }}  *//>
            </>
        );


    }
    else {       //MOBILE ACTIVE
        return (
            <>
                <nav className={sessionStorage.getItem('isMan') === 'true' ? styles.navbar_manage : styles.navbar}>
                    <div className={styles.navbar_container}>
                        <Link to='/' className={styles.logo_container} >
                            <img src='/images/islab_logo.png' alt='src' className={styles.logo} />
                        </Link>

                        <div className={styles.logo_unimi_container} onClick={() => window.open("https://unimi.it", "_blank")}>
                            <img src='/images/unimi_scritta.png' alt='src' className={styles.logo_unimi} />
                        </div>

                        <div className={styles.nav_menu_icon} >
                            <motion.div
                                initial={false}
                                animate={isOpen ? "open" : "closed"}
                            >
                                <MenuToggle toggle={() => toggleOpen()} className={styles.burgerIcon} />
                            </motion.div>
                            {/* <i className={click ? `fas fa-times` : `fas fa-bars`} /> */}
                        </div>
                        <ul className={sessionStorage.getItem('isMan') === 'true' ?
                            isOpen ?
                                `${styles.nav_menu}  ${styles.nav_menu_active_manage} ${styles.nav_menu_active}`
                                :
                                `${styles.nav_menu} ${styles.nav_menu_manage}`
                            : isOpen ?
                                `${styles.nav_menu} ${styles.nav_menu_active}`
                                :
                                `${styles.nav_menu}`
                        }>
                            <div className={styles.wrap_nav_menu}>


                                {
                                    // these are the  navbar and sidebar items when mobile view there isn't active

                                    sessionStorage.getItem('isMan') === 'true' ?
                                        SidebarItem.map((item, index) => {
                                            if (user?.admin) {
                                                return (
                                                    <li className={styles.nav_item} key={index}>
                                                        <Link to={item.path} className={sessionStorage.getItem('isMan') === 'true' ? styles.nav_links_manage : styles.nav_links} onClick={() => toggleOpen()}>
                                                            {item.icon} <span className={styles.span}>{item.title}</span>
                                                        </Link>
                                                    </li>
                                                );
                                            }
                                            else {
                                                if (item.freeAccess) {
                                                    return (
                                                        <li className={styles.nav_item} key={index}>
                                                            <Link to={item.path} className={sessionStorage.getItem('isMan') === 'true' ? styles.nav_links_manage : styles.nav_links} onClick={() => toggleOpen()}>
                                                                {item.icon} <span className={styles.span}>{item.title}</span>
                                                            </Link>
                                                        </li>
                                                    );
                                                }
                                                else return null;
                                            }
                                        })

                                        :
                                        NavbarItem.map((item, index) =>
                                            <li className={styles.nav_item} key={index}>
                                                <Link to={item.path} className={sessionStorage.getItem('isMan') === 'true' ? styles.nav_links_manage : styles.nav_links} onClick={() => toggleOpen()}>
                                                    {item.icon} <span className={styles.span}>{item.title}</span>
                                                </Link>
                                            </li>
                                        )
                                    // this is for button login in mobile screen 
                                }
                                
                                {
                                    
                                    isLog ?
                                        sessionStorage.getItem('isMan') === 'true' ?
                                            <li>
                                                <Link to="/" className={styles.nav_links_mobile} onClick={() => { toggleOpen(); sessionStorage.setItem('isMan', false) }}>
                                                    <LogoutButton type='mobile' />
                                                </Link>
                                            </li>
                                            :
                                            <li>
                                                <Link to="/manage/myuser" className={styles.nav_links_mobile} onClick={() => { toggleOpen(); sessionStorage.setItem('isMan', true) }}>
                                                    {user?.name}
                                                </Link>
                                            </li>
                                        :
                                        <li>
                                            <Link to="/login" className={styles.nav_links_mobile} onClick={() => toggleOpen()}>
                                                LOGIN
                                            </Link>
                                        </li>
                                }
                            </div>
                        </ul>

                    </div>
                </nav>
                <div className={sessionStorage.getItem('isMan') === 'true' ? '' : `${styles.navbar} ${styles.navbar_orange}`} />
            </>
        );

    }
}

export default Navbar
