import React, { useState } from 'react';
import Modal, { ModalFooter } from '../../utilities/Modal';
import { useForm, useWatch } from "react-hook-form";
import { InputText, regex, TextArea, Switch } from '../../utilities/InputForm';
import { Btn } from '../../utilities/Button';
import { FaSave } from 'react-icons/fa';
import { AiFillSwitcher } from 'react-icons/ai';
import { MdTitle, MdSubtitles, MdInsertLink, MdDescription } from 'react-icons/md';
import ChangePhoto from '../../utilities/ChangePhoto';
import DragDrop from '../../utilities/DragDrop';
import styles from './UpdateProject.module.css';
import { fetchSetProject, fetchUploadPhoto, fetchUploadProjectFiles } from '../../utilities/utilityFetch';


function CreateProject({ showModal, setShowModal, setAlert, position, setProjects }) {
  const [loadingIcon, setLoadingIcon] = useState(false);

  const defaultValues = {
    title: '',
    link: '',
    description: '',
    img_URL: '',
    html_switch: true,
    id: 0,
  }

  const [img, setImg] = useState();

  const [files, setFiles] = useState([]);

  const { control, handleSubmit } = useForm({
    defaultValues: defaultValues,
    mode: 'onChange'
  });

  const html_switch = useWatch({
    control,
    name: 'html_switch'
  })


  const onSubmit = data => {
    setLoadingIcon(true)

    data.id = position;
    data.html_switch = Boolean(data.html_switch);
    data.title = data.title.trim()

    if (img) {
      fetchUploadPhoto(img, data.title, 'project')
        .then(resImg => {
          data.img_URL = resImg.img_URL
          if (resImg.success) {
            if (!(html_switch)) {
              if (files.length > 0) {
                fetchSetProject(data)
                  .then(respUpdate => {
                    if (respUpdate.success) {
                      fetchUploadProjectFiles(files, data.title)
                        .then(resHTML =>
                          setAlert(s => ({ ...s, active: true, message: resHTML.success ? respUpdate.message : resHTML.message, type: resHTML.success ? 'success' : 'wrong' })))
                    }
                    else {
                      setAlert(s => ({ ...s, active: true, message: respUpdate.message, type: 'wrong' }))
                    }
                    setLoadingIcon(false)
                  }).then(window.location.reload())
              } else {
                fetchSetProject(data)
                  .then(respUpdate => {
                    setAlert(s => ({ ...s, active: true, message: respUpdate.message, type: respUpdate.success ? 'success' : 'wrong' }))
                    setLoadingIcon(false)
                  })
              }
            }
            else {
              fetchSetProject(data)
                .then(respUpdate => {
                  setAlert(s => ({ ...s, active: true, message: respUpdate.message, type: respUpdate.success ? 'success' : 'wrong' }))
                  setLoadingIcon(false)
                })
            }

          }
          else {
            setAlert(s => ({ ...s, active: true, message: resImg.message, type: 'wrong' }))
            setLoadingIcon(false)
          }
        })
    }
    else {
      if (!(html_switch)) {
        if (files.length > 0) {
          fetchSetProject(data)
            .then(respUpdate => {
              if (respUpdate.success) {
                fetchUploadProjectFiles(files, data.title)
                  .then(resHTML =>
                    setAlert(s => ({ ...s, active: true, message: resHTML.success ? respUpdate.message : resHTML.message, type: resHTML.success ? 'success' : 'wrong' })))
              }
              else {
                setAlert(s => ({ ...s, active: true, message: respUpdate.message, type: 'wrong' }))
              }
              setLoadingIcon(false)
            }).then(window.location.reload())


        } else {
          fetchSetProject(data)
            .then(respUpdate => {
              setAlert(s => ({ ...s, active: true, message: respUpdate.message, type: respUpdate.success ? 'success' : 'wrong' }))
              setLoadingIcon(false)
            }).then(window.location.reload())
        }
      }
      else {
        fetchSetProject(data)
          .then(respUpdate => {
            setAlert(s => ({ ...s, active: true, message: respUpdate.message, type: respUpdate.success ? 'success' : 'wrong' }))
            setLoadingIcon(false)
          }).then(window.location.reload())
      }
    }
  }


  return (
    <Modal showModal={showModal} setShowModal={setShowModal} title={'Create Project'} manage={true}>
      {loadingIcon && <div className='loader' style={{ position: 'absolute', margin: '50% 50% 50% 45%', zIndex: 1000 }} />}

      <div style={loadingIcon ? { opacity: 0.4, marginTop: '2rem' } : { marginTop: '2rem' }}>
        <ChangePhoto image={img} setImage={setImg} />
      </div>
      <div className='form-wrapper' >
        <Input
          control={control}
          html_switch={html_switch}
          setFiles={setFiles}
        />
      </div>
      <ModalFooter>
        <Btn size={'btn--sm'} styles={'btn--save'} onClick={handleSubmit(onSubmit)}>
          <FaSave /> Create
        </Btn>
      </ModalFooter>
    </Modal >
  );
}

export default CreateProject


const Input = (props) => {

  return (
    <>
      <InputText
        name={'title'}
        placeholder={'Title'}
        label={<MdTitle className='icon' />}
        control={props.control}
        rules={{
          required: `Title is required.`,
          minLength:
          {
            value: 3,
            message: `Title must exceed 2 characters`
          },
          validate: {
            checkUserApi: async (x) =>
              await fetch(`/manage/project-exist?title=${x}`, {
                method: 'GET',
                credentials: 'include',
              })
                  .then(r => {                    //il primo then ritorna la promise relativa allo stato
                      if (r.status === 200) {
                          return r.json();
                      } else {
                          throw new Error('Error');
                      }
                  })
                  .then((res) => res.success) || 'Project just Exist'
          }
        }}
      />
      <InputText
        name={'subtitle'}
        placeholder={'Subtitle'}
        label={<MdSubtitles className='icon' />}
        control={props.control}
        rules={{
          required: `Title is required.`,
          minLength:
          {
            value: 3,
            message: `Title must exceed 2 characters`
          }
        }}
      />
      <Switch
        name={'html_switch'}
        type={'checkbox'}
        control={props.control}
        label={<AiFillSwitcher className='icon' />}
        content_t={"LINK"}
        content_f={"HTML"}
      />
      {props.html_switch ?

        <InputText
          name={'link'}
          placeholder={'Link'}
          type={'link'}
          control={props.control}
          label={<MdInsertLink className='icon' />}
          rules={{
            pattern:
            {
              value: regex['link'],
              message: `Insert valid format of Link`
            },
            minLength:
            {
              value: 3,
              message: `Link must exceed 2 characters`
            }
          }}
        />
        :
        <DragDrop setFiles={props.setFiles} />
      }

      <TextArea
        name='description'
        placeholder='Enter your description here...'
        control={props.control}
        label={<MdDescription className='icon' />}
        className={styles.textAreaContainer}
        inputClassName={styles.textArea}
      //className='my__user__card__item__description'
      />

    </>
  )

}