import React, { useState, useEffect } from 'react'
import Modal, { ModalFooter } from '../../utilities/Modal';
import { useForm, useWatch } from "react-hook-form"
import { InputText, regex, TextArea, Switch } from '../../utilities/InputForm';
import { Btn } from '../../utilities/Button';
import { FaSave, FaCheck, FaPlusCircle } from 'react-icons/fa'
import { AiFillSwitcher } from 'react-icons/ai';
import { MdTitle, MdSubtitles, MdInsertLink, MdDescription, MdCancel, MdRemoveCircle } from 'react-icons/md';
import ChangePhoto from '../../utilities/ChangePhoto'
import AlertFade from '../../utilities/Alert';
import DragDrop from '../../utilities/DragDrop';
import styles from './UpdateProject.module.css'
import { fetchDeleteProjectFile, fetchGetFiles, fetchUpdateProject, fetchUploadPhoto, fetchUploadProjectFiles } from '../../utilities/utilityFetch';


export function UpdateProject({ showModal, setShowModal, editProject, setProjects }) {

  const edit = { ...editProject }

  const [img, setImg] = useState();
  const [HTML_check, setHTML_check] = useState(false)

  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [loadingIcon, setLoadingIcon] = useState(false);

  const [selectFile, setSelectFile] = useState();

  const defaultValues = {
    description: "",
    file: "",
    html_switch: false,
    img_URL: "",
    link: "",
    subtitle: "",
    title: ""
  }

  const { control, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
    mode: 'onChange'
  });

  const [alert, setAlert] = useState({
    active: false,
    message: '',
    type: '',
    time: true
  })

  const html_switch = useWatch({
    control,
    name: 'html_switch'
  })

  useEffect(() => {

    reset(editProject);
    var path = editProject?.file

    if (path !== undefined && path !== '') {
      fetchGetFiles(path).then(r => r.success &&
        setFiles(() => {
          var list = [];
          r.files.map((item, index) => {
            list.push({ 'id': index, 'file': item })
            return list
          });
          return list;
        }))
    }
    else {
      setFiles([])
    }
  }, [editProject, reset])

  useEffect(() => {
    setHTML_check(false)
    files.map(item => {
      if (item?.file?.includes('.html'))
        setHTML_check(true)
      return null
    })

  }, [files])

  const onSubmit = data => {
    setLoadingIcon(true)
    data.title = edit.title

    if (img) {
      fetchUploadPhoto(img, data.title, 'project')
        .then(resImg => {
          data.img_URL = resImg.img_URL
          if (resImg.success) {
            if (!(html_switch)) {
              for (var i = 0; i < newFiles.length; i++) {
                files.push(newFiles[i])
              }
              if (files.length > 0) {
                fetchUpdateProject(data)
                  .then(respUpdate => {
                    if (respUpdate.success) {
                      fetchUploadProjectFiles(files, data.title)
                        .then(resHTML =>
                          setAlert(s => ({ ...s, active: true, message: resHTML.success ? respUpdate.message : resHTML.message, type: resHTML.success ? 'success' : 'wrong' })))
                    }
                    else {
                      setAlert(s => ({ ...s, active: true, message: respUpdate.message, type: 'wrong' }))
                    }
                    setLoadingIcon(false)
                  }).then(window.location.reload())

              } else {
                fetchUpdateProject(data)
                  .then(respUpdate => {
                    setAlert(s => ({ ...s, active: true, message: respUpdate.message, type: respUpdate.success ? 'success' : 'wrong' }))
                    setLoadingIcon(false)
                  }).then(window.location.reload())
              }
            }
            else {
              fetchUpdateProject(data)
                .then(respUpdate => {
                  setAlert(s => ({ ...s, active: true, message: respUpdate.message, type: respUpdate.success ? 'success' : 'wrong' }))
                  setLoadingIcon(false)
                }).then(window.location.reload())
            }

          }
          else {
            setAlert(s => ({ ...s, active: true, message: resImg.message, type: 'wrong' }))
            setLoadingIcon(false)
          }
        })
    }
    else {
      if (!(html_switch)) {
        for (var i = 0; i < newFiles.length; i++) {
          files.push(newFiles[i])
        }
        if (files.length > 0) {
          fetchUpdateProject(data)
            .then(respUpdate => {
              if (respUpdate.success) {
                fetchUploadProjectFiles(files, data.title)
                  .then(resHTML =>
                    setAlert(s => ({ ...s, active: true, message: resHTML.success ? respUpdate.message : resHTML.message, type: resHTML.success ? 'success' : 'wrong' })))
                  .then(window.location.reload())
              }
              else {
                setAlert(s => ({ ...s, active: true, message: respUpdate.message, type: 'wrong' }))
              }
              setLoadingIcon(false)
            })

        } else {
          fetchUpdateProject(data)
            .then(respUpdate => {
              setAlert(s => ({ ...s, active: true, message: respUpdate.message, type: respUpdate.success ? 'success' : 'wrong' }))
              setLoadingIcon(false)
            }).then(window.location.reload())
        }
      }
      else {
        fetchUpdateProject(data)
          .then(respUpdate => {
            setAlert(s => ({ ...s, active: true, message: respUpdate.message, type: respUpdate.success ? 'success' : 'wrong' }))
            setLoadingIcon(false)
          }).then(window.location.reload())
      }
    }
  }

  const deleteClick = () => {
    fetchDeleteProjectFile(selectFile, editProject?.title).then(respDel => {
      if (respDel.success) {
        setAlert(s => ({ ...s, active: false, }))
        fetchGetFiles(editProject?.file).then(r => r.success &&
          setFiles(() => {
            var list = [];
            r.files.map((item, index) =>
              list.push({ 'id': index, 'file': item })
            );
            return list;
          })).then(window.location.reload())
      } else {
        setAlert(s => ({ ...s, active: true, message: respDel.message, type: 'wrong', time: true }))
      }
    })
  }

  return (
    <>


      <Modal showModal={showModal} setShowModal={setShowModal} title={`Update ${edit.title}`} manage={true}>
        {loadingIcon && <div className='loader' style={{ position: 'absolute', margin: '50% 50% 50% 45%', zIndex: 1000 }} />}
        <div style={loadingIcon ? { opacity: 0.4, marginTop: '2rem' } : { marginTop: '2rem' }}>
          <ChangePhoto image={edit.img_URL} setImage={setImg} />
        </div>

        <div className={styles.form_wrapper} style={loadingIcon ? { opacity: 0.4 } : {}} >
          <Input
            title={editProject?.title}
            control={control}
            html_switch={html_switch}
            HTML_check={HTML_check}
            setFiles={setFiles}
            files={files}
            selectFile={selectFile}
            setSelectFile={setSelectFile}
            setNewFiles={setNewFiles}
            setAlert={setAlert}
          />
        </div>

        <ModalFooter>
          <Btn size={'btn--sm'} styles={'btn--save'} onClick={handleSubmit(onSubmit)}>
            <FaSave /> Save
          </Btn>
        </ModalFooter>
      </Modal >

      <AlertFade
        showAlert={alert.active}
        onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
        message={alert.message}
        type={alert.type}
        time={alert.time}
      >
        {alert.type === 'warning' ?
          <div>
            <MdRemoveCircle className={'icon-btn'} color='var(--wrong)' style={{ left: '10%' }} onClick={() => setAlert(s => ({ ...s, active: false }))} />
            <FaCheck className={'icon-btn'} color='var(--success)' style={{ right: '10%' }} onClick={deleteClick} />
          </div>
          :
          null}
      </AlertFade>
    </>
  );
}

export default UpdateProject


const Input = (props) => {

  const [showDrag, setShowDrag] = useState(false);

  const handleClick = (item) => {
    props.setAlert(s => ({
      ...s,
      active: true,
      type: 'warning',
      message: `Do you Want Delete ${item} ?`,
      time: false
    }));
    props.setSelectFile(item)
  }

  return (
    <>
      <InputText
        name={'title'}
        placeholder={'Title'}
        label={<MdTitle className='icon' />}
        control={props.control}
        disabled={true}
      />

      <InputText
        name={'subtitle'}
        placeholder={'Subtitle'}
        label={<MdSubtitles className='icon' />}
        control={props.control}
        rules={{
          required: `Title is required.`,
          minLength:
          {
            value: 3,
            message: `Title must exceed 2 characters`
          }
        }}
      />

      <TextArea
        name='description'
        placeholder='Enter your description here...'
        control={props.control}
        label={<MdDescription className='icon' />}
        className={styles.textAreaContainer}
        inputClassName={styles.textArea}
      />
      <Switch
        name={'html_switch'}
        type={'checkbox'}
        control={props.control}
        label={<AiFillSwitcher className='icon' />}
        content_t={"LINK"}
        content_f={"HTML"}
      />

      {
        props.html_switch ?

          <InputText
            name={'link'}
            placeholder={'Link'}
            type={'link'}
            control={props.control}
            label={<MdInsertLink className='icon' />}
            rules={{
              pattern:
              {
                value: regex['link'],
                message: `Insert valid format of Link`
              },
              minLength:
              {
                value: 3,
                message: `Link must exceed 2 characters`
              }
            }}
          />
          :
          <div style={{ marginTop: '1rem' }}>
            {
              props.files.map((item, key) => {

                return (
                  <div className={styles.files_container} key={key}>
                    <div className={styles.files_grid}>
                      <li id={item} children={<MdCancel className={styles.delete_icon} onClick={() => handleClick(item.file)} />} />
                      <li className={styles.text} children={item.file} />
                    </div>
                  </div>
                );
              })
            }

            <FaPlusCircle className={showDrag ? styles.close : styles.addIcon} onClick={() => setShowDrag(!showDrag)} />
            {showDrag && <DragDrop setFiles={props.setNewFiles} setAlert={props.setAlert} HTML_check={props.HTML_check} setShowDrag={setShowDrag} />}

          </div>
      }

    </>
  )
}
