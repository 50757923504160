
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import Modal, { ModalFooter } from '../../utilities/Modal';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { MdRemoveCircle, MdCancel, MdTitle } from "react-icons/md";
import { FaCheck, FaEdit, FaSave } from 'react-icons/fa';
import AlertFade from '../../utilities/Alert';
import { Btn } from '../../utilities/Button';
import { InputText } from '../../utilities/InputForm';

function EditMenuTab({ showModal, setShowModal, info }) {
    const { control, handleSubmit } = useForm({ mode: 'onChange' });

    const [selectMenuItem, setSelectMenuItem] = useState()

    const [menu, setMenu] = useState([])

    const [alert, setAlert] = useState({
        active: false,
        message: '',
        type: '',
        time: true,
        delete: false
    })

    useEffect(() => {
        var temp = new Array(...info.menu);
        temp.shift();
        setMenu(temp);
    }, [info])

    useEffect(() => {

    }, [menu])

    const handleClick = (item, del) => {
        setAlert(s => ({
            ...s,
            active: true,
            type: 'warning',
            message: `Do you Want ${del ? 'Delete' : 'Change'} ${item.name} ?`,
            time: false,
            delete: del
        }));

        setSelectMenuItem(item)
    }

    const confirmClick = (data) => {
        var temp = new Array(...menu);
        var i = temp.indexOf(selectMenuItem)

        if (alert.delete) {
            temp.splice(i, 1)
            setMenu(temp)
        }

        else {
            setMenu(menu.map((item, index) => {
                if (index === i) {
                    return {
                        ...item,
                        name: data['tabName'],
                    }
                }
                else return item;
            }));
        }
        setAlert(s => ({ ...s, active: false }))

    }

    const handleRLDDChange = (newItems) => {
        setMenu(newItems)
    }

    function sort() {
        var temp = menu;

        /* temp.map((item, index) => {
            item.id = index + 1
        }) */
        return temp
    }

    const clickSave = () => {
        var array = new Array(info.menu[0]);
        var temp = sort();
        temp.map(item =>
            array.push(item)
        )

        fetch(`/manage/update-menu-tab`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'menu': array, 'email': info.email })
        })
            .then(r => {                    //il primo then ritorna la promise relativa allo stato
                if (r.status === 200) {
                    return r.json();
                } else {
                    setAlert(s => ({ ...s, active: true, message: r.message, type: 'wrong' }));
                    throw new Error('Error');
                }
            })
            .then((res) => {       //il secondo il risultato
                if (res.success === true) {
                    window.location.reload();
                }
                else {
                    setAlert(s => ({ ...s, active: true, message: res.message, type: 'wrong' }));
                }
            })
            .catch((err) => {
                ;
            });




    }

    return (
        <>
            <Modal showModal={showModal} setShowModal={setShowModal} title={'Edit Tab'} manage={true}>
                <RLDD
                    items={menu}
                    //cssClasses="list-container"
                    itemRenderer={(item) => {
                        return (
                            <>
                                <div className="tab-info-files-container">
                                    <div className="tab-info-grid triple">
                                        <li>
                                            <MdCancel className={'tab-icon'} onClick={() => handleClick(item, true)} />
                                        </li>
                                        <li className='text'>
                                            {item.name}
                                        </li>
                                        <li>
                                            <FaEdit className={'edit-icon'} onClick={() => handleClick(item, false)} />
                                        </li>
                                    </div>
                                </div>
                            </>
                        );
                    }}
                    onChange={handleRLDDChange}
                />

                <ModalFooter>
                    <Btn size={'btn--sm'} styles={'btn--save'} onClick={clickSave} >
                        <FaSave /> Save
                    </Btn>
                </ModalFooter>

            </Modal >

            <AlertFade
                showAlert={alert.active}
                onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
                message={alert.message}
                type={alert.type}
                time={alert.time}
            >

                {alert.type === 'warning' ?
                    alert.delete ?
                        <div>
                            <MdRemoveCircle className={'icon-btn'} color='var(--wrong)' style={{ left: '10%' }} onClick={() => setAlert(s => ({ ...s, active: false }))} />
                            <FaCheck className={'icon-btn'} color='var(--success)' style={{ right: '10%' }} onClick={confirmClick} />
                        </div>
                        :
                        <>
                            <div className='edit-tab-input'>
                                <InputText
                                    name={'tabName'}
                                    placeholder={'Tab Name'}
                                    control={control}
                                    errorClass='error'
                                    label={<MdTitle className='icon' />}
                                    rules={{
                                        required: `Tab Name is required.`,
                                        minLength:
                                        {
                                            value: 3,
                                            message: `Tab Name must exceed 2 characters`
                                        },
                                        validate: {
                                            checkUserApi: async (x) =>
                                                await fetch(`/manage/menu-tab-exist`, {
                                                    method: 'GET',
                                                    credentials: 'include',
                                                    headers: {
                                                        'Accept': 'application/json',
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({ 'tabName': x, 'email': info.email })
                                                })
                                                    .then(r => {                    //il primo then ritorna la promise relativa allo stato
                                                        if (r.status === 200) {
                                                            return r.json();
                                                        } else {
                                                            setAlert(s => ({ ...s, active: true, message: 'Error', type: 'wrong' }));
                                                            throw new Error('Error');
                                                        }
                                                    })
                                                    .then((res) => {       //il secondo il risultato
                                                        return res.success
                                                    }).catch((err) => {
                                                        ;
                                                    }) || 'Tab just Exist'
                                        }
                                    }}
                                />
                            </div>
                            <MdRemoveCircle className={'icon-btn'} color='var(--wrong)' style={{ left: '10%' }} onClick={() => setAlert(s => ({ ...s, active: false }))} />
                            <FaCheck className={'icon-btn'} color='var(--success)' style={{ right: '10%' }} onClick={handleSubmit(confirmClick)} />
                        </>
                    :
                    null}
            </AlertFade>
        </>
    );
}

export default EditMenuTab

