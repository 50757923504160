import { FaUniversity, FaUser, FaPhone, FaPencilRuler } from 'react-icons/fa'
import { MdEmail, MdDescription, MdTitle, MdSubtitles, MdRssFeed } from 'react-icons/md'
import { RiAdminFill, RiLockPasswordFill, RiKeyFill } from 'react-icons/ri'
import { AiFillEyeInvisible , AiOutlineStop} from 'react-icons/ai'
import { ImLink } from 'react-icons/im'

export const MyUserForm = [
    {
        name: 'admin',
        type: 'checkbox',
        message: 'Admin required',
        icon: <RiAdminFill className='icon' />,
        className: 'my__user__card__item',
        userAdmin: true,
        required: false
    },

    {
        name: 'active',
        type: 'checkbox',
        message: 'Admin required',
        icon: <AiOutlineStop className='icon' />,
        className: 'my__user__card__item',
        userAdmin: true,
        required: false
    },

    {
        name: 'visible',
        type: 'checkbox',
        message: 'Admin required',
        icon: <AiFillEyeInvisible className='icon' />,
        className: 'my__user__card__item',
        userAdmin: true,
        required: false
    },
   
    {
        type: 'text',
        name: 'name',
        message: 'Name required',
        placeholder: 'Name',
        icon: <FaUser className='icon' />,
        userAdmin: false,
        required: true,
    },
    {
        name: 'channel',
        type: 'text',
        message: 'Channel required',
        placeholder: 'News Channel',
        icon: <MdRssFeed className='icon' />,
        className: 'my__user__card__item',
        userAdmin: false,
        required: false
    },
    {
        type: 'text',
        name: 'role',
        message: 'Role required',
        placeholder: 'Role',
        icon: <FaPencilRuler className='icon' />,
        userAdmin: false,
        required: false
    },
    {
        name: 'email',
        type: 'email',
        message: 'Email required',
        placeholder: 'Email',
        icon: <MdEmail className='icon' />,
        userAdmin: false,
        required: true,
        disabled: true
    },
    {
        name: 'phone',
        type: 'tel',
        pattern: "[0-9]{7}",
        message: 'Phone required',
        placeholder: 'Phone Ex: 3455949503',
        icon: <FaPhone className='icon' />,
        className: 'my__user__card__item',
        userAdmin: false,
        required: false,
    },
    {
        name: 'address',
        type: 'text',
        message: 'Address required',
        placeholder: 'Address',
        icon: <FaUniversity className='icon' />,
        className: 'my__user__card__item',
        userAdmin: false,
        required: false
    },
    {
        name: 'description',
        type: 'text',
        message: 'Description required',
        placeholder: 'Enter your description here...',
        icon: <MdDescription className='icon' />,
        className: 'my__user__card__item__description',
        userAdmin: false,
        required: false
    },
   
   
];

export const pswForm = [
    {
        type: 'password',
        name: 'oldPsw',
        message: 'Old Password is required',
        placeholder: 'Old Password',
        label: <RiLockPasswordFill className='icon' />,
        required: true,
        admin: false
    },
    {
        type: 'password',
        name: 'newPsw',
        message: 'New Password is required',
        placeholder: 'New Password',
        label: <RiKeyFill className='icon' />,
        required: true,
        admin: true
    },
    {
        type: 'password',
        name: 'confirmPsw',
        message: 'Confirm Password is required',
        placeholder: 'Confirm Password',
        label: <RiKeyFill className='icon' />,
        required: true,
        admin: true
    },
];

export const newUserForm = [
    {
        name: 'admin',
        type: 'checkbox',
        message: 'Admin required',
        icon: <RiAdminFill className='icon' />,
        className: 'my__user__card__item',
    },
    {
        type: 'text',
        name: 'name',
        message: 'name Password is required',
        placeholder: 'Name',
        icon: <FaUser className='icon' />,
    },
    {
        type: 'email',
        name: 'email',
        message: 'Email is required',
        placeholder: 'Email',
        icon: <MdEmail className='icon' />,
    },
    {
        type: 'password',
        name: 'password',
        message: 'Password is required',
        placeholder: 'Password',
        icon: <RiKeyFill className='icon' />,
    },
]

export const newProjectForm = [
    {
        type: 'text',
        name: 'title',
        placeholder: 'Title',
        icon: <MdTitle className='icon' />,
        disabled: true,
        required: true,
    },
    {
        type: 'text',
        name: 'Subtitle',
        placeholder: 'Subtitle',
        icon: <MdSubtitles className='icon' />,
        required: true,
    },
    {
        type: 'link',
        name: 'link',
        placeholder: 'Link',
        icon: <ImLink className='icon' />,
        required: true,
    },
    {
        name: 'description',
        type: 'text',
        message: 'Description required',
        placeholder: 'Enter your description here...',
        icon: <MdDescription className='icon' />,
        className: 'my__user__card__item__description',
        required: false
    },
];