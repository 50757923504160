import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SidebarItem } from './SidebarItem';
import styles from './Sidebar.module.css'
import { IconContext } from 'react-icons';


export default function Sidebar(props) {

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);


    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <nav className={styles.side__menu}>
                    <ul className={styles.side__menu__items} onClick={showSidebar}>
                        {SidebarItem.map((item, index) => {
                            if (props.user?.admin) {
                                return (
                                    <li key={index} className={styles.side__text}>
                                        <Link to={item.path} >
                                            {item.icon}
                                            <span className={styles.span} id={item.title}>{item.title}</span>
                                        </Link>
                                    </li>
                                );
                            }
                            else {
                                if (item.freeAccess) {
                                    return (
                                        <li key={index} className={styles.side__text}>
                                            <Link to={item.path} >
                                                {item.icon}
                                                <span className={styles.span} id={item.title}>{item.title}</span>
                                            </Link>
                                        </li>
                                    );
                                }
                                else return null
                            }
                        })}
                    </ul>
                </nav>
            </IconContext.Provider>
        </>
    )
}