
import React from 'react';
import './DragDrop.css'



function DragDrop(props) {

    const onChangeHandler = (event) => {
        var files = event.target.files

        if (checkMimeType(event)) {
            // if return true allow to setState
            if (props.HTML_check)

                if (checkHTML(event)) {
                    props.setAlert(s => ({ ...s, active: true, message: 'The HTML file already exists, before uploading a new file you need to remove the old one', type: 'wrong' }));
                    props.setShowDrag(false)
                }
                else
                    props.setFiles(files);
            else
                props.setFiles(files);
        }
    }

    const checkMimeType = (event) => {
        //getting file object
        let files = event.target.files
        //define message container
        let err = ''
        // list allow mime type
        const types = ['image/png', 'image/jpeg', 'text/css', 'text/html', 'application/pdf']
        // loop access array

        for (let x = 0; x < files.length; x++) {
            // compare file type find doesn't matach
            if (types.every(type => files[x].type !== type)) {
                // create error message and assign to container  
                err += files[x].type + ' is not a supported format\n';
            }
        };

        if (err !== '') { // if message not same old that mean has error 
            event.target.value = null // discard selected file
            return false;
        }
        return true;
    }

    const checkHTML = (event) => {
        let files = event.target.files

        for (let x = 0; x < files.length; x++) {
            if (files[x].type === 'text/html')
                return true
        };

        return false;
    }

    return (
        <>
            <form method="post" action="#" id="#">
                <div className="form-group drag-drop__files">
                    <input id='upload' type="file" multiple onChange={onChangeHandler} />
                </div>
            </form>
        </>

    )
}

export default DragDrop



export async function UploadUserFiles(files, email, tabName, index) {
    const data = new FormData()

    for (var x = 0; x < files.length; x++) {
        data.append('file', files[x])
    }

    data.append('email', email);
    data.append('tabName', tabName);
    data.append('id', index);

    return await fetch(`/manage/upload-files`, {
        method: 'POST',
        body: data
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            }
            else {
                return false;
            }
        })
        .then((res) => {         //il secondo il risultato
            return res.success;
        })
        .catch((err) => {
            ;
            return false;
        });
}

export async function UploadProjectFiles(files, title, index) {
    const data = new FormData()

    for (var x = 0; x < files.length; x++) {
        data.append('file', files[x])
    }
    data.append('title', title);
    data.append('id', index);

    return await fetch(`/manage/upload-files`, {
        method: 'POST',
        body: data
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            }
            else {
                return false;
            }
        })
        .then((res) => {         //il secondo il risultato
            return res.success;
        })
        .catch((err) => {
            ;
            return false;
        });
}

