
import React, { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form';
import { AiFillSwitcher } from 'react-icons/ai'
import { FaSave } from 'react-icons/fa'
import { MdTitle, MdInsertLink } from "react-icons/md";
import { Btn } from '../../utilities/Button';
import DragDrop, { UploadUserFiles } from '../../utilities/DragDrop';
import { InputText, regex, Switch } from '../../utilities/InputForm';
import Modal, { ModalFooter } from '../../utilities/Modal';



function AddMenuTab({ showModal, setShowModal, setAlert, email, index }) {

    const { control, handleSubmit } = useForm({ mode: 'onChange' });

    const [files, setFiles] = useState([])

    const html_switch = useWatch({
        control,
        name: 'html_switch'
    })


    const onSubmit = (data) => {
        if (html_switch) { //Upload tab and Link

            var x = { 'email': email, 'id': index }
            var newData = { ...data, ...x, }

            fetch(`/manage/upload-link`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newData)
            })
                .then(r => {                    //il primo then ritorna la promise relativa allo stato
                    if (r.status === 200) {
                        return r.json();
                    }
                    else {
                        window.alert(r.message)
                        return false;
                    }
                })
                .then((res) => {         //il secondo il risultato
                    if (res.success === true) {
                        window.location.reload()
                    }
                    else {
                        window.alert(res.message)
                    }
                })
                .catch((err) => {
                    ;
                    return false;
                });
        }
        else { //Upload file 

            if (files.length > 0) {
                if (UploadUserFiles(files, email, data.tabName, index))
                    window.location.reload();
                else
                    setAlert(s => ({ ...s, active: true, message: 'Please insert File HTML or CSS', type: 'wrong' }));
            }
            else
                setAlert(s => ({ ...s, active: true, message: 'Please insert File HTML or CSS', type: 'wrong' }));
        }

    }

    return (
        <Modal showModal={showModal} setShowModal={setShowModal} title={'Add New Tab'} manage={true}>
            <form className='form-wrapper' >

                <InputText
                    name={'tabName'}
                    placeholder={'Tab Name'}
                    control={control}
                    label={<MdTitle className='icon' />}
                    rules={{
                        required: `Tab Name is required.`,
                        minLength:
                        {
                            value: 3,
                            message: `Tab Name must exceed 2 characters`
                        },
                        validate: {
                            checkUserApi: async (x) =>
                            await fetch(`/manage/menu-tab-exist?tabName=${x}&email=${email}`, {
                                method: 'GET',
                                credentials: 'include',
                            })
                                .then(r => {                    //il primo then ritorna la promise relativa allo stato
                                    if (r.status === 200) {
                                        return r.json();
                                    } else {
                                        throw new Error('Error');
                                    }
                                })
                                .then((res) => res.success) || 'Tab just Exist'
                        }
                    }}
                />
                <Switch
                    name={'html_switch'}
                    type={'checkbox'}
                    control={control}
                    label={<AiFillSwitcher className='icon' />}
                    content_t={"LINK"}
                    content_f={"HTML"}
                />
                {html_switch ?
                    <InputText
                        name={'link'}
                        placeholder={'Link'}
                        type={'link'}
                        control={control}
                        label={<MdInsertLink className='icon' />}
                        rules={{
                            required: `Link is required.`,
                            pattern:
                            {
                                value: regex['link'],
                                message: `Insert valid format of Link`
                            },
                            minLength:
                            {
                                value: 3,
                                message: `Link must exceed 2 characters`
                            }
                        }}
                    />
                    :
                    <div className='my__user__card__item__wrapper'>
                        <DragDrop setFiles={setFiles} />
                    </div>
                }
            </form>
            <ModalFooter>
                <Btn size={'btn--sm'} styles={'btn--save'} onClick={handleSubmit(onSubmit)}>
                    <FaSave /> Create
                </Btn>
            </ModalFooter>
        </Modal >
    );
}

export default AddMenuTab