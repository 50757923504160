import React from 'react'
import { IoClose } from 'react-icons/io5';
import styles from './Modal.module.css'
import { AnimatePresence, motion } from 'framer-motion'

function Modal({ showModal, setShowModal, time, title, children }) {

    /* useEffect(() => {
        if (!showModal) return;
        if (time) {
            setTimeout(() => {
                setShowModal(false);
            }, time);
            return clearTimeout();
        }
    }, [showModal, time, setShowModal]);

    if (!showModal) return null; */

    const bgVariants = {
        hidden: {
            opacity: 0,

        },
        visible: {
            opacity: 1,
        }
    }

    const modalVariants = {
        hidden: {
            opacity: 0,
            y: -300,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: .5,
            }
        }
    }

    return (
        <AnimatePresence exitBeforeEnter>
            {
                showModal &&
                <>
                    <motion.div
                        initial='hidden'
                        animate='visible'
                        exit='hidden'
                        variants={bgVariants}
                        className={styles.backgroundSidebar} />

                    <motion.div
                        className={styles.background}
                        initial='hidden'
                        animate='visible'
                        exit='hidden'
                        variants={bgVariants}>

                        <motion.div
                            variants={modalVariants}
                            className={`${styles.wrapper} ${styles.type}`}>
                            <div className={styles.header}>
                                <IoClose className={styles.closeBtn} onClick={() => { setShowModal(showModal => !showModal) }} />
                                <h1>{title}</h1>
                            </div>
                            <div className={styles.body}>
                                {children}
                            </div>
                            <ModalFooter setShowModal={setShowModal} showModal={showModal} />
                        </motion.div>
                    </motion.div>
                </>
            }
        </AnimatePresence>
    );
};

export default Modal


export const ModalFooter = ({ children }) => {
    return (
        <div className={styles.footer}>
            {children}
        </div>
    );
}





