import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { MdEmail } from "react-icons/md";
import { RiKeyFill } from 'react-icons/ri'
import { FaUniversity } from 'react-icons/fa'
import './Login.css'
import AlertFade from '../../utilities/Alert';
import { useForm } from 'react-hook-form';
import { InputText, regex } from '../../utilities/InputForm';
import { Btn } from '../../utilities/Button';
import MotionHoC from '../../utilities/MotionHoC';
import { fetchCasLogin, fetchGetUser, fetchLogin } from '../../utilities/utilityFetch';


function LoginComponent(props) {
  const history = useHistory()

  const { control, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const [alert, setAlert] = useState({
    active: false,
    message: '',
    type: 'wrong'
  })

  function casLogin() {
    fetchCasLogin().then(r => window.open(r.link,"_self"))
  }
  
  const onSubmit = (data) => {
    fetchLogin(data).then(r => r.success ?
      fetchGetUser().then(r1 => props.setUser(r1))
        .then(() => history.push('/'))
      : setAlert(s => ({ ...s, active: true, message: r.message })))

  }

  return (
    <>
      <div className={'login__container'}>
        <div style={{ marginTop: '2rem' }}>
          <figure className='login__pic-wrap' >
            <img className='login__img' alt='1' src='/images/islab_logo.png' />
          </figure>
        </div>
        <div className='form-wrapper'>
          <InputText
            name={"email"}
            placeholder={"email"}
            control={control}
            label={<MdEmail className='icon' />}
            rules={{
              required: `Email is required.`,
              pattern: {
                value: regex['email'],
                message: `Insert valid valid format email`
              }
            }}
          />
          <InputText
            name={'password'}
            placeholder={'password'}
            type={'password'}
            control={control}
            label={<RiKeyFill className='icon' />}
            rules={{
              required: `Password is required.`,
              pattern: {
                value: regex['password'],
                message: `Password must be contain number, upper case and lower case letter`
              },
              minLength: {
                value: 8,
                message: `Password must exceed 7 characters`
              }
            }}
          />
          <div className='login__footer'>
            <div>
              <Btn size={'btn--md'} styles={'btn--cas'} onClick={casLogin}>
                <FaUniversity style={{ marginBottom: '5px' }} /> UNIMI
              </Btn>
            </div>
            <div>
              <Btn size={'btn--md'} styles={'btn--save'} onClick={handleSubmit(onSubmit)}>
                LOGIN
              </Btn>

            </div>
          </div>
          {/* <button onClick={() => setAddUser(true)}>add user</button> */}
        </div>
      </div>

      <AlertFade
        showAlert={alert.active}
        onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
        message={alert.message}
        type={alert.type}
      />
      {/* 
      <AddUser
        showModal={addUser}
        setShowModal={setAddUser}
        setAlert={setAlert}
      /> */}
    </>
  )

}

const Login = MotionHoC(LoginComponent)

export default Login
