import React, { useState, useEffect, useMemo } from 'react';
import FeedCard from './FeedCard';
import styles from './Feed.module.css'
import { fetchGetFeed } from '../utilities/utilityFetch';
//import './Feed.css'


function Feed(props) {

    const [feed, setFeed] = useState([]);
    const [numberOfFeed, setNumberOfFeed] = useState(0);


    useEffect(() => {
        if (props.channel) {
            fetchGetFeed(props.channel).then(res => {
                setNumberOfFeed(res.feed.totalResults);
                if (res.feed.articles && res.feed.articles.length > 0)
                    setFeed(res.feed.articles.reverse())
                else
                    setFeed([])
            })
        }
    }, [props.channel])

    useMemo(() => props?.numberOfFeed(numberOfFeed), [numberOfFeed, props])

    if (feed) {
        if (feed.length > 0)
            return (
                <div className={styles.container} style={props.options} >
                    <span className={styles.title}>
                        <div className={styles.text} children={`${props.channel_name ? props.channel_name : `Latest news`}`} />
                    </span>

                    <div className={styles.wrapper}>
                        {
                            feed.map((item, index) => {
                                return (
                                    <FeedCard
                                        key={index}
                                        feed={item}
                                    />
                                )
                            })
                        }

                    </div>
                </div >
            );
            else
            return null;
    }


    else {
        return (
            <div className='loader' />
        )
    }
}

export default Feed
