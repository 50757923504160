/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import styles from './Hero.module.css';
import Slider from './Slider';
import SVG_IslabTitle from './SVG_IslabTitle';


const heroVariants = {
    hidden: {
        opacity: 0,
        y: -100
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1
        }
    }
}

const slideVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.5,
            duration: 3
        }
    }
}

export default function Hero(props) {
   
    const heroWrapRef = useRef(null);
    const [MAIN_TEXT, setMAIN_TEXT] = useState([])
    const [heroWidth, setHeroWidth] = useState(0)


    useEffect(() => {
        fetch(`/api/get-info-home`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(r => {                    //il primo then ritorna la promise relativa allo stato
                if (r.status === 200) {
                    return r.json();
                } else {
                    throw new Error("Error");
                }
            })
            .then((res) => {       //il secondo il risultato
                if (res.success === true) {
                    setMAIN_TEXT(res.text);
                }
            })
            .catch((err) => {
                ;
            });

    }, [])

 

    window.addEventListener('resize',  ()=>setHeroWidth(heroWrapRef.current?.clientWidth));

    return (
        <>
            <motion.div
                className={styles.heroContainer}
                initial='hidden'
                animate='visible'
                variants={heroVariants}
                ref={heroWrapRef}
                style={{backgroundSize:  (heroWrapRef.current?.clientHeight, heroWrapRef.current?.clientWidth)}}
                >
                <motion.div
                    className={styles.heroWrap}
                >

                    <motion.div className={styles.islabTitleContainer}>
                        {/* // eslint-disable-next-line react/jsx-pascal-case */}
                        <SVG_IslabTitle />
                    </motion.div>

                </motion.div>

                <div className={styles.heroWrapText}>
                    <motion.div
                        initial='hidden'
                        animate={"visible"}
                        variants={slideVariants}
                        className={styles.sliderContainer}>
                        <Slider items={MAIN_TEXT} />
                    </motion.div>
                </div>
            </motion.div>
        </>
    );
}
