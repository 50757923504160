import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

function SVG_IslabTitle(props) {
  const controls = useAnimation();
  const controlsSmall = useAnimation();
  const controlsContainer = useAnimation();

  const svgVariant = {
    hidden: { rotate: 0 },
    visible: {
      rotate: 0,
      transition: { duration: 1 },
    },
  };

  const pathVariants = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: { duration: 2, ease: "easeInOut" },
    },
  };

  useEffect(() => {
    const sequence = async () => {
      await controls.start((i) => ({
        opacity: 1,
        pathLength: 1,
        transition: { duration: 2, ease: "easeInOut", delay: i * 0.2 },
      }));
      await controlsSmall.start((i) => ({
        opacity: 1,
        pathLength: 1,
        transition: { duration: 2, ease: "easeInOut", delay: i * 0.01 },
      }));
    };
    sequence();
  }, [controls, controlsSmall]);

  return (
    <motion.div animate={controlsContainer}>
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="468 160 580 250"
        initial="hidden"
        animate="visible"
        variants={svgVariant}
      >
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={0}
          initial={"hidden"}
          animate={controls}
          variants={pathVariants}
          d="M 613.00,162.00
           C 613.00,162.00 628.59,162.00 628.59,162.00
             628.59,162.00 628.59,228.00 628.59,228.00
             628.59,228.00 613.00,228.00 613.00,228.00
             613.00,228.00 613.00,162.00 613.00,162.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={1}
          initial={"hidden"}
          animate={controls}
          variants={pathVariants}
          d="M 664.00,229.00
           C 657.80,229.00 650.13,228.43 641.00,227.28
             641.00,227.28 641.00,215.31 641.00,215.31
             652.33,217.11 660.07,218.00 664.20,218.00
             672.87,218.00 677.20,215.70 677.20,211.11
             677.20,211.11 677.20,206.78 677.20,206.78
             677.20,204.59 676.58,202.92 675.34,201.75
             674.11,200.59 671.90,200.00 668.70,200.00
             668.70,200.00 661.09,200.00 661.09,200.00
             646.83,200.00 639.70,194.23 639.70,182.69
             639.70,182.69 639.70,177.80 639.70,177.80
             639.70,172.03 642.07,167.79 646.80,165.08
             651.54,162.36 659.04,161.00 669.30,161.00
             674.63,161.00 681.39,161.47 689.59,162.41
             689.59,162.41 689.59,174.03 689.59,174.03
             679.53,172.68 672.60,172.00 668.80,172.00
             663.54,172.00 660.00,172.45 658.20,173.34
             656.27,174.32 655.30,175.85 655.30,177.92
             655.30,177.92 655.30,182.70 655.30,182.70
             655.30,186.23 658.16,188.00 663.91,188.00
             663.91,188.00 671.70,188.00 671.70,188.00
             679.10,188.00 684.46,189.39 687.80,192.17
             691.13,194.95 692.80,199.17 692.80,204.84
             692.80,204.84 692.80,209.20 692.80,209.20
             692.80,217.06 690.03,222.37 684.50,225.14
             681.83,226.57 678.88,227.57 675.64,228.14
             672.41,228.71 668.53,229.00 664.00,229.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={2}
          initial={"hidden"}
          animate={controls}
          variants={pathVariants}
          d="M 704.00,162.50
           C 704.00,162.50 719.59,162.50 719.59,162.50
             719.59,162.50 719.59,217.00 719.59,217.00
             719.59,217.00 751.50,217.00 751.50,217.00
             751.50,217.00 751.50,228.00 751.50,228.00
             751.50,228.00 704.00,228.00 704.00,228.00
             704.00,228.00 704.00,162.50 704.00,162.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={3}
          initial={"hidden"}
          animate={controls}
          variants={pathVariants}
          d="M 779.20,162.50
           C 779.20,162.50 798.20,162.50 798.20,162.50
             798.20,162.50 821.59,228.00 821.59,228.00
             821.59,228.00 805.80,228.00 805.80,228.00
             805.80,228.00 800.00,211.00 800.00,211.00
             800.00,211.00 776.30,211.00 776.30,211.00
             776.30,211.00 770.59,228.00 770.59,228.00
             770.59,228.00 755.20,228.00 755.20,228.00
             755.20,228.00 779.20,162.50 779.20,162.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={4}
          initial={"hidden"}
          animate={controls}
          variants={pathVariants}
          d="M 796.59,200.00
           C 796.59,200.00 788.41,175.70 788.41,175.70
             788.41,175.70 788.00,175.70 788.00,175.70
             788.00,175.70 779.80,200.00 779.80,200.00
             779.80,200.00 796.59,200.00 796.59,200.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={5}
          initial={"hidden"}
          animate={controls}
          variants={pathVariants}
          d="M 829.00,162.00
           C 829.00,162.00 855.30,162.00 855.30,162.00
             861.49,162.00 866.27,162.46 869.64,163.38
             873.02,164.29 875.40,165.76 876.80,167.77
             878.20,169.78 878.91,172.59 878.91,176.19
             878.91,176.19 878.91,184.36 878.91,184.36
             878.91,186.20 878.19,187.95 876.75,189.58
             875.31,191.20 873.63,192.32 871.70,192.94
             871.70,192.94 871.70,193.44 871.70,193.44
             874.23,193.71 876.55,194.81 878.64,196.73
             880.74,198.66 881.80,200.81 881.80,203.17
             881.80,203.17 881.80,213.31 881.80,213.31
             881.80,218.65 879.70,222.43 875.50,224.66
             871.30,226.89 864.80,228.00 856.00,228.00
             856.00,228.00 829.00,228.00 829.00,228.00
             829.00,228.00 829.00,162.00 829.00,162.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={6}
          initial={"hidden"}
          animate={controls}
          variants={pathVariants}
          d="M 855.30,189.00
           C 857.77,189.00 859.60,188.79 860.80,188.38
             861.99,187.96 862.81,187.28 863.25,186.34
             863.69,185.40 863.91,184.02 863.91,182.20
             863.91,182.20 863.91,178.86 863.91,178.86
             863.91,177.26 863.70,176.05 863.30,175.25
             862.90,174.45 862.08,173.88 860.84,173.53
             859.61,173.18 857.66,173.00 855.00,173.00
             855.00,173.00 844.59,173.00 844.59,173.00
             844.59,173.00 844.59,189.00 844.59,189.00
             844.59,189.00 855.30,189.00 855.30,189.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={7}
          initial={"hidden"}
          animate={controls}
          variants={pathVariants}
          d="M 855.70,217.00
           C 859.50,217.00 862.20,216.55 863.80,215.66
             865.40,214.75 866.20,213.29 866.20,211.28
             866.20,211.28 866.20,205.86 866.20,205.86
             866.20,203.37 865.58,201.61 864.34,200.56
             863.11,199.52 860.93,199.00 857.80,199.00
             857.80,199.00 844.59,199.00 844.59,199.00
             844.59,199.00 844.59,217.00 844.59,217.00
             844.59,217.00 855.70,217.00 855.70,217.00Z"
        />

        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={8}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 531.45,249.00
           C 531.45,249.00 536.30,249.00 536.30,249.00
             536.30,249.00 536.30,282.00 536.30,282.00
             536.30,282.00 531.45,282.00 531.45,282.00
             531.45,282.00 531.45,249.00 531.45,249.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={9}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 544.16,258.00
           C 544.16,258.00 548.66,258.00 548.66,258.00
             548.66,258.00 548.66,260.50 548.66,260.50
             549.11,258.17 551.76,257.00 556.59,257.00
             559.70,257.00 561.88,257.57 563.12,258.70
             564.38,259.83 565.00,261.61 565.00,264.03
             565.00,264.03 565.00,282.00 565.00,282.00
             565.00,282.00 560.50,282.00 560.50,282.00
             560.50,282.00 560.50,264.05 560.50,264.05
             560.50,263.39 560.45,262.86 560.34,262.45
             560.25,262.04 560.05,261.62 559.75,261.22
             559.08,260.37 557.60,259.94 555.30,259.94
             553.54,259.94 552.18,260.07 551.22,260.33
             550.27,260.58 549.60,261.00 549.22,261.58
             548.84,262.16 548.66,262.98 548.66,264.05
             548.66,264.05 548.66,282.00 548.66,282.00
             548.66,282.00 544.16,282.00 544.16,282.00
             544.16,282.00 544.16,258.00 544.16,258.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={10}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 575.30,260.94
           C 575.30,260.94 569.75,260.94 569.75,260.94
             569.75,260.94 569.75,258.00 569.75,258.00
             569.75,258.00 575.30,258.00 575.30,258.00
             575.30,258.00 575.30,253.88 575.30,253.88
             575.30,251.09 576.04,249.10 577.52,247.89
             579.00,246.67 581.27,246.06 584.30,246.06
             585.43,246.06 586.78,246.16 588.34,246.34
             588.34,246.34 588.34,249.30 588.34,249.30
             587.08,249.10 585.79,249.00 584.45,249.00
             582.79,249.00 581.59,249.32 580.88,249.97
             580.16,250.62 579.80,251.78 579.80,253.47
             579.80,253.47 579.80,258.00 579.80,258.00
             579.80,258.00 587.84,258.00 587.84,258.00
             587.84,258.00 587.84,260.94 587.84,260.94
             587.84,260.94 579.80,260.94 579.80,260.94
             579.80,260.94 579.80,282.00 579.80,282.00
             579.80,282.00 575.30,282.00 575.30,282.00
             575.30,282.00 575.30,260.94 575.30,260.94Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={11}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 602.41,282.00
           C 600.27,282.00 598.66,281.93 597.59,281.78
             596.53,281.62 595.47,281.30 594.41,280.81
             593.30,280.31 592.50,279.51 592.00,278.39
             591.50,277.28 591.25,275.77 591.25,273.88
             591.25,273.88 591.25,265.52 591.25,265.52
             591.25,264.03 591.43,262.72 591.80,261.59
             592.16,260.46 592.64,259.57 593.25,258.94
             593.75,258.38 594.50,257.94 595.52,257.62
             596.54,257.31 597.58,257.11 598.66,257.00
             599.85,257.00 601.10,257.00 602.41,257.00
             604.13,257.00 605.44,257.04 606.33,257.12
             607.21,257.21 608.16,257.38 609.16,257.64
             610.19,257.95 610.98,258.40 611.55,259.00
             612.12,259.59 612.60,260.45 613.00,261.58
             613.36,262.75 613.55,264.09 613.55,265.61
             613.55,265.61 613.55,273.98 613.55,273.98
             613.55,276.81 612.89,278.87 611.59,280.16
             611.06,280.69 610.30,281.11 609.30,281.41
             608.30,281.70 607.27,281.90 606.20,282.00
             604.93,282.00 603.66,282.00 602.41,282.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={12}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 602.41,279.06
           C 603.80,279.06 604.82,279.03 605.45,278.97
             606.09,278.90 606.70,278.71 607.30,278.42
             607.90,278.15 608.33,277.71 608.58,277.11
             608.83,276.50 608.95,275.64 608.95,274.55
             608.95,274.55 608.95,264.84 608.95,264.84
             608.95,263.36 608.77,262.27 608.39,261.59
             608.02,260.92 607.40,260.48 606.52,260.27
             605.64,260.05 604.27,259.94 602.41,259.94
             600.53,259.94 599.15,260.05 598.27,260.27
             597.39,260.48 596.77,260.92 596.39,261.59
             596.02,262.27 595.84,263.36 595.84,264.84
             595.84,264.84 595.84,274.55 595.84,274.55
             595.84,275.64 595.97,276.50 596.22,277.11
             596.47,277.71 596.89,278.15 597.50,278.42
             598.10,278.71 598.72,278.90 599.34,278.97
             599.98,279.03 601.00,279.06 602.41,279.06Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={13}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 620.16,258.00
           C 620.16,258.00 624.41,258.00 624.41,258.00
             624.41,258.00 624.41,261.95 624.41,261.95
             624.41,261.18 624.74,260.46 625.41,259.78
             626.07,259.09 626.92,258.46 627.95,257.88
             628.98,257.29 630.00,257.00 631.00,257.00
             631.00,257.00 633.20,257.00 633.20,257.00
             633.20,257.00 633.20,261.00 633.20,261.00
             633.20,261.00 630.70,261.00 630.70,261.00
             628.57,261.00 627.02,261.28 626.08,261.83
             625.13,262.38 624.66,263.38 624.66,264.83
             624.66,264.83 624.66,282.00 624.66,282.00
             624.66,282.00 620.16,282.00 620.16,282.00
             620.16,282.00 620.16,258.00 620.16,258.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={14}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 638.16,258.00
           C 638.16,258.00 642.66,258.00 642.66,258.00
             642.66,258.00 642.66,260.16 642.66,260.16
             643.11,258.05 645.83,257.00 650.80,257.00
             654.83,257.00 657.34,258.00 658.34,260.00
             658.64,259.03 659.55,258.29 661.06,257.78
             662.58,257.26 664.45,257.00 666.66,257.00
             669.66,257.00 671.77,257.57 673.00,258.70
             674.23,259.83 674.84,261.61 674.84,264.03
             674.84,264.03 674.84,282.00 674.84,282.00
             674.84,282.00 670.34,282.00 670.34,282.00
             670.34,282.00 670.34,264.05 670.34,264.05
             670.34,263.40 670.29,262.88 670.19,262.47
             670.09,262.05 669.89,261.64 669.59,261.22
             668.93,260.37 667.45,259.94 665.16,259.94
             663.38,259.94 662.07,260.06 661.20,260.30
             660.34,260.54 659.74,260.95 659.42,261.53
             659.11,262.12 658.95,262.95 658.95,264.05
             658.95,264.05 658.95,282.00 658.95,282.00
             658.95,282.00 654.45,282.00 654.45,282.00
             654.45,282.00 654.45,264.05 654.45,264.05
             654.45,263.40 654.40,262.88 654.30,262.47
             654.20,262.05 654.00,261.64 653.70,261.22
             653.04,260.37 651.57,259.94 649.30,259.94
             647.46,259.94 646.08,260.06 645.14,260.30
             644.21,260.54 643.57,260.95 643.20,261.52
             642.84,262.08 642.66,262.92 642.66,264.05
             642.66,264.05 642.66,282.00 642.66,282.00
             642.66,282.00 638.16,282.00 638.16,282.00
             638.16,282.00 638.16,258.00 638.16,258.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={15}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 690.59,282.50
           C 688.30,282.50 686.53,282.27 685.28,281.83
             684.03,281.37 683.13,280.57 682.58,279.42
             682.02,278.28 681.75,276.62 681.75,274.47
             681.75,272.55 682.00,271.07 682.52,270.02
             683.04,268.95 683.92,268.20 685.17,267.75
             686.42,267.29 688.20,267.06 690.50,267.06
             690.50,267.06 697.75,267.06 697.75,267.06
             697.75,267.06 697.75,263.28 697.75,263.28
             697.75,262.40 697.57,261.72 697.22,261.25
             696.88,260.77 696.29,260.43 695.47,260.23
             694.66,260.04 693.47,259.94 691.91,259.94
             689.41,259.94 686.63,260.13 683.59,260.52
             683.59,260.52 683.59,257.45 683.59,257.45
             686.89,257.15 689.88,257.00 692.55,257.00
             695.35,257.00 697.41,257.21 698.75,257.64
             700.08,258.06 701.00,258.79 701.50,259.84
             702.00,260.89 702.25,262.50 702.25,264.69
             702.25,264.69 702.25,282.00 702.25,282.00
             702.25,282.00 697.91,282.00 697.91,282.00
             697.91,282.00 697.91,279.72 697.91,279.72
             697.53,281.57 695.09,282.50 690.59,282.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={16}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 691.34,279.56
           C 692.98,279.56 694.36,279.44 695.50,279.19
             697.00,278.88 697.75,278.14 697.75,276.97
             697.75,276.97 697.75,270.00 697.75,270.00
             697.75,270.00 690.80,270.00 690.80,270.00
             689.37,270.00 688.34,270.12 687.70,270.34
             687.07,270.57 686.65,271.01 686.45,271.64
             686.25,272.28 686.16,273.30 686.16,274.70
             686.16,275.98 686.27,276.95 686.50,277.64
             686.73,278.33 687.14,278.82 687.75,279.12
             688.35,279.42 689.25,279.56 690.45,279.56
             690.45,279.56 691.34,279.56 691.34,279.56Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={17}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 720.30,282.50
           C 718.23,282.50 716.62,282.27 715.45,281.83
             714.29,281.38 713.44,280.62 712.92,279.55
             712.41,278.48 712.16,276.96 712.16,275.02
             712.16,275.02 712.16,260.94 712.16,260.94
             712.16,260.94 706.75,260.94 706.75,260.94
             706.75,260.94 706.75,258.00 706.75,258.00
             706.75,258.00 712.16,258.00 712.16,258.00
             712.16,258.00 712.16,250.00 712.16,250.00
             712.16,250.00 716.70,250.00 716.70,250.00
             716.70,250.00 716.70,258.00 716.70,258.00
             716.70,258.00 725.34,258.00 725.34,258.00
             725.34,258.00 725.34,260.94 725.34,260.94
             725.34,260.94 716.70,260.94 716.70,260.94
             716.70,260.94 716.70,275.25 716.70,275.25
             716.70,276.44 716.84,277.33 717.12,277.92
             717.41,278.52 717.91,278.94 718.62,279.19
             719.34,279.44 720.38,279.56 721.75,279.56
             722.19,279.56 723.38,279.43 725.34,279.17
             725.34,279.17 725.34,282.11 725.34,282.11
             723.64,282.37 721.96,282.50 720.30,282.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={18}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d=" M 730.16,249.00
           C 730.16,249.00 734.66,249.00 734.66,249.00
             734.66,249.00 734.66,254.00 734.66,254.00
             734.66,254.00 730.16,254.00 730.16,254.00
             730.16,254.00 730.16,249.00 730.16,249.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={19}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 730.16,258.00
           C 730.16,258.00 734.66,258.00 734.66,258.00
             734.66,258.00 734.66,282.00 734.66,282.00
             734.66,282.00 730.16,282.00 730.16,282.00
             730.16,282.00 730.16,258.00 730.16,258.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={20}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 752.41,282.00
           C 750.27,282.00 748.66,281.93 747.59,281.78
             746.53,281.62 745.47,281.30 744.41,280.81
             743.30,280.31 742.50,279.51 742.00,278.39
             741.50,277.28 741.25,275.77 741.25,273.88
             741.25,273.88 741.25,265.52 741.25,265.52
             741.25,264.03 741.43,262.72 741.80,261.59
             742.16,260.46 742.64,259.57 743.25,258.94
             743.75,258.38 744.50,257.94 745.52,257.62
             746.54,257.31 747.58,257.11 748.66,257.00
             749.85,257.00 751.10,257.00 752.41,257.00
             754.13,257.00 755.44,257.04 756.33,257.12
             757.21,257.21 758.16,257.38 759.16,257.64
             760.19,257.95 760.98,258.40 761.55,259.00
             762.12,259.59 762.60,260.45 763.00,261.58
             763.36,262.75 763.55,264.09 763.55,265.61
             763.55,265.61 763.55,273.98 763.55,273.98
             763.55,276.81 762.89,278.87 761.59,280.16
             761.06,280.69 760.30,281.11 759.30,281.41
             758.30,281.70 757.27,281.90 756.20,282.00
             754.93,282.00 753.66,282.00 752.41,282.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={21}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 752.41,279.06
           C 753.80,279.06 754.82,279.03 755.45,278.97
             756.09,278.90 756.70,278.71 757.30,278.42
             757.90,278.15 758.33,277.71 758.58,277.11
             758.83,276.50 758.95,275.64 758.95,274.55
             758.95,274.55 758.95,264.84 758.95,264.84
             758.95,263.36 758.77,262.27 758.39,261.59
             758.02,260.92 757.40,260.48 756.52,260.27
             755.64,260.05 754.27,259.94 752.41,259.94
             750.53,259.94 749.15,260.05 748.27,260.27
             747.39,260.48 746.77,260.92 746.39,261.59
             746.02,262.27 745.84,263.36 745.84,264.84
             745.84,264.84 745.84,274.55 745.84,274.55
             745.84,275.64 745.97,276.50 746.22,277.11
             746.47,277.71 746.89,278.15 747.50,278.42
             748.10,278.71 748.72,278.90 749.34,278.97
             749.98,279.03 751.00,279.06 752.41,279.06Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={22}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 770.16,258.00
           C 770.16,258.00 774.66,258.00 774.66,258.00
             774.66,258.00 774.66,260.50 774.66,260.50
             775.11,258.17 777.76,257.00 782.59,257.00
             785.70,257.00 787.88,257.57 789.12,258.70
             790.38,259.83 791.00,261.61 791.00,264.03
             791.00,264.03 791.00,282.00 791.00,282.00
             791.00,282.00 786.50,282.00 786.50,282.00
             786.50,282.00 786.50,264.05 786.50,264.05
             786.50,263.39 786.45,262.86 786.34,262.45
             786.25,262.04 786.05,261.62 785.75,261.22
             785.08,260.37 783.60,259.94 781.30,259.94
             779.54,259.94 778.18,260.07 777.22,260.33
             776.27,260.58 775.60,261.00 775.22,261.58
             774.84,262.16 774.66,262.98 774.66,264.05
             774.66,264.05 774.66,282.00 774.66,282.00
             774.66,282.00 770.16,282.00 770.16,282.00
             770.16,282.00 770.16,258.00 770.16,258.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={23}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 818.55,282.50
           C 815.98,282.50 813.22,282.32 810.25,281.95
             810.25,281.95 810.25,278.56 810.25,278.56
             814.14,279.23 816.95,279.56 818.66,279.56
             820.78,279.56 822.31,279.34 823.25,278.91
             824.19,278.47 824.66,277.66 824.66,276.48
             824.66,276.48 824.66,273.92 824.66,273.92
             824.66,272.84 824.30,272.07 823.59,271.62
             822.89,271.17 821.77,270.94 820.20,270.94
             820.20,270.94 817.45,270.94 817.45,270.94
             814.95,270.94 813.04,270.42 811.72,269.38
             810.41,268.34 809.75,266.88 809.75,265.00
             809.75,265.00 809.75,262.73 809.75,262.73
             809.75,258.91 813.35,257.00 820.55,257.00
             821.08,257.00 823.53,257.00 827.91,257.00
             827.91,257.00 827.91,260.73 827.91,260.73
             824.94,260.20 822.41,259.94 820.34,259.94
             817.98,259.94 816.36,260.19 815.50,260.69
             814.63,261.19 814.20,262.02 814.20,263.19
             814.20,263.19 814.20,265.39 814.20,265.39
             814.20,267.13 815.69,268.00 818.66,268.00
             818.66,268.00 821.45,268.00 821.45,268.00
             826.55,268.00 829.09,269.88 829.09,273.62
             829.09,273.62 829.09,275.69 829.09,275.69
             829.09,278.16 828.23,279.91 826.50,280.95
             824.77,281.98 822.12,282.50 818.55,282.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={24}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 840.91,282.83
           C 840.91,282.83 831.55,258.00 831.55,258.00
             831.55,258.00 836.45,258.00 836.45,258.00
             836.45,258.00 843.20,278.00 843.20,278.00
             843.20,278.00 843.30,278.00 843.30,278.00
             843.30,278.00 849.41,258.00 849.41,258.00
             849.41,258.00 853.95,258.00 853.95,258.00
             853.95,258.00 841.80,293.00 841.80,293.00
             841.80,293.00 837.09,293.00 837.09,293.00
             837.09,293.00 840.91,282.83 840.91,282.83Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={25}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 865.55,282.50
           C 862.98,282.50 860.22,282.32 857.25,281.95
             857.25,281.95 857.25,278.56 857.25,278.56
             861.14,279.23 863.95,279.56 865.66,279.56
             867.78,279.56 869.31,279.34 870.25,278.91
             871.19,278.47 871.66,277.66 871.66,276.48
             871.66,276.48 871.66,273.92 871.66,273.92
             871.66,272.84 871.30,272.07 870.59,271.62
             869.89,271.17 868.77,270.94 867.20,270.94
             867.20,270.94 864.45,270.94 864.45,270.94
             861.95,270.94 860.04,270.42 858.72,269.38
             857.41,268.34 856.75,266.88 856.75,265.00
             856.75,265.00 856.75,262.73 856.75,262.73
             856.75,258.91 860.35,257.00 867.55,257.00
             868.08,257.00 870.53,257.00 874.91,257.00
             874.91,257.00 874.91,260.73 874.91,260.73
             871.94,260.20 869.41,259.94 867.34,259.94
             864.98,259.94 863.36,260.19 862.50,260.69
             861.63,261.19 861.20,262.02 861.20,263.19
             861.20,263.19 861.20,265.39 861.20,265.39
             861.20,267.13 862.69,268.00 865.66,268.00
             865.66,268.00 868.45,268.00 868.45,268.00
             873.55,268.00 876.09,269.88 876.09,273.62
             876.09,273.62 876.09,275.69 876.09,275.69
             876.09,278.16 875.23,279.91 873.50,280.95
             871.77,281.98 869.12,282.50 865.55,282.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={26}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 892.30,282.50
           C 890.23,282.50 888.62,282.27 887.45,281.83
             886.29,281.38 885.44,280.62 884.92,279.55
             884.41,278.48 884.16,276.96 884.16,275.02
             884.16,275.02 884.16,260.94 884.16,260.94
             884.16,260.94 878.75,260.94 878.75,260.94
             878.75,260.94 878.75,258.00 878.75,258.00
             878.75,258.00 884.16,258.00 884.16,258.00
             884.16,258.00 884.16,250.00 884.16,250.00
             884.16,250.00 888.70,250.00 888.70,250.00
             888.70,250.00 888.70,258.00 888.70,258.00
             888.70,258.00 897.34,258.00 897.34,258.00
             897.34,258.00 897.34,260.94 897.34,260.94
             897.34,260.94 888.70,260.94 888.70,260.94
             888.70,260.94 888.70,275.25 888.70,275.25
             888.70,276.44 888.84,277.33 889.12,277.92
             889.41,278.52 889.91,278.94 890.62,279.19
             891.34,279.44 892.38,279.56 893.75,279.56
             894.19,279.56 895.38,279.43 897.34,279.17
             897.34,279.17 897.34,282.11 897.34,282.11
             895.64,282.37 893.96,282.50 892.30,282.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={27}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 912.34,282.50
           C 904.95,282.50 901.25,280.01 901.25,275.02
             901.25,275.02 901.25,265.34 901.25,265.34
             901.25,262.38 902.09,260.25 903.77,258.95
             905.45,257.65 908.18,257.00 911.95,257.00
             915.52,257.00 918.09,257.64 919.67,258.91
             921.25,260.17 922.05,262.35 922.05,265.45
             922.05,265.45 922.05,270.94 922.05,270.94
             922.05,270.94 905.80,270.94 905.80,270.94
             905.80,270.94 905.80,274.70 905.80,274.70
             905.80,276.43 906.38,277.68 907.55,278.44
             908.71,279.19 910.52,279.56 912.95,279.56
             915.25,279.56 917.97,279.15 921.09,278.33
             921.09,278.33 921.09,281.62 921.09,281.62
             918.03,282.21 915.11,282.50 912.34,282.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={28}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 917.55,268.00
           C 917.55,268.00 917.55,264.00 917.55,264.00
             917.55,262.45 917.10,261.39 916.22,260.81
             915.34,260.23 913.84,259.94 911.70,259.94
             909.63,259.94 908.12,260.23 907.19,260.81
             906.26,261.39 905.80,262.45 905.80,264.00
             905.80,264.00 905.80,268.00 905.80,268.00
             905.80,268.00 917.55,268.00 917.55,268.00Z"
        />

        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={29}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 978.55,282.50
           C 975.98,282.50 973.22,282.32 970.25,281.95
             970.25,281.95 970.25,278.56 970.25,278.56
             974.14,279.23 976.95,279.56 978.66,279.56
             980.78,279.56 982.31,279.34 983.25,278.91
             984.19,278.47 984.66,277.66 984.66,276.48
             984.66,276.48 984.66,273.92 984.66,273.92
             984.66,272.84 984.30,272.07 983.59,271.62
             982.89,271.17 981.77,270.94 980.20,270.94
             980.20,270.94 977.45,270.94 977.45,270.94
             974.95,270.94 973.04,270.42 971.72,269.38
             970.41,268.34 969.75,266.88 969.75,265.00
             969.75,265.00 969.75,262.73 969.75,262.73
             969.75,258.91 973.35,257.00 980.55,257.00
             981.08,257.00 983.53,257.00 987.91,257.00
             987.91,257.00 987.91,260.73 987.91,260.73
             984.94,260.20 982.41,259.94 980.34,259.94
             977.98,259.94 976.36,260.19 975.50,260.69
             974.63,261.19 974.20,262.02 974.20,263.19
             974.20,263.19 974.20,265.39 974.20,265.39
             974.20,267.13 975.69,268.00 978.66,268.00
             978.66,268.00 981.45,268.00 981.45,268.00
             986.55,268.00 989.09,269.88 989.09,273.62
             989.09,273.62 989.09,275.69 989.09,275.69
             989.09,278.16 988.23,279.91 986.50,280.95
             984.77,281.98 982.12,282.50 978.55,282.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={30}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 929.16,258.00
           C 929.16,258.00 933.66,258.00 933.66,258.00
             933.66,258.00 933.66,260.16 933.66,260.16
             934.11,258.05 936.83,257.00 941.80,257.00
             945.83,257.00 948.34,258.00 949.34,260.00
             949.64,259.03 950.55,258.29 952.06,257.78
             953.58,257.26 955.45,257.00 957.66,257.00
             960.66,257.00 962.77,257.57 964.00,258.70
             965.23,259.83 965.84,261.61 965.84,264.03
             965.84,264.03 965.84,282.00 965.84,282.00
             965.84,282.00 961.34,282.00 961.34,282.00
             961.34,282.00 961.34,264.05 961.34,264.05
             961.34,263.40 961.29,262.88 961.19,262.47
             961.09,262.05 960.89,261.64 960.59,261.22
             959.93,260.37 958.45,259.94 956.16,259.94
             954.38,259.94 953.07,260.06 952.20,260.30
             951.34,260.54 950.74,260.95 950.42,261.53
             950.11,262.12 949.95,262.95 949.95,264.05
             949.95,264.05 949.95,282.00 949.95,282.00
             949.95,282.00 945.45,282.00 945.45,282.00
             945.45,282.00 945.45,264.05 945.45,264.05
             945.45,263.40 945.40,262.88 945.30,262.47
             945.20,262.05 945.00,261.64 944.70,261.22
             944.04,260.37 942.57,259.94 940.30,259.94
             938.46,259.94 937.08,260.06 936.14,260.30
             935.21,260.54 934.57,260.95 934.20,261.52
             933.84,262.08 933.66,262.92 933.66,264.05
             933.66,264.05 933.66,282.00 933.66,282.00
             933.66,282.00 929.16,282.00 929.16,282.00
             929.16,282.00 929.16,258.00 929.16,258.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={31}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 717.59,340.50
           C 715.30,340.50 713.53,340.27 712.28,339.83
             711.03,339.37 710.13,338.57 709.58,337.42
             709.02,336.28 708.75,334.62 708.75,332.47
             708.75,330.55 709.00,329.07 709.52,328.02
             710.04,326.95 710.92,326.20 712.17,325.75
             713.42,325.29 715.20,325.06 717.50,325.06
             717.50,325.06 724.75,325.06 724.75,325.06
             724.75,325.06 724.75,321.28 724.75,321.28
             724.75,320.40 724.57,319.72 724.22,319.25
             723.88,318.77 723.29,318.43 722.47,318.23
             721.66,318.04 720.47,317.94 718.91,317.94
             716.41,317.94 713.63,318.13 710.59,318.52
             710.59,318.52 710.59,315.45 710.59,315.45
             713.89,315.15 716.88,315.00 719.55,315.00
             722.35,315.00 724.41,315.21 725.75,315.64
             727.08,316.06 728.00,316.79 728.50,317.84
             729.00,318.89 729.25,320.50 729.25,322.69
             729.25,322.69 729.25,340.00 729.25,340.00
             729.25,340.00 724.91,340.00 724.91,340.00
             724.91,340.00 724.91,337.72 724.91,337.72
             724.53,339.57 722.09,340.50 717.59,340.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={32}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 718.34,337.56
           C 719.98,337.56 721.36,337.44 722.50,337.19
             724.00,336.88 724.75,336.14 724.75,334.97
             724.75,334.97 724.75,328.00 724.75,328.00
             724.75,328.00 717.80,328.00 717.80,328.00
             716.37,328.00 715.34,328.12 714.70,328.34
             714.07,328.57 713.65,329.01 713.45,329.64
             713.25,330.28 713.16,331.30 713.16,332.70
             713.16,333.98 713.27,334.95 713.50,335.64
             713.73,336.33 714.14,336.82 714.75,337.12
             715.35,337.42 716.25,337.56 717.45,337.56
             717.45,337.56 718.34,337.56 718.34,337.56Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={33}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 737.16,316.00
           C 737.16,316.00 741.66,316.00 741.66,316.00
             741.66,316.00 741.66,318.50 741.66,318.50
             742.11,316.17 744.76,315.00 749.59,315.00
             752.70,315.00 754.88,315.57 756.12,316.70
             757.38,317.83 758.00,319.61 758.00,322.03
             758.00,322.03 758.00,340.00 758.00,340.00
             758.00,340.00 753.50,340.00 753.50,340.00
             753.50,340.00 753.50,322.05 753.50,322.05
             753.50,321.39 753.45,320.86 753.34,320.45
             753.25,320.04 753.05,319.62 752.75,319.22
             752.08,318.37 750.60,317.94 748.30,317.94
             746.54,317.94 745.18,318.07 744.22,318.33
             743.27,318.58 742.60,319.00 742.22,319.58
             741.84,320.16 741.66,320.98 741.66,322.05
             741.66,322.05 741.66,340.00 741.66,340.00
             741.66,340.00 737.16,340.00 737.16,340.00
             737.16,340.00 737.16,316.00 737.16,316.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={34}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 774.80,340.50
           C 772.40,340.50 770.52,340.25 769.14,339.77
             767.77,339.28 766.79,338.48 766.17,337.36
             765.55,336.25 765.25,334.70 765.25,332.70
             765.25,332.70 765.25,321.94 765.25,321.94
             765.25,319.47 766.00,317.70 767.52,316.62
             769.04,315.54 771.55,315.00 775.05,315.00
             777.15,315.00 778.96,315.27 780.47,315.81
             781.99,316.34 782.77,317.11 782.80,318.11
             782.80,318.11 782.91,318.11 782.91,318.11
             782.91,318.11 782.80,312.03 782.80,312.03
             782.80,312.03 782.80,305.00 782.80,305.00
             782.80,305.00 787.30,305.00 787.30,305.00
             787.30,305.00 787.30,340.00 787.30,340.00
             787.30,340.00 782.91,340.00 782.91,340.00
             782.91,340.00 782.91,337.16 782.91,337.16
             782.91,338.05 782.19,338.82 780.75,339.45
             779.31,340.15 777.33,340.50 774.80,340.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={35}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 775.75,337.56
           C 778.25,337.56 780.05,337.27 781.14,336.69
             782.24,336.09 782.80,335.09 782.80,333.67
             782.80,333.67 782.80,321.30 782.80,321.30
             782.80,319.06 780.58,317.94 776.16,317.94
             773.49,317.94 771.75,318.15 770.95,318.56
             770.12,318.98 769.70,319.89 769.70,321.30
             769.70,321.30 769.70,333.67 769.70,333.67
             769.70,335.09 770.16,336.09 771.08,336.69
             771.99,337.27 773.55,337.56 775.75,337.56Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={36}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 475.95,365.00
           C 475.95,365.00 480.80,365.00 480.80,365.00
             480.80,365.00 480.80,398.00 480.80,398.00
             480.80,398.00 475.95,398.00 475.95,398.00
             475.95,398.00 475.95,365.00 475.95,365.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={37}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 480.91,380.45
           C 480.91,380.45 495.41,365.00 495.41,365.00
             495.41,365.00 501.25,365.00 501.25,365.00
             501.25,365.00 486.66,380.34 486.66,380.34
             486.66,380.34 502.70,398.00 502.70,398.00
             502.70,398.00 496.41,398.00 496.41,398.00
             496.41,398.00 480.91,380.45 480.91,380.45Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={38}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 505.66,374.00
           C 505.66,374.00 510.16,374.00 510.16,374.00
             510.16,374.00 510.16,376.50 510.16,376.50
             510.61,374.17 513.26,373.00 518.09,373.00
             521.20,373.00 523.38,373.57 524.62,374.70
             525.88,375.83 526.50,377.61 526.50,380.03
             526.50,380.03 526.50,398.00 526.50,398.00
             526.50,398.00 522.00,398.00 522.00,398.00
             522.00,398.00 522.00,380.05 522.00,380.05
             522.00,379.39 521.95,378.86 521.84,378.45
             521.75,378.04 521.55,377.62 521.25,377.22
             520.58,376.37 519.10,375.94 516.80,375.94
             515.04,375.94 513.68,376.07 512.72,376.33
             511.77,376.58 511.10,377.00 510.72,377.58
             510.34,378.16 510.16,378.98 510.16,380.05
             510.16,380.05 510.16,398.00 510.16,398.00
             510.16,398.00 505.66,398.00 505.66,398.00
             505.66,398.00 505.66,374.00 505.66,374.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={39}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 544.91,398.00
           C 542.77,398.00 541.16,397.93 540.09,397.78
             539.03,397.62 537.97,397.30 536.91,396.81
             535.80,396.31 535.00,395.51 534.50,394.39
             534.00,393.28 533.75,391.77 533.75,389.88
             533.75,389.88 533.75,381.52 533.75,381.52
             533.75,380.03 533.93,378.72 534.30,377.59
             534.66,376.46 535.14,375.57 535.75,374.94
             536.25,374.38 537.00,373.94 538.02,373.62
             539.04,373.31 540.08,373.11 541.16,373.00
             542.35,373.00 543.60,373.00 544.91,373.00
             546.63,373.00 547.94,373.04 548.83,373.12
             549.71,373.21 550.66,373.38 551.66,373.64
             552.69,373.95 553.48,374.40 554.05,375.00
             554.62,375.59 555.10,376.45 555.50,377.58
             555.86,378.75 556.05,380.09 556.05,381.61
             556.05,381.61 556.05,389.98 556.05,389.98
             556.05,392.81 555.39,394.87 554.09,396.16
             553.56,396.69 552.80,397.11 551.80,397.41
             550.80,397.70 549.77,397.90 548.70,398.00
             547.43,398.00 546.16,398.00 544.91,398.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={40}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 544.91,395.06
           C 546.30,395.06 547.32,395.03 547.95,394.97
             548.59,394.90 549.20,394.71 549.80,394.42
             550.40,394.15 550.83,393.71 551.08,393.11
             551.33,392.50 551.45,391.64 551.45,390.55
             551.45,390.55 551.45,380.84 551.45,380.84
             551.45,379.36 551.27,378.27 550.89,377.59
             550.52,376.92 549.90,376.48 549.02,376.27
             548.14,376.05 546.77,375.94 544.91,375.94
             543.03,375.94 541.65,376.05 540.77,376.27
             539.89,376.48 539.27,376.92 538.89,377.59
             538.52,378.27 538.34,379.36 538.34,380.84
             538.34,380.84 538.34,390.55 538.34,390.55
             538.34,391.64 538.47,392.50 538.72,393.11
             538.97,393.71 539.39,394.15 540.00,394.42
             540.60,394.71 541.22,394.90 541.84,394.97
             542.48,395.03 543.50,395.06 544.91,395.06Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={41}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 559.34,373.50
           C 559.34,373.50 564.30,373.50 564.30,373.50
             564.30,373.50 570.20,393.66 570.20,393.66
             570.20,393.66 570.50,393.66 570.50,393.66
             570.50,393.66 576.20,373.50 576.20,373.50
             576.20,373.50 581.25,373.50 581.25,373.50
             581.25,373.50 587.16,393.66 587.16,393.66
             587.16,393.66 587.45,393.66 587.45,393.66
             587.45,393.66 593.16,373.50 593.16,373.50
             593.16,373.50 597.75,373.50 597.75,373.50
             597.75,373.50 589.80,398.00 589.80,398.00
             589.80,398.00 584.45,398.00 584.45,398.00
             584.45,398.00 578.59,378.22 578.59,378.22
             578.59,378.22 572.84,398.00 572.84,398.00
             572.84,398.00 567.50,398.00 567.50,398.00
             567.50,398.00 559.34,373.50 559.34,373.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={42}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 602.66,363.00
           C 602.66,363.00 607.16,363.00 607.16,363.00
             607.16,363.00 607.16,398.00 607.16,398.00
             607.16,398.00 602.66,398.00 602.66,398.00
             602.66,398.00 602.66,363.00 602.66,363.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={43}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 622.09,398.50
          C 614.70,398.50 614.00,396.01 614.00,391.02
            614.00,391.02 614.00,381.34 614.00,381.34
            614.00,378.38 614.84,376.25 616.52,374.95
            618.20,373.65 620.93,373.00 624.70,373.00
            628.28,373.00 630.85,373.64 632.43,374.91
            634.01,376.17 634.81,378.35 634.81,381.45
            634.81,381.45 634.81,386.94 634.81,386.94
            634.81,386.94 618.56,386.94 618.56,386.94
            618.56,386.94 618.56,390.70 618.56,390.70
            618.56,392.43 619.04,393.68 620.21,394.44
            621.37,395.19 623.18,395.56 625.61,395.56
            627.91,395.56 630.63,395.15 633.75,394.33
            633.75,394.33 633.75,397.62 633.75,397.62
            630.68,398.21 627.98,398.50 625.65,398.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={44}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 630.84,384.00
           C 630.84,384.00 630.84,380.00 630.84,380.00
             630.84,378.45 630.39,377.39 629.51,376.81
             628.63,376.23 627.13,375.94 624.99,375.94
             622.92,375.94 621.41,376.23 620.48,376.81
             619.61,377.39 619.15,378.45 619.15,380.00
             619.15,380.00 619.15,384.00 619.15,384.00
             619.15,384.00 630.84,384.00 630.84,384.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={45}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 650.30,398.50
           C 647.90,398.50 646.02,398.25 644.64,397.77
             643.27,397.28 642.29,396.48 641.67,395.36
             641.05,394.25 640.75,392.70 640.75,390.70
             640.75,390.70 640.75,379.94 640.75,379.94
             640.75,377.47 641.50,375.70 643.02,374.62
             644.54,373.54 647.05,373.00 650.55,373.00
             652.65,373.00 654.46,373.27 655.97,373.81
             657.49,374.34 658.27,375.11 658.30,376.11
             658.30,376.11 658.41,376.11 658.41,376.11
             658.41,376.11 658.30,370.03 658.30,370.03
             658.30,370.03 658.30,363.00 658.30,363.00
             658.30,363.00 662.80,363.00 662.80,363.00
             662.80,363.00 662.80,398.00 662.80,398.00
             662.80,398.00 658.41,398.00 658.41,398.00
             658.41,398.00 658.41,395.16 658.41,395.16
             658.41,396.05 657.69,396.82 656.25,397.45
             654.81,398.15 652.83,398.50 650.30,398.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={46}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 651.25,395.56
           C 653.75,395.56 655.55,395.27 656.64,394.69
             657.74,394.09 658.30,393.09 658.30,391.67
             658.30,391.67 658.30,379.30 658.30,379.30
             658.30,377.06 656.08,375.94 651.66,375.94
             648.99,375.94 647.25,376.15 646.45,376.56
             645.62,376.98 645.20,377.89 645.20,379.30
             645.20,379.30 645.20,391.67 645.20,391.67
             645.20,393.09 645.66,394.09 646.58,394.69
             647.49,395.27 649.05,395.56 651.25,395.56Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={47}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 679.80,409.50
           C 676.49,409.50 673.71,409.33 671.45,409.00
             671.45,409.00 671.45,405.81 671.45,405.81
             675.45,406.31 678.37,406.56 680.20,406.56
             682.50,406.56 684.18,406.16 685.22,405.38
             686.27,404.58 686.80,403.27 686.80,401.42
             686.80,401.42 686.80,395.41 686.80,395.41
             686.73,396.47 686.01,397.25 684.62,397.75
             683.24,398.25 681.50,398.50 679.41,398.50
             676.91,398.50 674.96,398.27 673.58,397.80
             672.19,397.32 671.21,396.54 670.62,395.47
             670.04,394.39 669.75,392.92 669.75,391.06
             669.75,391.06 669.75,381.09 669.75,381.09
             669.75,378.20 670.52,376.12 672.08,374.88
             673.63,373.62 676.17,373.00 679.70,373.00
             683.90,373.00 686.36,373.90 687.09,375.70
             687.09,375.70 687.09,373.45 687.09,373.45
             687.09,373.45 691.30,373.45 691.30,373.45
             691.30,373.45 691.30,400.59 691.30,400.59
             691.30,402.88 690.88,404.66 690.05,405.95
             689.21,407.25 687.96,408.17 686.30,408.70
             684.63,409.23 682.46,409.50 679.80,409.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={48}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 680.34,395.56
           C 682.72,395.56 684.38,395.27 685.34,394.69
             686.31,394.11 686.80,393.01 686.80,391.41
             686.80,391.41 686.80,380.16 686.80,380.16
             686.80,379.02 686.62,378.16 686.27,377.58
             685.92,377.00 685.28,376.58 684.34,376.33
             683.41,376.07 682.05,375.94 680.25,375.94
             678.55,375.94 677.27,376.06 676.42,376.30
             675.58,376.54 674.99,376.96 674.67,377.56
             674.36,378.16 674.20,379.02 674.20,380.16
             674.20,380.16 674.20,391.41 674.20,391.41
             674.20,392.56 674.37,393.43 674.70,394.02
             675.04,394.60 675.63,395.01 676.50,395.23
             677.36,395.45 678.64,395.56 680.34,395.56Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={49}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 708.84,398.50
           C 701.45,398.50 697.75,396.01 697.75,391.02
             697.75,391.02 697.75,381.34 697.75,381.34
             697.75,378.38 698.59,376.25 700.27,374.95
             701.95,373.65 704.68,373.00 708.45,373.00
             712.02,373.00 714.59,373.64 716.17,374.91
             717.75,376.17 718.55,378.35 718.55,381.45
             718.55,381.45 718.55,386.94 718.55,386.94
             718.55,386.94 702.30,386.94 702.30,386.94
             702.30,386.94 702.30,390.70 702.30,390.70
             702.30,392.43 702.88,393.68 704.05,394.44
             705.21,395.19 707.02,395.56 709.45,395.56
             711.75,395.56 714.47,395.15 717.59,394.33
             717.59,394.33 717.59,397.62 717.59,397.62
             714.53,398.21 711.61,398.50 708.84,398.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={50}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 714.05,384.00
           C 714.05,384.00 714.05,380.00 714.05,380.00
             714.05,378.45 713.60,377.39 712.72,376.81
             711.84,376.23 710.34,375.94 708.20,375.94
             706.13,375.94 704.62,376.23 703.69,376.81
             702.76,377.39 702.30,378.45 702.30,380.00
             702.30,380.00 702.30,384.00 702.30,384.00
             702.30,384.00 714.05,384.00 714.05,384.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={51}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 737.66,374.00
           C 737.66,374.00 742.16,374.00 742.16,374.00
             742.16,374.00 742.16,376.16 742.16,376.16
             742.61,374.05 745.33,373.00 750.30,373.00
             754.33,373.00 756.84,374.00 757.84,376.00
             758.14,375.03 759.05,374.29 760.56,373.78
             762.08,373.26 763.95,373.00 766.16,373.00
             769.16,373.00 771.27,373.57 772.50,374.70
             773.73,375.83 774.34,377.61 774.34,380.03
             774.34,380.03 774.34,398.00 774.34,398.00
             774.34,398.00 769.84,398.00 769.84,398.00
             769.84,398.00 769.84,380.05 769.84,380.05
             769.84,379.40 769.79,378.88 769.69,378.47
             769.59,378.05 769.39,377.64 769.09,377.22
             768.43,376.37 766.95,375.94 764.66,375.94
             762.88,375.94 761.57,376.06 760.70,376.30
             759.84,376.54 759.24,376.95 758.92,377.53
             758.61,378.12 758.45,378.95 758.45,380.05
             758.45,380.05 758.45,398.00 758.45,398.00
             758.45,398.00 753.95,398.00 753.95,398.00
             753.95,398.00 753.95,380.05 753.95,380.05
             753.95,379.40 753.90,378.88 753.80,378.47
             753.70,378.05 753.50,377.64 753.20,377.22
             752.54,376.37 751.07,375.94 748.80,375.94
             746.96,375.94 745.58,376.06 744.64,376.30
             743.71,376.54 743.07,376.95 742.70,377.52
             742.34,378.08 742.16,378.92 742.16,380.05
             742.16,380.05 742.16,398.00 742.16,398.00
             742.16,398.00 737.66,398.00 737.66,398.00
             737.66,398.00 737.66,374.00 737.66,374.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={52}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 790.09,398.50
           C 787.80,398.50 786.03,398.27 784.78,397.83
             783.53,397.37 782.63,396.57 782.08,395.42
             781.52,394.28 781.25,392.62 781.25,390.47
             781.25,388.55 781.50,387.07 782.02,386.02
             782.54,384.95 783.42,384.20 784.67,383.75
             785.92,383.29 787.70,383.06 790.00,383.06
             790.00,383.06 797.25,383.06 797.25,383.06
             797.25,383.06 797.25,379.28 797.25,379.28
             797.25,378.40 797.07,377.72 796.72,377.25
             796.38,376.77 795.79,376.43 794.97,376.23
             794.16,376.04 792.97,375.94 791.41,375.94
             788.91,375.94 786.13,376.13 783.09,376.52
             783.09,376.52 783.09,373.45 783.09,373.45
             786.39,373.15 789.38,373.00 792.05,373.00
             794.85,373.00 796.91,373.21 798.25,373.64
             799.58,374.06 800.50,374.79 801.00,375.84
             801.50,376.89 801.75,378.50 801.75,380.69
             801.75,380.69 801.75,398.00 801.75,398.00
             801.75,398.00 797.41,398.00 797.41,398.00
             797.41,398.00 797.41,395.72 797.41,395.72
             797.03,397.57 794.59,398.50 790.09,398.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={53}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 790.84,395.56
           C 792.48,395.56 793.86,395.44 795.00,395.19
             796.50,394.88 797.25,394.14 797.25,392.97
             797.25,392.97 797.25,386.00 797.25,386.00
             797.25,386.00 790.30,386.00 790.30,386.00
             788.87,386.00 787.84,386.12 787.20,386.34
             786.57,386.57 786.15,387.01 785.95,387.64
             785.75,388.28 785.66,389.30 785.66,390.70
             785.66,391.98 785.77,392.95 786.00,393.64
             786.23,394.33 786.64,394.82 787.25,395.12
             787.85,395.42 788.75,395.56 789.95,395.56
             789.95,395.56 790.84,395.56 790.84,395.56Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={54}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 809.66,374.00
           C 809.66,374.00 814.16,374.00 814.16,374.00
             814.16,374.00 814.16,376.50 814.16,376.50
             814.61,374.17 817.26,373.00 822.09,373.00
             825.20,373.00 827.38,373.57 828.62,374.70
             829.88,375.83 830.50,377.61 830.50,380.03
             830.50,380.03 830.50,398.00 830.50,398.00
             830.50,398.00 826.00,398.00 826.00,398.00
             826.00,398.00 826.00,380.05 826.00,380.05
             826.00,379.39 825.95,378.86 825.84,378.45
             825.75,378.04 825.55,377.62 825.25,377.22
             824.58,376.37 823.10,375.94 820.80,375.94
             819.04,375.94 817.68,376.07 816.72,376.33
             815.77,376.58 815.10,377.00 814.72,377.58
             814.34,378.16 814.16,378.98 814.16,380.05
             814.16,380.05 814.16,398.00 814.16,398.00
             814.16,398.00 809.66,398.00 809.66,398.00
             809.66,398.00 809.66,374.00 809.66,374.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={55}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 846.09,398.50
           C 843.80,398.50 842.03,398.27 840.78,397.83
             839.53,397.37 838.63,396.57 838.08,395.42
             837.52,394.28 837.25,392.62 837.25,390.47
             837.25,388.55 837.50,387.07 838.02,386.02
             838.54,384.95 839.42,384.20 840.67,383.75
             841.92,383.29 843.70,383.06 846.00,383.06
             846.00,383.06 853.25,383.06 853.25,383.06
             853.25,383.06 853.25,379.28 853.25,379.28
             853.25,378.40 853.07,377.72 852.72,377.25
             852.38,376.77 851.79,376.43 850.97,376.23
             850.16,376.04 848.97,375.94 847.41,375.94
             844.91,375.94 842.13,376.13 839.09,376.52
             839.09,376.52 839.09,373.45 839.09,373.45
             842.39,373.15 845.38,373.00 848.05,373.00
             850.85,373.00 852.91,373.21 854.25,373.64
             855.58,374.06 856.50,374.79 857.00,375.84
             857.50,376.89 857.75,378.50 857.75,380.69
             857.75,380.69 857.75,398.00 857.75,398.00
             857.75,398.00 853.41,398.00 853.41,398.00
             853.41,398.00 853.41,395.72 853.41,395.72
             853.03,397.57 850.59,398.50 846.09,398.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={56}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 846.84,395.56
           C 848.48,395.56 849.86,395.44 851.00,395.19
             852.50,394.88 853.25,394.14 853.25,392.97
             853.25,392.97 853.25,386.00 853.25,386.00
             853.25,386.00 846.30,386.00 846.30,386.00
             844.87,386.00 843.84,386.12 843.20,386.34
             842.57,386.57 842.15,387.01 841.95,387.64
             841.75,388.28 841.66,389.30 841.66,390.70
             841.66,391.98 841.77,392.95 842.00,393.64
             842.23,394.33 842.64,394.82 843.25,395.12
             843.85,395.42 844.75,395.56 845.95,395.56
             845.95,395.56 846.84,395.56 846.84,395.56Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={57}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 874.80,409.50
           C 871.49,409.50 868.71,409.33 866.45,409.00
             866.45,409.00 866.45,405.81 866.45,405.81
             870.45,406.31 873.37,406.56 875.20,406.56
             877.50,406.56 879.18,406.16 880.22,405.38
             881.27,404.58 881.80,403.27 881.80,401.42
             881.80,401.42 881.80,395.41 881.80,395.41
             881.73,396.47 881.01,397.25 879.62,397.75
             878.24,398.25 876.50,398.50 874.41,398.50
             871.91,398.50 869.96,398.27 868.58,397.80
             867.19,397.32 866.21,396.54 865.62,395.47
             865.04,394.39 864.75,392.92 864.75,391.06
             864.75,391.06 864.75,381.09 864.75,381.09
             864.75,378.20 865.52,376.12 867.08,374.88
             868.63,373.62 871.17,373.00 874.70,373.00
             878.90,373.00 881.36,373.90 882.09,375.70
             882.09,375.70 882.09,373.45 882.09,373.45
             882.09,373.45 886.30,373.45 886.30,373.45
             886.30,373.45 886.30,400.59 886.30,400.59
             886.30,402.88 885.88,404.66 885.05,405.95
             884.21,407.25 882.96,408.17 881.30,408.70
             879.63,409.23 877.46,409.50 874.80,409.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={58}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 875.34,395.56
           C 877.72,395.56 879.38,395.27 880.34,394.69
             881.31,394.11 881.80,393.01 881.80,391.41
             881.80,391.41 881.80,380.16 881.80,380.16
             881.80,379.02 881.62,378.16 881.27,377.58
             880.92,377.00 880.28,376.58 879.34,376.33
             878.41,376.07 877.05,375.94 875.25,375.94
             873.55,375.94 872.27,376.06 871.42,376.30
             870.58,376.54 869.99,376.96 869.67,377.56
             869.36,378.16 869.20,379.02 869.20,380.16
             869.20,380.16 869.20,391.41 869.20,391.41
             869.20,392.56 869.37,393.43 869.70,394.02
             870.04,394.60 870.63,395.01 871.50,395.23
             872.36,395.45 873.64,395.56 875.34,395.56Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={59}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 903.84,398.50
           C 896.45,398.50 892.75,396.01 892.75,391.02
             892.75,391.02 892.75,381.34 892.75,381.34
             892.75,378.38 893.59,376.25 895.27,374.95
             896.95,373.65 899.68,373.00 903.45,373.00
             907.02,373.00 909.59,373.64 911.17,374.91
             912.75,376.17 913.55,378.35 913.55,381.45
             913.55,381.45 913.55,386.94 913.55,386.94
             913.55,386.94 897.30,386.94 897.30,386.94
             897.30,386.94 897.30,390.70 897.30,390.70
             897.30,392.43 897.88,393.68 899.05,394.44
             900.21,395.19 902.02,395.56 904.45,395.56
             906.75,395.56 909.47,395.15 912.59,394.33
             912.59,394.33 912.59,397.62 912.59,397.62
             909.53,398.21 906.61,398.50 903.84,398.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={60}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 909.05,384.00
           C 909.05,384.00 909.05,380.00 909.05,380.00
             909.05,378.45 908.60,377.39 907.72,376.81
             906.84,376.23 905.34,375.94 903.20,375.94
             901.13,375.94 899.62,376.23 898.69,376.81
             897.76,377.39 897.30,378.45 897.30,380.00
             897.30,380.00 897.30,384.00 897.30,384.00
             897.30,384.00 909.05,384.00 909.05,384.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={61}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 920.66,374.00
           C 920.66,374.00 925.16,374.00 925.16,374.00
             925.16,374.00 925.16,376.16 925.16,376.16
             925.61,374.05 928.33,373.00 933.30,373.00
             937.33,373.00 939.84,374.00 940.84,376.00
             941.14,375.03 942.05,374.29 943.56,373.78
             945.08,373.26 946.95,373.00 949.16,373.00
             952.16,373.00 954.27,373.57 955.50,374.70
             956.73,375.83 957.34,377.61 957.34,380.03
             957.34,380.03 957.34,398.00 957.34,398.00
             957.34,398.00 952.84,398.00 952.84,398.00
             952.84,398.00 952.84,380.05 952.84,380.05
             952.84,379.40 952.79,378.88 952.69,378.47
             952.59,378.05 952.39,377.64 952.09,377.22
             951.43,376.37 949.95,375.94 947.66,375.94
             945.88,375.94 944.57,376.06 943.70,376.30
             942.84,376.54 942.24,376.95 941.92,377.53
             941.61,378.12 941.45,378.95 941.45,380.05
             941.45,380.05 941.45,398.00 941.45,398.00
             941.45,398.00 936.95,398.00 936.95,398.00
             936.95,398.00 936.95,380.05 936.95,380.05
             936.95,379.40 936.90,378.88 936.80,378.47
             936.70,378.05 936.50,377.64 936.20,377.22
             935.54,376.37 934.07,375.94 931.80,375.94
             929.96,375.94 928.58,376.06 927.64,376.30
             926.71,376.54 926.07,376.95 925.70,377.52
             925.34,378.08 925.16,378.92 925.16,380.05
             925.16,380.05 925.16,398.00 925.16,398.00
             925.16,398.00 920.66,398.00 920.66,398.00
             920.66,398.00 920.66,374.00 920.66,374.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={62}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 975.84,398.50
           C 968.45,398.50 964.75,396.01 964.75,391.02
             964.75,391.02 964.75,381.34 964.75,381.34
             964.75,378.38 965.59,376.25 967.27,374.95
             968.95,373.65 971.68,373.00 975.45,373.00
             979.02,373.00 981.59,373.64 983.17,374.91
             984.75,376.17 985.55,378.35 985.55,381.45
             985.55,381.45 985.55,386.94 985.55,386.94
             985.55,386.94 969.30,386.94 969.30,386.94
             969.30,386.94 969.30,390.70 969.30,390.70
             969.30,392.43 969.88,393.68 971.05,394.44
             972.21,395.19 974.02,395.56 976.45,395.56
             978.75,395.56 981.47,395.15 984.59,394.33
             984.59,394.33 984.59,397.62 984.59,397.62
             981.53,398.21 978.61,398.50 975.84,398.50Z"
        />
        <motion.path
          id="Selection"
          fill="var(--hero)"
          stroke="black"
          stroke-width="1"
          custom={63}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 981.05,384.00
           C 981.05,384.00 981.05,380.00 981.05,380.00
             981.05,378.45 980.60,377.39 979.72,376.81
             978.84,376.23 977.34,375.94 975.20,375.94
             973.13,375.94 971.62,376.23 970.69,376.81
             969.76,377.39 969.30,378.45 969.30,380.00
             969.30,380.00 969.30,384.00 969.30,384.00
             969.30,384.00 981.05,384.00 981.05,384.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={64}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d=" M 992.66,374.00
           C 992.66,374.00 997.16,374.00 997.16,374.00
             997.16,374.00 997.16,376.50 997.16,376.50
             997.61,374.17 1000.26,373.00 1005.09,373.00
             1008.20,373.00 1010.38,373.57 1011.62,374.70
             1012.88,375.83 1013.50,377.61 1013.50,380.03
             1013.50,380.03 1013.50,398.00 1013.50,398.00
             1013.50,398.00 1009.00,398.00 1009.00,398.00
             1009.00,398.00 1009.00,380.05 1009.00,380.05
             1009.00,379.39 1008.95,378.86 1008.84,378.45
             1008.75,378.04 1008.55,377.62 1008.25,377.22
             1007.58,376.37 1006.10,375.94 1003.80,375.94
             1002.04,375.94 1000.68,376.07 999.72,376.33
             998.77,376.58 998.10,377.00 997.72,377.58
             997.34,378.16 997.16,378.98 997.16,380.05
             997.16,380.05 997.16,398.00 997.16,398.00
             997.16,398.00 992.66,398.00 992.66,398.00
             992.66,398.00 992.66,374.00 992.66,374.00Z"
        />
        <motion.path
          id="Selection"
          fill="var(--islab)"
          stroke="black"
          stroke-width="1"
          custom={65}
          initial={"hidden"}
          animate={controlsSmall}
          variants={pathVariants}
          d="M 1031.80,398.50
           C 1029.73,398.50 1028.12,398.27 1026.95,397.83
             1025.79,397.38 1024.94,396.62 1024.42,395.55
             1023.91,394.48 1023.66,392.96 1023.66,391.02
             1023.66,391.02 1023.66,376.94 1023.66,376.94
             1023.66,376.94 1018.25,376.94 1018.25,376.94
             1018.25,376.94 1018.25,374.00 1018.25,374.00
             1018.25,374.00 1023.66,374.00 1023.66,374.00
             1023.66,374.00 1023.66,366.00 1023.66,366.00
             1023.66,366.00 1028.20,366.00 1028.20,366.00
             1028.20,366.00 1028.20,374.00 1028.20,374.00
             1028.20,374.00 1036.84,374.00 1036.84,374.00
             1036.84,374.00 1036.84,376.94 1036.84,376.94
             1036.84,376.94 1028.20,376.94 1028.20,376.94
             1028.20,376.94 1028.20,391.25 1028.20,391.25
             1028.20,392.44 1028.34,393.33 1028.62,393.92
             1028.91,394.52 1029.41,394.94 1030.12,395.19
             1030.84,395.44 1031.88,395.56 1033.25,395.56
             1033.69,395.56 1034.88,395.43 1036.84,395.17
             1036.84,395.17 1036.84,398.11 1036.84,398.11
             1035.14,398.37 1033.46,398.50 1031.80,398.50Z"
        />
      </motion.svg>
    </motion.div>
  );
}
export default SVG_IslabTitle;
