import React, { useEffect, useState } from 'react'
import './Projects.css'
import AlertFade from '../../utilities/Alert'
import ProjectsCard from './ProjectsCard'
import MotionHoC from '../../utilities/MotionHoC'


function ProjectsComponent() {
    const [alert, setAlert] = useState({
        active: false,
        message: '',
        type: ''
    })

    const [projects, setProjects] = useState([])


    useEffect(() => {
        fetch(`/projects/show-all-projects`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(r => {                    //il primo then ritorna la promise relativa allo stato
                if (r.status === 200) {
                    return r.json();
                } else {
                    setAlert(s => ({ ...s, active: true, message: r.Error, type: 'wrong' }));
                    throw new Error("Error");
                }
            })
            .then((res) => {       //il secondo il risultato
                if (res.success === true) {
                    setProjects(res.projects)
                }
                else {
                    setAlert(s => ({ ...s, active: true, message: res.message, type: 'wrong' }));
                }
            })
            .catch((err) => {
                ;
            });
            window.scrollTo(0, 0);

    }, []);

    if (projects.length === 0) {
        return (
            <div className='loader' />
        );
    }

    return (
        <>
            <div className='project__container'>
                <div className='main_title' children={`PROJECTS `} />
                <div className='project__wrapper'>

                    {projects.map((item, index) =>
                        <ProjectsCard
                            key={index}
                            img_URL={item.img_URL}
                            link={item.link}
                            title={item.title}
                            subtitle={item.subtitle}
                            html={item.html_switch}
                            description={item.description}

                        />
                    )}
                </div>
            </div>
            <AlertFade
                showAlert={alert.active}
                onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
                message={alert.message}
                type={alert.type}
            />
        </>
    )
}

const Projects = MotionHoC(ProjectsComponent)

export default Projects
