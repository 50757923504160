
import React, { useState } from 'react'
import AlertFade from './Alert'
import './ChangePhoto.css'

function ChangePhoto({ image, setImage }) {

    const [alert, setAlert] = useState({
        active: false,
        message: '',
        type: ''
    })

    const [imgPreviewUrl, setImgPreviewUrl] = useState()


    const onChange = (e) => {

        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];

        if (checkMimeType(file)) {
            setAlert(s => ({ ...s, active: true, message: 'Format Error! please insert png or jpg file', type: 'wrong' }));
        }
        else {
            reader.onloadend = () => {
                setImage(file)
                setImgPreviewUrl(reader.result)
            }
            reader.readAsDataURL(file);
        }
    }

    const onClickDelete = (e) => {
        setImage()
        setImgPreviewUrl()
    }

    const checkMimeType = (file) => {
        // list allow mime type
        const types = ['image/jpg', 'image/png', 'image/jpeg']
        // loop access array

        if (types.every(type => file.type !== type))
            return true;
        return false;
    }


    return (
        <>
            <label htmlFor="photo-upload" className="change__photo__file__upload">
                <div className="change__photo__img__wrap change__photo__img__upload" >
                    <i className={imgPreviewUrl ? "fas change__photo__img__upload delete" : "fas change__photo__img__upload"} />
                    <img htmlFor="photo-upload" src={imgPreviewUrl ? imgPreviewUrl : `${image}`} alt='1' />
                </div>
                {imgPreviewUrl ?
                    <button id="photo-upload" className="change__photo__delete__button" onClick={onClickDelete} />
                    :
                    <input id="photo-upload" className='change__photo__input' type="file" onChange={onChange} />
                }
            </label>
            <AlertFade
                showAlert={alert.active}
                onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
                message={alert.message}
                type={alert.type}
            />
        </>
    )
}

export default ChangePhoto

