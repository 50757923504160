import React, { useEffect, useState, useMemo } from 'react'
import ProjectCard from './ProjectCard'
import AlertFade from '../../utilities/Alert'
import { FaPlusCircle, FaCheck } from 'react-icons/fa'
import { IoMdMove } from 'react-icons/io'
import { MdRemoveCircle } from 'react-icons/md';
import CreateProject from './CreateProject'
import UpdateProject from './UpdateProject'
import { Redirect } from 'react-router'
import styles from './ManageProjects.module.css'
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";
import { useSpring, animated } from 'react-spring'
import { fetchCheckActiveUser, fetchDeleteProject, fetchGetProject, fetchUpdateProject } from '../../utilities/utilityFetch';
import { Switch } from '../../utilities/Button';

export function numberOfElement(setNumberElement) {
  if (window.innerWidth > 960)
    setNumberElement(3);
  else if (window.innerWidth > 600)
    setNumberElement(2);
  else
    setNumberElement(1);
}

function ManageProjects(props) {

  const [checkActiveUser, setCheckActiveUser] = useState()
  useMemo(() => setCheckActiveUser(fetchCheckActiveUser()), [])

  const animate = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });

  const [numberElement, setNumberElement] = useState(3);
  const [projects, setProjects] = useState([])
  const [moveMode, setMoveMode] = useState();
  const [editProject, setEditProject] = useState()
  const [createProject, setCreateProject] = useState(false)
  const [updateProject, setUpdateProject] = useState(false)
  const [delProject, setDelProject] = useState();

  const [alert, setAlert] = useState({
    active: false,
    message: '',
    type: ''
  });

  /* LONG PRESS */
  /*  const callback = useCallback(() => {
     console.log(updateProject, createProject)
     if (!(updateProject && createProject)) {
       setMoveMode(prev => {
         setAlert(s => ({ ...s, active: true, message: `${'Move Mode ' + !prev}`, type: 'success' }))
         return !prev;
       });
     }
   }, []);
 
   const bind = useLongPress(updateProject || createProject ? '' : callback,
     moveMode ?
       {
         threshold: 3000,
         captureEvent: true,
         detect: LongPressDetectEvents.BOTH
       }
       :
       {
         threshold: 1000,
         captureEvent: true,
         detect: LongPressDetectEvents.BOTH
       }
   ); */


  useEffect(() => {
    fetchGetProject().then(r => r.success && setProjects(r.projects))
  }, []);

  useEffect(() => {
    moveMode !== undefined && setAlert(s => ({ ...s, active: true, message: `${'Move Mode ' + moveMode}`, type: 'success' }))
    window.scrollTo(0, 0);
  }, [moveMode])

  useEffect(() => {

    projects.map((item, index) =>
      item.id = index);

    var data = { 'list': projects }

    fetchUpdateProject(data)
  }, [projects])


  const deleteClick = () => {
    fetchDeleteProject(delProject)
      .then(r => {
        if (r.success) {
          setAlert(s => ({ ...s, active: false, }))
          fetchGetProject()
            .then(res => res.success && setProjects(res.projects))

        } else {
          setAlert(s => ({ ...s, active: true, message: r.message, type: 'wrong', time: true }))
        }
      })
  }



  if (projects.length === 0) {
    return (
      <div className={styles.container}>
        <div className='loader' />
      </div>
    )
  }

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(projects, sourceIndex, targetIndex);
    setProjects(nextState);
  }

  window.addEventListener('resize', () => { numberOfElement(setNumberElement) });
  window.addEventListener('load', () => { numberOfElement(setNumberElement) });

  if (checkActiveUser) {
    return (
      <animated.div style={animate} >
        <span style={{ marginTop: '40px', marginLeft: '80%', position: 'absolute' }}>
          <Switch label={<IoMdMove color='var(--main)' size={30} />} value={moveMode} setValue={setMoveMode} />
        </span>
        <FaPlusCircle className={styles.add_icon} onClick={() => { setCreateProject(true); }} />
        <div  /* {...bind} */ className={styles.container} style={moveMode ? { cursor: 'grab' } : { cursor: 'default' }}>
          <div className={styles.dropzone_container} >
            {
              <GridContextProvider onChange={onChange} >
                <GridDropZone
                  className={styles.dropzone}
                  id="left"
                  boxesPerRow={numberElement}
                  rowHeight={350}
                  disableDrag={!moveMode}
                  disableDrop={!moveMode}
                  style={{ height: `${projects.length/numberElement*400}px`, Overflow :'auto' }}
                >
                  {
                    projects.map((item, key) => (
                      <div key={key}>
                        <GridItem key={item.name} style={moveMode ? { cursor: 'grab' } : { cursor: 'default' }}>
                          <ProjectCard
                            project={item}
                            setShowModal={setUpdateProject}
                            setEditProject={setEditProject}
                            moveMode={moveMode}
                            setAlert={setAlert}
                            setDelProject={setDelProject}
                          />
                        </GridItem>
                      </div>
                    ))
                  }
                </GridDropZone>


              </GridContextProvider>
            }
            <CreateProject
              position={projects.length}
              showModal={createProject}
              setShowModal={setCreateProject}
              setAlert={setAlert}
              setProjects={setProjects}
            />

            <UpdateProject
              editProject={editProject}
              showModal={updateProject}
              setShowModal={setUpdateProject}
              setAlert={setAlert}
              setProjects={setProjects}
            />
          </div>


          {/* <AlertFade
          showAlert={alert.active}
          onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
          message={alert.message}
          type={alert.type}
        /> */}

          <AlertFade
            showAlert={alert.active}
            onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
            message={alert.message}
            type={alert.type}
            time={alert.time}
          >
            {alert.type === 'warning' ?
              <div>
                <MdRemoveCircle className={'icon-btn'} color='var(--wrong)' style={{ left: '10%' }} onClick={() => setAlert(s => ({ ...s, active: false }))} />
                <FaCheck className={'icon-btn'} color='var(--success)' style={{ right: '10%' }} onClick={deleteClick} />
              </div>
              :
              null}
          </AlertFade>
        </div>
      </animated.div >

    )
  }
  else if (checkActiveUser === false) {
    sessionStorage.setItem('isMan', false);
    return <Redirect to='/login' />
  }
  else {
    return <div className='loader' />
  }
}


export default ManageProjects;