
export async function fetchLogin(data) {
    return await fetch(`/api/login`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error('Error');
            }
        })
        .then((res) => res)     //il secondo il risultato
        .catch((err) => { });

}

export async function fetchCasLogin() {
    return await fetch(`/login`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
        .then(r => {
            //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error('Error');
            }
        })
        .then((res) => res);
}

export async function fetchLogout() {
    return await fetch(`/api/logout`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error("Error");
            }
        })
        .then((res) => {       //il secondo il risultato
            if (res.success === true) {
                sessionStorage.setItem('isMan', false);
            }
            return res;

        });
}


export async function fetchCheckActiveUser() {
    return await fetch(`/api/check-active-user`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
        .then(r => {
            //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error('Error');

            }
        })
        .then((res) => true)
        .catch(err => { return false })
}

// ---- USER -----
export async function fetchGetUser() {
    return await fetch(`/manage/get-user`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw Error;
            }
        })
        .then((res) => {       //il secondo il risultato
            if (res.success === true)
                return res.user
            return null

        }).catch(err => { })

}
export async function fetchGetAllUser() {
    return await fetch(`/manage/show-all-users`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw Error;
            }
        })
        .then((res) => res).catch(err => { })
}

export async function fetchSetUser(data) {
    return await fetch(`/manage/create-user`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            }
            else {
                throw Error('Error')
            }
        })
        .then((res) => res)
        .catch((err) => { });
}

export async function fetchUpdateUser(data) {
    return await fetch((`/manage/update-user`), {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            }
            else {
                throw Error('Error')
            }
        })
        .then((res) => res)
        .catch((err) => { });
}

export async function fetchDeleteUser(data) {
    return await fetch(`/manage/delete-user`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'email': data })
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            }
            else {
                throw Error('Error')
            }
        })
        .then((res) => res)
        .catch((err) => { });
}

// ---- TEAM -----
export async function fetchGetTeam() {
    return await fetch(`/team/show-team`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error("Error");
            }
        })
        .then((res) => res)
        .catch((err) => { });
}

// ---- PROJECT -----
export async function fetchGetProject(data) {
    return await fetch(`/manage/show-projects`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error("Error");
            }
        })
        .then((res) => res)
        .catch((err) => { });
}

export async function fetchSetProject(data) {
    return await fetch((`/manage/create-project`), {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            }
            else {
                throw new Error('Error')
            }
        })
        .then((res) => res
        )
        .catch((err) => { });
}

export async function fetchUpdateProject(data) {
    return await fetch((`/manage/update-project`), {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            }
            else {
                throw Error('Error')
            }
        })
        .then((res) => res)
        .catch((err) => { });
}

export async function fetchDeleteProject(data) {
    return await fetch(`/manage/delete-project`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'title': data })
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            }
            else {
                throw Error('Error')
            }
        })
        .then((res) => res)
        .catch((err) => { });
}

// ---- INFO HOME -----
export async function fetchGetInfoHome() {
    return await fetch(`/manage/get-info-home`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error("Error");
            }
        })
        .then((res) => res)
        .catch((err) => { })
}

export async function fetchSetInfoHome(data) {
    return await fetch(`/manage/create-info-home`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            }
            else {
                throw new Error("Error");
            }
        })
        .then((res) => res)
        .catch((err) => { });
}

export async function fetchDeleteInfoHome(id) {
    return await fetch(`/manage/delete-info-home`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'id': id })
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            }
            else {
                throw Error;
            }
        })
        .then((res) => res)
        .catch((err) => { });
}

export async function fetchUpdateInfoHome(data) {
    return await fetch(`/manage/update-info-home`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error("Error");
            }

        }).then(res => res)

        .catch((err) => { });
}


// --- FILES ---
export async function fetchUploadPhoto(file, elem, type) {
    const data = new FormData()
    data.append('file', file);

    if (type === 'project')
        data.append('title', elem);

    else if (type === 'user')
        data.append('email', elem);


    if (file.length === 0) {
        return await null;
    }
    else {
        return await fetch(`/manage/upload-image`, {
            method: 'POST',
            credentials: 'include',
            body: data
        })
            .then(r => {                    //il primo then ritorna la promise relativa allo stato
                if (r.status === 200) {
                    return r.json();
                }
                else if (r.status === 413) {
                    return { message: 'File too large, Maximum size 1MB', success: false };
                } else {
                    throw new Error('Error');
                }
            })
            .then((res) => res)
            .catch((err) => err);
    }
}

export async function fetchUploadProjectFiles(files, title, index) {
    const data = new FormData()

    for (var x = 0; x < files.length; x++) {
        data.append('file', files[x])
    }
    data.append('title', title);
    data.append('id', index);

    return await fetch(`/manage/upload-files`, {
        method: 'POST',
        credentials: 'include',
        body: data
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            }
            else if (r.status === 413) {
                return { message: 'File too large, Maximum size 1MB', success: false };
            } else {
                throw new Error('Error');
            }
        })
        .then((res) => res)
        .catch((err) => { });
}

export async function fetchGetFolder(path) {
    return await fetch(`/manage/get-folders?path=${path}`, {
        method: 'GET',
        credentials: "include"
    })
        .then(r => {
            //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error('Error');
            }
        })
        .then((res) => res)
        .catch((err) => { });
}


export async function fetchGetFiles(path) {
    return await fetch(`/manage/get-files?path=${path}`, {
        method: 'GET',
        credentials: "include"
    })
        .then(r => {
            //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error('Error');
            }
        })
        .then((res) => res)
        .catch((err) => { });
}

export async function fetchDeleteProjectFile(filename, title) {
    return await fetch(`/manage/delete-file`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'fileName': filename, 'title': title })
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            }
            else {
                throw Error('Error')
            }
        })
        .then((res) => res)
        .catch((err) => { });
}

// ---- TAB INFO ---

export async function fetchMenuTabExist(tabName, email) {
    return await fetch(`/manage/menu-tab-exist?tabName=${tabName}&email=${email}`, {
        method: 'GET',
        credentials: 'include',
    })
        .then(r => {                    //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error('Error');
            }
        })
        .then((res) => {       //il secondo il risultato
            return res
        }).catch((err) => {
        })
}

//---- FEED ----
export async function fetchGetFeed(channel) {
    return await fetch(`/api/get-feed?channel=${channel}`,
        { method: 'GET' }).then(r => {            //il primo then ritorna la promise relativa allo stato
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error('Error');
            }
        })
        .then((res) => res).catch()
}