import React from 'react'
import styles from './TeamUserInfo.module.css';
import {  FaUser, FaPhone, FaUniversity, FaPencilRuler } from 'react-icons/fa'
import { IoMail } from 'react-icons/io5'

function TeamUserInfo({ name, role, email, phone, address, img_URL, cv, ariel_site }) {

    return (

        <div className={styles.container}>
            <div className={styles.grid}>

                <div className={styles.img_wrap}>
                    <img src={img_URL} alt="1" />
                </div>

                <div className={styles.item_wrap}>
                    <li className={styles.item}>
                        <FaUser className={styles.icon} /> {name}
                    </li >
                    <li className={styles.item}>
                        <FaPencilRuler className={styles.icon} /> {role}
                    </li >
                    <li className={styles.item}>
                        <IoMail className={styles.icon} /> {email}
                    </li>
                    {
                        phone ?
                            <li className={styles.item}>
                                <FaPhone className={styles.icon} /> {phone}
                            </li>
                            :
                            ''
                    }
                    {
                        address ?
                            <li className={styles.item}>
                                <FaUniversity className={styles.icon} />{address}
                            </li>
                            :
                            ''
                    }
                </div>

               {/*  <div style={{ margin: '5%' }}>
                    <Feed
                        channel={name}
                        options={{ height: 300 }}

                    />
                </div> */}
            </div>

            <div className={styles.iconSpecial}>
               {/*  {cv ? <FaIdCard className={styles.ariel} onClick={() => window.open(cv, "_blank")} /> : ''} */}
                {ariel_site ? <img src='/ariel.png' className={styles.ariel} onClick={() => window.open(ariel_site, "_blank")} alt=''/> : ''}
            </div>

        </div>
    )
}

export default TeamUserInfo
