import React, { useEffect } from 'react';
import styles from './ManageHome.module.css'
import { useForm } from "react-hook-form"
import { TextArea } from '../../utilities/InputForm';
import { Btn } from '../../utilities/Button';
import { FaSave } from 'react-icons/fa';
import { RiQuestionAnswerFill } from 'react-icons/ri'
import Modal, { ModalFooter } from '../../utilities/Modal';
import { fetchGetInfoHome, fetchUpdateInfoHome } from '../../utilities/utilityFetch';

function UpdateHome(props) {

    const defaultValues = {
        question: '',
        answer: '',
        id: 0,
        active: true
    }
    const { control, handleSubmit, reset } = useForm({
        defaultValues: defaultValues,
        mode: 'onChange'
    });

    useEffect(() => {
        reset(props.text);
    }, [props.text, reset])

    const onSubmit = data => {
        fetchUpdateInfoHome(data)
            .then(r => props.setAlert(s => ({ ...s, active: true, message: r.message, type: r.success ? 'success' : 'wrong' })))
            .then(() => fetchGetInfoHome())
            .then(r1 => r1.success && props.setText(r1.text))
            .then(props.setShowModal(false))
            .then(() => reset(defaultValues))
    }



    const setTitle = () => {
        if (props.text?.question.length > 10)
            return props.text.question.slice(0, 10) + '...'
        else
            return props.text?.question;
    }

    return (
        <Modal showModal={props.showModal} setShowModal={props.setShowModal} title={setTitle()} manage={true}>
            <form className='form-wrapper' >
                {/*  <InputText
                    name={'question'}
                    placeholder={"Question for your answer ( this field is optional) "}
                    control={control}
                    label={<RiQuestionFill className='icon' />}
                    className={styles.inputTextContainer}
                    inputClassName={styles.inputText}
                    rules={{
                        minLength: {
                            value: 3,
                            message: `question must exceed 2 characters`
                        },
                    }}
                /> */}
                <TextArea
                    name={'answer'}
                    placeholder={"Answer or Text "}
                    control={control}
                    label={<RiQuestionAnswerFill className='icon' />}
                    className={styles.textAreaContainer}
                    inputClassName={styles.textArea}
                    rules={{
                        minLength: {
                            value: 3,
                            message: `answer must exceed 2 characters`
                        },
                    }}
                />
            </form>
            <ModalFooter>
                <Btn size={'btn--sm'} styles={'btn--save'} onClick={handleSubmit(onSubmit)}>
                    <FaSave /> {'SAVE'}
                </Btn>
            </ModalFooter>

        </Modal >
    );
}



export default UpdateHome
