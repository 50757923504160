import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Team.module.css'
import AlertFade from '../../utilities/Alert'
import MotionHoC from '../../utilities/MotionHoC'

function TeamComponent() {

    const [alert, setAlert] = useState({
        active: false,
        message: '',
        type: ''
    })

    const [users, setUsers] = useState([])

    useEffect(() => {
        fetch(`/team/show-team`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(r => {                    //il primo then ritorna la promise relativa allo stato
                if (r.status === 200) {
                    return r.json();
                } else {
                    setAlert(s => ({ ...s, active: true, message: r.Error, type: 'wrong' }));
                    throw new Error("Error");
                }
            })
            .then((res) => {       //il secondo il risultato
                if (res.success === true) {
                    setUsers(res.users)
                }
                else {
                    setAlert(s => ({ ...s, active: true, message: res.message, type: 'wrong' }));
                }
            })
            .catch((err) => {
                ;
            });
        window.scrollTo(0, 0);

    }, []);

    if (users.length === 0) {
        return (
            <div className='loader' />
        );
    }

    return (
        <>
            <div className={styles.container}>
                <div className='main_title' children={`TEAM `} />
                <div className={styles.wrapper}>
                    {users.map((item, index) =>
                        <Link to={'/team/' + item.email} className={styles.card} key={index}>
                            <div className={styles.card_link}>
                                <img className={styles.card_picWrap} src={item.img_URL} alt={index} />
                            </div>
                            <div className={styles.card_info}>
                                <div className={`${styles.text} ${styles.name}`}>
                                    {item.name}
                                </div>
                                <div className={styles.text}>
                                    {item.role}
                                </div>
                               {/*  <div className={styles.text}>
                                    <MdEmail className={styles.icon} />
                                    {item.email}
                                </div>
                                {item.phone ?
                                    <div className={styles.text}>
                                        <MdPhone className={styles.icon} />
                                        {item.phone}
                                    </div>
                                    :
                                    ''
                                } */}
                            </div>
                        </Link>
                    )}
                </div>
            </div>
            <AlertFade
                showAlert={alert.active}
                onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
                message={alert.message}
                type={alert.type}
            />
        </>
    )
}

const Team = MotionHoC(TeamComponent)

export default Team;
