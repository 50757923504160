import React, { useState, useEffect, useMemo } from 'react'
import './PersonalInfo.css'
import { FaPlusCircle, FaEdit } from 'react-icons/fa'
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi'
import AlertFade from '../../utilities/Alert';
import TabInfoDescription from './TabInfoDescription';
import EditMenuTab from './EditMenuTab';
import AddMenuTab from './AddMenuTab';
import TabInfoCustom from './TabInfoCustom';
import { Redirect } from 'react-router';
import DropDown from '../../utilities/DropDown';
import MotionHoC from '../../utilities/MotionHoC';
import { fetchCheckActiveUser } from '../../utilities/utilityFetch';


function numberOfElement() {
    if (window.innerWidth > 960)
        return 3;
    else if (window.innerWidth > 600)
        return 2;
    else
        return 1;
}


function PersonalInfoComponent(props) {

    const [checkActiveUser, setCheckActiveUser] = useState()
    useMemo(() => fetchCheckActiveUser().then(res => setCheckActiveUser(res)), [])

    const [info, setInfo] = useState({
        email: '',
        menu: []
    })

    const [next_index, setNext_index] = useState(0)

    const maxElement = numberOfElement();

    const [slice, setSlice] = useState({
        start: 0,
        select: 0,
        tabName: 'Description',
        end: maxElement,
        menu: [],
    })

    const [alert, setAlert] = useState({
        active: false,
        message: '',
        type: '',
        time: true
    });

    const [addMenuItem, setAddMenuItem] = useState(false);
    const [editMenuItem, setEditMenuItem] = useState(false);

    const [users, setUsers] = useState({
        allEmail: [],
        emailSelect: ''
    });


    useEffect(() => {

        async function setAsyncSlice() {
            const tempMenu = await fetch(`/manage/get-personal-info${'?email=' + (users.emailSelect !== '' ? users.emailSelect : '')}`, {
                method: 'GET',
                credentials: "include"
            })
                .then(r => {
                    switch (r.status) {
                        case 200:
                            return r.json();
                        default:
                            throw new Error('Error');
                    }                  //il primo then ritorna la promise relativa allo stato
                })
                .then((res) => {       //il secondo il risultato
                    if (res.success === true) {
                        setInfo(s => ({ ...s, email: res.info.email, menu: res.info.menu }));

                        if (users.emailSelect === '')
                            setUsers(s => ({ ...s, emailSelect: res.info.email }));

                        return res.info.menu;
                    }
                    else {
                        setAlert(s => ({ ...s, active: true, message: res.message, type: 'wrong', time: true }));
                    }
                })
                .catch((err) => {
                    ;
                });
            window.scrollTo(0, 0);

            setSlice(s => ({ ...s, menu: tempMenu?.slice(0, tempMenu.length > maxElement ? maxElement : tempMenu.length) }));
        }

        setAsyncSlice();

        window.addEventListener("resize", handleResize);

    }, [users, maxElement])

    function handleResize() {
        setSlice(s => ({
            ...s,
            start: 0,
            select: 0,
            tabName: 'Description',
            end: numberOfElement()
        }));
    }


    useEffect(() => {
        if (info.menu[slice.start]) {

            if (slice.select <= slice.start) {
                setSlice(s => ({ ...s, menu: info.menu.slice(slice.start, slice.end), select: slice.start, tabName: info.menu[slice.start].name }));
            }
            else if (slice.select >= slice.end - 1) {

                setSlice(s => ({ ...s, menu: info.menu.slice(slice.start, slice.end), select: slice.end - 1, tabName: info.menu[slice.end - 1].name }));
            }
            else {
                setSlice(s => ({ ...s, menu: info.menu.slice(slice.start, slice.end) }));
            }
        }

        var max = 0
        for (var i = 0; i < info.menu.length; i++) {
            if (info.menu[i].id > max)
                max = info.menu[i].id
        }
        setNext_index(max + 1)


    }, [slice.start, slice.end, info.menu, slice.select]);


    useEffect(() => {

        async function setList() {
            var list = await fetch(`/manage/show-all-users`, {
                method: 'GET',
                credentials: 'include',

                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(r => {                    //il primo then ritorna la promise relativa allo stato
                if (r.status === 200) {
                    return r.json();
                } else {
                    throw new Error("Error");
                }
            }).then((res) => {       //il secondo il risultato
                if (res.success === true) {
                    var list = [];
                    res.users.map(item =>
                        list.push(item.email)
                    );
                    return list;
                }
                else {
                    window.alert(res.message)
                }
            }).catch((err) => {
            });

            if (list) {
                var indexEmailSelect = list.indexOf(users.emailSelect);
                list.splice(indexEmailSelect, 1)

                setUsers(s => ({ ...s, allEmail: list }));
            }

        }

        setList();
        handleResize();
    }, [users.emailSelect])

    if (checkActiveUser) {
        return (
            <div className="my-user-card-background" style={{ height: `${users.allEmail.length * 10}vh` }}>
                <div style={{ position: 'relative', padding: '2rem', margin: 'auto', }}>
                    {
                        props.user?.admin && <DropDown
                            list={users.allEmail}
                            select={users.emailSelect}
                            setEmailSelect={setUsers}
                        />
                    }
                </div>

                <div className='personal-info-container'>
                    <div className='personal-info-navbar-wrapper'>
                        <div>
                            <FaEdit className='edit-icon' onClick={() => setEditMenuItem(true)} />
                            {info.menu.length > 3 && slice.start !== 0 ?
                                <BiLeftArrow className='arrow-icon left' onClick={() => setSlice(s => ({ ...s, start: slice.start - 1, end: slice.end - 1 }))} />
                                :
                                null
                            }
                            {
                                slice.menu.map((item, index) => {
                                    return (
                                        <span className={slice.select === index + slice.start ? 'active' : ''} key={index} onClick={() => setSlice(s => ({ ...s, select: (index + slice.start), tabName: item.name }))} /* onClick={() => setActive(s => ({ ...s, 'id': index, "tabName": item.name }))} */>{item.name}</span>
                                    )
                                })
                            }
                            {
                                info.menu.length > maxElement && slice.end !== info.menu.length ?
                                    <BiRightArrow className='arrow-icon right' onClick={() => setSlice(s => ({ ...s, start: slice.start + 1, end: slice.end + 1 }))} />
                                    :
                                    null
                            }
                            <FaPlusCircle className='add-icon' onClick={() => setAddMenuItem(true)} />
                        </div>
                    </div>

                    <div className='personal-info-wrapper'>

                        {
                            slice.select === 0 ?
                                <TabInfoDescription
                                    info={info}
                                />
                                :
                                <TabInfoCustom
                                    id={slice.select}
                                    tabName={slice.tabName}
                                    info={info}
                                />
                        }
                    </div>

                    <AddMenuTab
                        showModal={addMenuItem}
                        setShowModal={setAddMenuItem}
                        setAlert={setAlert}
                        email={info.email}
                        index={next_index}
                    />

                    <EditMenuTab
                        showModal={editMenuItem}
                        setShowModal={setEditMenuItem}
                        setAlert={setAlert}
                        info={info}
                    />


                </div>

                <AlertFade
                    showAlert={alert.active}
                    onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
                    message={alert.message}
                    type={alert.type}
                    time={alert.time}
                />
            </div>

        )

    }
    else if (checkActiveUser === false) {
        sessionStorage.setItem('isMan', false);
        return <Redirect to='/login' />
    }
    else {
        return <div className='loader' />
    }

}
const PersonalInfo = MotionHoC(PersonalInfoComponent);

export default PersonalInfo
