import { FaProjectDiagram } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { RiTeamFill } from "react-icons/ri";

export const NavbarItem = [
    {
        title: 'Home',
        path: '/',
        icon: <AiFillHome />,
    },
    {
        title: 'Team',
        path: '/team',
        icon: <RiTeamFill />,
    },
    {
        title: 'Projects',
        path: '/projects',
        icon: <FaProjectDiagram />,
    },
    /*  {
         title: 'News',
         path: '/news',
         icon: <FaIcons.FaNewspaper />,
         className: {styles.nav_item},
         classLinkName: styles.nav_links
     } */

];
