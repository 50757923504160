import '../../../App.css'
import React from 'react'
import Hero from './Hero'
import Feed from '../../Feed/Feed'
import { useEffect, useState, useRef } from 'react'
import styles from './Home.module.css'
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import ProfileLine from './ProfileLine'
import { motion } from 'framer-motion'
import HomeProjectCard from './HomeProjectCard'
import MotionHoC from '../../utilities/MotionHoC'


function HomeComponent() {
  const [mobile, setMobile] = useState(false);
  const [numberOfFeed, setNumberOfFeed] = useState();

  /*   useEffect(() => {
      if (projectCardRef.current)
        projectCardRef.current.style.width = (numberOfFeed > 0 ? '75%' : '75%');
      window.scrollTo(0, 0);
    }, [numberOfFeed]); */


  const showPageVariants = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, ease: 'easeInOut', delay: 1 }
    }
  }

  const mobileView = () => {
    if (window.innerWidth < 960) {
      setMobile(true);
    }
    else {
      setMobile(false);
    }
  }


  useEffect(() => {
    mobileView();
    window.scrollTo(0, 0);
  }, [])


  window.addEventListener('resize', mobileView);

  return (
    <>
      <Hero />
      {
        <motion.div
          initial={'hidden'}
          animate={'visible'}
          variants={showPageVariants}
        >
          <ProfileLine />

          {
            mobile ?
              <>
                <div className={styles.projectCardContainer} >
                  <HomeProjectCard />
                </div>

                <div className={styles.feedContainer} /* style={{ display: numberOfFeed > 0 ? 'block' : 'none' }} */>
                  <Feed
                    channel="islab"
                    channel_name='ISLAB news'
                    numberOfFeed={setNumberOfFeed}
                    options={{ height: 450, width: '95%' }} />
                </div>
                <div className={styles.twitterContainer} >
                  <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="IslabUnimi"
                    options={{ width: '95%', height: 450 }}
                  />
                </div>
              </>
              :
              <div className={styles.newsContainer}>
                <div className={styles.projectCardContainer} >
                  <HomeProjectCard />
                </div>
                <div className={styles.feedContainer} >
                  <Feed
                    channel="islab"
                    channel_name='ISLAB news'
                    numberOfFeed={setNumberOfFeed}
                    options={{ height: 450, width: '100%'}} />
                  <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="IslabUnimi"
                    options={{ width: '100%', height: 450 }}
                  />
                </div>
              </div>
          }

        </motion.div>
      }
    </>
  )
}

const Home = MotionHoC(HomeComponent);

export default Home;
