import { FaUser, FaProjectDiagram, FaUserCog } from 'react-icons/fa';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { RiHomeGearFill } from 'react-icons/ri'


export const SidebarItem = [

  {
    title: 'My User',
    path: '/manage/myuser',
    icon: <FaUser />,
    freeAccess: true
  },
  {
    title: 'Personal Info',
    path: '/manage/personal-info',
    icon: <FaUserCog />,
    freeAccess: true
  },
  {
    title: 'Manage Home',
    path: '/manage/manage-home',
    icon: <RiHomeGearFill />,

    freeAccess: false
  },
  {
    title: 'Manage Users',
    path: '/manage/manage-users',
    icon: <AiOutlineUserAdd />,
    freeAccess: false
  },
  {
    title: 'Manage Projects',
    path: '/manage/manage-projects',
    icon: <FaProjectDiagram />,
    freeAccess: false
  }
];
