import React, { useState, useEffect,useRef } from 'react'
import { Link } from 'react-router-dom'
import { RiExternalLinkFill } from 'react-icons/ri'
import styles from './ProjectsCard.module.css'

function ProjectsCard(props) {

    const [flip, setFlip] = useState(false)
    const titleRef = useRef()
    const subRef = useRef()
    const descriptionRef = useRef()
   
    const [heightCard, setHeightCard] = useState(0)

    useEffect(() => {
        setHeightCard(titleRef?.current?.clientHeight+subRef?.current?.clientHeight + descriptionRef?.current?.clientHeight)

    }, [descriptionRef,subRef])

    return (
        <div className={styles.card} onClick={() => setFlip(!flip)}>
            <div className={`${styles.card_inner} ${flip ? styles.is_flipped : ''}`} >
                <div className={`${styles.card_face} ${styles.card_face_front}`}>
                    <div className={flip ? styles.card_pic_wrap_flip: styles.card_pic_wrap }>
                        <img className={styles.card_pic} src={props.img_URL} alt={'0'} />
                    </div>
                    <div className={`${styles.card_title} ${styles.card_text}`}>
                        {props.title.length > 40 ? props.title.substring(0, 40) + '...' : props.title}
                    </div>
                    <div className={styles.card_subtitle}>
                        {props.subtitle.length > 45 ? props.subtitle.substring(0, 45) + '...' : props.subtitle}
                    </div>
                </div>

                <div className={`${styles.card_face} ${styles.card_face_back}`}>
                    <div ref={titleRef}>
                        {
                            props.html ?
                                props.link ?
                                    <div className={styles.card_link} onClick={() => window.open(props.link, "_blank")}>
                                        <RiExternalLinkFill style={{ margin: '10px' }} />
                                        {props.title}
                                    </div>
                                    :
                                    <div className={styles.card_no_link} >
                                        {props.title}
                                    </div>
                                :
                                <Link to={'/project/' + props.title} className={styles.card_link}>
                                    <RiExternalLinkFill style={{ margin: '10px' }} />
                                    {props.title}
                                </Link>
                        }
                    </div>
                    <div className={styles.card_content} style={{height:`${heightCard} px`}}>
                        <div ref={subRef} className={styles.card_content_sub}   dangerouslySetInnerHTML={{ __html: props.subtitle }} />
                        <div ref={descriptionRef} className={styles.card_content_desc} dangerouslySetInnerHTML={{ __html: props.description }}  />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectsCard
