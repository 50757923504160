import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom'
import MotionHoC from '../../utilities/MotionHoC';

function CustomPageComponent({ title }) {

    const [project, setProject] = useState();

    let projectTitle = useParams(title);

    //const SERVER = 'http://172.20.27.90'

    const iframeRef = useRef();

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (iframeRef.current)
                iframeRef.current.style.height = `${event.data + 70}px`
        });
        window.scrollTo(0, 0);

    }, [])


    useEffect(() => {
        fetch(`/projects/get-project?title=${projectTitle['title']}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(r => {                    //il primo then ritorna la promise relativa allo stato
                if (r.status === 200) {
                    return r.json();
                } else {
                    throw new Error("Error");
                }
            })
            .then((res) => {       //il secondo il risultato
                if (res.success === true) {
                    setProject(res.project)
                }
            })
            .catch((err) => {
                ;
            });

    }, [projectTitle]);

    return (
        <>
            {
                <div style={{ margin: '2rem auto', marginTop:'20px', width: "95%" }}>
                    <iframe title={'2'} ref={iframeRef} src={project?.file} style={{ width: '100%' }} />
                </div>

            }
        </>
    )
}

const CustomPage = MotionHoC(CustomPageComponent);

export default CustomPage;