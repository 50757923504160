import React from 'react'
import styles from './Slider.module.css'
import Carousel from 'react-multi-carousel';


function Slider(props) {

    const CustomLeftArrow = ({ onClick, ...rest }) => {
       
        // onMove means if dragging or swiping in progress.
        return <button className={`${styles.arrow} ${styles.arrowLeft}`} onClick={() => onClick()} />;
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        
        // onMove means if dragging or swiping in progress.
        return <button className={`${styles.arrow} ${styles.arrowRight}`} onClick={() => onClick()} />;
    };

    const CustomDot = ({ onClick, ...rest }) => {
        const {
            active,
        } = rest;

        return (
            <button
                className={active ? styles.dotActive : styles.dot}
                onClick={() => onClick()}
            />
        );
    };


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,

        },
        tablet: {
            breakpoint: { max: 960, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    return (

        <Carousel
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            responsive={responsive}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={30000}
            transitionDuration={500}
            ssr={true}
            showDots={true}
            className={styles.slide}
            itemClass={styles.sliderTextSection}
            customDot={<CustomDot />}
            dotListClass={styles.dotList}
        >

            {
                props.items.map((slideItem, index) =>
                    <div className={styles.answer} key={index}>
                        <div dangerouslySetInnerHTML={{__html: slideItem.answer}} />

                    </div>
                )
            }

        </Carousel >
    )

}

export default Slider
