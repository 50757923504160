
import React, { useState, useEffect } from 'react'
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import './TabInfo.css'
import { MdRemoveCircle, MdCancel, MdInsertLink, } from "react-icons/md";
import { FaCheck, FaSave } from 'react-icons/fa';
import AlertFade from '../../utilities/Alert';
import { InputText, regex } from '../../utilities/InputForm';
import { useForm } from 'react-hook-form';
import { Btn } from '../../utilities/Button';
import AddFileWithDrag from './AddFileWithDrag';
import { fetchGetFolder } from '../../utilities/utilityFetch';


function TabInfoCustom({ tabName, id, info }) {

    const { control, handleSubmit, reset } = useForm({ defaultValues: { 'link': '' }, mode: 'onChange', });

    const [files, setFiles] = useState([]);

    const [link, setLink] = useState('');

    const [selectFile, setSelectFile] = useState();

    const [alert, setAlert] = useState({
        active: false,
        message: '',
        type: '',
        time: true
    })

    useEffect(() => {
        var path = ''

        info.menu.map((item) => {
            if (item.name === tabName) {
                setLink(item.link);
                path = item.link;
            }
            return null;
        })

        if ((!regex['link'].test(path)) && path !== '') {
            fetchGetFolder(path).then((res) => {       //il secondo il risultato
                if (res.success === true) {
                    setFiles(() => {
                        var list = [];
                        res.files.map((item, index) =>
                            list.push({ 'id': index, 'file': item })
                        );
                        return list;
                    })
                }
                else {
                    setAlert(s => ({ ...s, active: true, message: res.message, type: 'wrong' }));
                }
            })
            /* fetch(`/manage/get-files?path=${path}`, {
                method: 'GET',
                credentials: "include"
            })
                .then(r => {                    //il primo then ritorna la promise relativa allo stato
                    if (r.status === 200) {
                        return r.json();
                    }
                    else if (r.status === 401) {
                        sessionStorage.setItem('isMan', false)
                        window.location.replace('/login')
                    } else {
                        setAlert(s => ({ ...s, active: true, message: r.message, type: 'wrong' }));
                        console.log(r)
                        throw new Error('Error');
                    }
                })
                .then((res) => {       //il secondo il risultato
                    if (res.success === true) {
                        setFiles(() => {
                            var list = [];
                            res.files.map((item, index) =>
                                list.push({ 'id': index, 'file': item })
                            );
                            return list;
                        })
                    }
                    else {
                        setAlert(s => ({ ...s, active: true, message: res.message, type: 'wrong' }));
                    }
                })
                .catch((err) => {
                    console.log(err)
                }); */
        }
        else {
            reset({ 'link': info.menu[id].link })
            setFiles([]);
        }
    }, [tabName, id, info.menu, reset])



    // IF TAB CONTAIN A LINK 

    if (regex['link'].test(link)) {

        const onSubmit = (data) => {
            var x = { 'email': info.email, 'id': id, 'tabName': tabName }
            var newData = { ...data, ...x, }

            fetch(`/manage/update-link`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newData)
            })
                .then(r => {                    //il primo then ritorna la promise relativa allo stato
                    if (r.status === 200) {
                        return r.json();
                    }
                    else {
                        window.alert(r.message)
                    }
                })
                .then((res) => {         //il secondo il risultato
                    if (res.success === true) {
                        setAlert(s => ({ ...s, active: true, message: res.message, type: 'success', time: true }));
                    }
                    else {
                        setAlert(s => ({ ...s, active: true, message: res.message, type: 'wrong', time: true }));
                    }
                })
                .catch((err) => {
                    ;
                    return false;
                });
        }

        return (
            <>
                <form className='form-wrapper' >
                    <InputText
                        name={'link'}
                        placeholder={'Link'}
                        control={control}
                        errorClass='error-message'
                        label={<MdInsertLink className='icon' />}
                        rules={{
                            require: `Tab Link is Required`,
                            pattern:
                            {
                                value: regex['link'],
                                message: `Insert valid format of Link`
                            }
                        }}
                    />
                    <div style={{ marginTop: '2rem' }}>
                        <Btn size={'btn--sm'} styles={'btn--save'} onClick={handleSubmit(onSubmit)}>
                            <FaSave /> Save
                        </Btn>
                    </div>
                </form>

                <AlertFade
                    showAlert={alert.active}
                    onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
                    message={alert.message}
                    type={alert.type}
                    time={alert.time}
                />
            </>
        )
    }

    // IF TAB CONTAIN A HTML FILE 

    else {
        const deleteClick = () => {
            fetch(`/manage/delete-file`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'fileName': selectFile, 'id': id, 'tabName': tabName, 'email': info.email })
            })
                .then(r => {                    //il primo then ritorna la promise relativa allo stato
                    if (r.status === 200) {
                        return r.json();
                    }
                    else {
                        setAlert(s => ({ ...s, active: true, message: r.message, type: 'wrong', time: true }));
                    }
                })
                .then((res) => {         //il secondo il risultato
                    if (res.success === true) {
                        window.location.reload();
                    }
                    else {
                        setAlert(s => ({ ...s, active: true, message: res.message, type: 'wrong', time: true }));
                    }
                })
                .catch((err) => {
                    ;
                });
        }


        const handleClick = (item) => {

            setAlert(s => ({
                ...s,
                active: true,
                type: 'warning',
                message: `Do you Want Delete ${item} ?`,
                time: false
            }));
            setSelectFile(item)
        }

        const handleRLDDChange = (newItems) => {
            setFiles(newItems)
        }

        return (
            <>
                <RLDD
                    items={files}
                    itemRenderer={(item) => {
                        return (
                            <div className="tab-info-files-container">
                                <div className="tab-info-grid triple">
                                    <li id={item}>
                                        <MdCancel className={'tab-icon'} onClick={() => handleClick(item.file)} />
                                    </li>
                                    <li className='text'>
                                        {item.file}
                                    </li>
                                </div>

                            </div>
                        );
                    }}
                    onChange={handleRLDDChange}
                />

                <AddFileWithDrag
                    id={id}
                    tabName={tabName}
                    email={info.email}
                    setAlert={setAlert}
                    html_exist={() => {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].file.includes('.html'))
                                return true
                        }
                        return false;
                    }}
                />

                <AlertFade
                    showAlert={alert.active}
                    onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
                    message={alert.message}
                    type={alert.type}
                    time={alert.time}
                >
                    {alert.type === 'warning' ?
                        <div>
                            <MdRemoveCircle className={'icon-btn'} color='var(--wrong)' style={{ left: '10%' }} onClick={() => setAlert(s => ({ ...s, active: false }))} />
                            <FaCheck className={'icon-btn'} color='var(--success)' style={{ right: '10%' }} onClick={deleteClick} />
                        </div>
                        :
                        null}
                </AlertFade>
            </>
        )
    }
}
export default TabInfoCustom