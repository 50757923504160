import React from 'react'
import { MdCancel } from 'react-icons/md';
import styles from './ProjectCard.module.css'

const ProjectCard = ({ project, setEditProject, setShowModal, moveMode, setAlert, setDelProject }) => {

    const handleClick = () => {
        setAlert(s => ({
            ...s,
            active: true,
            type: 'warning',
            message: `Do you Want Delete ${project.title} ?`,
            time: false
        }));
        setDelProject(project.title)
    }

    return (
        <>
            <div className={styles.card + ` ${moveMode ? styles.move : ''}`}>
                {!moveMode && <MdCancel className={styles.icon_btn} onClick={handleClick} />}
                <div onClick={() => { if (moveMode !== true) { setShowModal(prev => !prev); setEditProject(project) } }}>
                    <figure className={styles.pic_wrap}>
                        <img className={styles.pic} alt={'1'} src={project.img_URL} />
                    </figure>
                    <div className={styles.title} children={project.title} />
                </div>
            </div>

        </>

    )
}


export default ProjectCard
