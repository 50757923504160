import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import styles from './ProfileLine.module.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { fetchGetTeam } from '../../utilities/utilityFetch';

function ProfileLine() {
    const [users, setUsers] = useState([]);

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        return <button className={`${styles.arrow} ${styles.arrowLeft}`} onClick={() => onClick()} />;
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        return <button className={`${styles.arrow} ${styles.arrowRight}`} onClick={() => onClick()} />;
    };

    useEffect(() => {
        fetchGetTeam().then(r=>r.success && setUsers(r.users))
    }, []);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,

        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,

        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,

        }
    };

    return (
        <Carousel
            responsive={responsive}
            className={styles.slideContainer}
            itemClass={styles.itemsSlide}
            infinite={true}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
        >
            {users.map((item,index) => (
                <div className={styles.pic_wrap} key={index}>
                    <Link to={`/team/${item.email}`}>
                        <img className={styles.pic} src={item.img_URL} alt="" />
                    </Link>
                </div>
            ))}
        </Carousel>

    )
}
export default ProfileLine
