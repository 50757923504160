import React, { useState, useEffect, useRef } from 'react'
import { IoCalendar } from 'react-icons/io5';
import styles from './FeedCard.module.css';

function FeedCard(props) {

    const [flip, setFlip] = useState(false);
    const [overLink, setOverLink] = useState(false)
    const titleRef = useRef(null)
    const bodyRef = useRef(null)
    const linkRef = useRef(null)
    const [height, setHeight] = useState({
        front: 0,
        back : 0
    })





    function formatDate(string) {
        var options = { year: 'numeric', month: 'long', day: 'numeric', hours: 'double' };
        return new Date(string).toLocaleDateString([], options);
    }

    function formatFullDate(string) {
        var date = (formatDate(string) + ' ' + new Date(string).toLocaleTimeString('it-IT'))
        return date.substring(0, date.length - 3);
    }

    useEffect(() => {
        setHeight(s=>({...s, back:(titleRef.current?.clientHeight + bodyRef.current?.clientHeight + linkRef.current?.clientHeight + 50), front:titleRef.current?.clientHeight + 50} ))
    }, [titleRef, bodyRef, linkRef])



    return (
        <>
            <div className={`${flip ? styles.flip : ''} ${styles.container}`} style={flip?{height:height.back}:{height:height.front}} onClick={() => overLink ? '' : setFlip(!flip)}>
                <div  className={`${styles.card_face} ${styles.front}`} >
                    {flip ? '' : <>
                        <div  className={styles.title}>
                            {props.feed.title}
                        </div>
                        <div className={styles.date}>
                            <IoCalendar className={styles.icon} />
                            {formatDate(props.feed?.creation_date)}
                        </div>
                    </>}
                </div>
                <div className={`${styles.card_face} ${styles.back}`}>
                    <div ref={titleRef} className={styles.title}>
                        {props.feed.title}
                    </div>
                    <div ref={bodyRef} dangerouslySetInnerHTML={{ __html: props.feed.htmlbody }} />
                    <div ref={linkRef} className={styles.link} onMouseOver={() => setOverLink(true)} onMouseLeave={() => setOverLink(false)}>
                        {props.feed.attachments.map((item, index) =>
                            <div key={index} onClick={() => window.open(item, "_blank")}>
                                {item.substring(item.lastIndexOf('/') + 1, item.lastIndexOf('.'))}
                            </div>

                        )}
                    </div>

                    <div className={styles.date}>
                        <IoCalendar className={styles.icon} />
                        {formatFullDate(props.feed?.creation_date)}
                    </div>

                </div>
            </div>
        </>
    )
}

export default FeedCard
