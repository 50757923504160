import React from 'react';
import styles from './ManageHome.module.css'
import { useForm } from "react-hook-form"
import { TextArea } from '../../utilities/InputForm';
import { Btn } from '../../utilities/Button';
import { FaSave, } from 'react-icons/fa';
import { RiQuestionAnswerFill } from 'react-icons/ri'
import Modal, { ModalFooter } from '../../utilities/Modal';
import { fetchSetInfoHome, fetchGetInfoHome } from '../../utilities/utilityFetch';


function NewHome(props) {

    const defaultValues = {
        question: '',
        answer: '',
        id: 0,
        active: true
    }

    const { control, handleSubmit,reset } = useForm({
        defaultValues: defaultValues,
        mode: 'onChange'
    });


    const onSubmit = data => {
        data.id = props.position
        fetchSetInfoHome(data).then(r => r.success ?
            fetchGetInfoHome()
                .then(r1 => r1.success && props.setText(r1.text))
                .then(props.setShowModal(false))
                .then(() =>reset(defaultValues))
            : props.setAlert(s => ({ ...s, active: true, message: r.message, type: 'wrong' }))
        )
    }


    return (
        <Modal showModal={props.showModal} setShowModal={props.setShowModal} title={'Create new Home Slide'} manage={true}>
            <form className='form-wrapper' >
                {/* <InputText
                    name={'question'}
                    placeholder={"Question for your answer ( this field is optional) "}
                    control={control}
                    label={<RiQuestionFill className='icon' />}
                    className={styles.inputTextContainer}
                    inputClassName={styles.inputText}
                    rules={{
                        minLength: {
                            value: 3,
                            message: `question must exceed 2 characters`
                        },
                    }}
                /> */}
                <TextArea
                    name={'answer'}
                    placeholder={"Answer or Text "}
                    control={control}
                    label={<RiQuestionAnswerFill className='icon' />}
                    className={styles.textAreaContainer}
                    inputClassName={styles.textArea}
                    rules={{
                        minLength: {
                            value: 3,
                            message: `answer must exceed 2 characters`
                        },
                    }}
                />
            </form>
            <ModalFooter>
                <Btn size={'btn--sm'} styles={'btn--save'} onClick={handleSubmit(onSubmit)}>
                    <FaSave /> {'CREATE'}
                </Btn>
            </ModalFooter>

        </Modal >
    );
}




export default NewHome
