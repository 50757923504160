
import React from 'react'
import { useEffect, useState, useMemo } from 'react'
import UserCard from './UserCard'
import { MdRemoveCircle } from 'react-icons/md';
import { FaPlusCircle, FaCheck } from 'react-icons/fa'
import { IoMdMove } from 'react-icons/io'

import Modal from '../../utilities/Modal';
import MyUserCard from '../MyUser/MyUserCard'
import AlertFade from '../../utilities/Alert'
import AddUser from './AddUser';
import { Redirect } from 'react-router';
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";
import { useSpring, animated } from 'react-spring'
import styles from './ManageUser.module.css'
import { fetchCheckActiveUser, fetchDeleteUser, fetchGetAllUser, fetchUpdateUser } from '../../utilities/utilityFetch';
import { Switch } from '../../utilities/Button';

function ManageUser(props) {

  const [checkActiveUser, setCheckActiveUser] = useState()
  useMemo(() => setCheckActiveUser(fetchCheckActiveUser()), [])

  const [numberElement, setNumberElement] = useState(1);
  const [moveMode, setMoveMode] = useState();
  const [users, setUsers] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [editEmail, setEditEmail] = useState('')
  const [addUser, setAddUser] = useState(false);
  const [delUser, setDelUser] = useState();
  const [alert, setAlert] = useState({
    active: false,
    message: '',
    type: '',
  });

  const animate = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });

  /* LONG PRESS */
  /*  const callback = useCallback(() => {
     setMoveMode(prev => {
       setAlert(s => ({ ...s, active: true, message: `${'Move Mode ' + !prev}`, type: 'success' }))
       return !prev;
     });
   }, []);
 
   const bind = useLongPress(addUser || showModal ? '' : callback,
     moveMode ?
       {
         threshold: 3000,
         captureEvent: true,
         detect: LongPressDetectEvents.BOTH
       }
       :
       {
         threshold: 1000,
         captureEvent: true,
         detect: LongPressDetectEvents.BOTH
       }
   ); */

  useEffect(() => {
    fetchGetAllUser().then(r => r.success && setUsers(r.users))
  }, []);

  const numberOfElement = () => {
    if (window.innerWidth > 1200)
      setNumberElement(3);
    else if (window.innerWidth > 600)
      setNumberElement(2);
    else
      setNumberElement(1);
  }

  useEffect(() => {
    numberOfElement()
  }, [])

  window.addEventListener('resize', numberOfElement);

  useEffect(() => {
    moveMode !== undefined && setAlert(s => ({ ...s, active: true, message: `${'Move Mode ' + moveMode}`, type: 'success' }))
    window.scrollTo(0, 0);
  }, [moveMode])

  useEffect(() => {
    users.map((item, index) =>
      item.id = index);

    var data = { 'list': users }

    fetchUpdateUser(data)
  }, [users])


  const deleteClick = () => {
    fetchDeleteUser(delUser)
      .then(r => {
        if (r.success) {
          setAlert(s => ({ ...s, active: false, }))
          fetchGetAllUser()
            .then(res => res.success && setUsers(res.users))

        } else {
          setAlert(s => ({ ...s, active: true, message: r.message, type: 'wrong', time: true }))
        }
      })
  }


  if (users.length === 0) {
    return (
      <div className="my-user-card-background">
        <div className='loader' />
      </div>
    )
  }

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(users, sourceIndex, targetIndex);
    setUsers(nextState);
  }
  if (checkActiveUser) {
    return (
      <>
        <span style={{ marginTop: '40px', marginLeft: '80%', position: 'absolute' }}>
          <Switch label={<IoMdMove color='var(--main)' size={30} />} value={moveMode} setValue={setMoveMode} />
        </span>
        <animated.div style={animate} >
          <FaPlusCircle className={styles.add_icon} onClick={() => setAddUser(true)} />
          <div /* {...bind} */ className={styles.container} style={moveMode ? { cursor: 'grab' } : { cursor: 'default' }}>
            <div className={styles.dropzone_container} >
              {
                <GridContextProvider onChange={onChange}>
                  <GridDropZone
                    className={styles.dropzone}
                    id="left"
                    boxesPerRow={numberElement}
                    rowHeight={350}
                    disableDrag={!moveMode}
                    disableDrop={!moveMode}
                    style={{ height: `${users.length/numberElement*400}px`, Overflow :'auto' }}
                  >

                    {
                      users.map(item => (
                        <GridItem key={item.name} style={moveMode ? { cursor: 'grab' } : { cursor: 'default' }}>
                          <UserCard
                            email={item.email}
                            name={item.name}
                            admin={item.admin}
                            image={item.img_URL}
                            setShowModal={setShowModal}
                            setEditEmail={setEditEmail}
                            moveMode={moveMode}
                            setAlert={setAlert}
                            setDelUser={setDelUser}
                          />
                        </GridItem>
                      ))
                    }

                  </GridDropZone>

                </GridContextProvider>
              }

            </div>


            <Modal showModal={showModal} setShowModal={setShowModal} title={''} manage={true}>
              <MyUserCard
                manageUsers={true}
                editEmail={editEmail}
                user={props.user} />
            </Modal>



            <AddUser
              position={users.length}
              showModal={addUser}
              setShowModal={setAddUser}
              setAlert={setAlert}
              setUsers={setUsers}
            />

            <AlertFade
              showAlert={alert.active}
              onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
              message={alert.message}
              type={alert.type}
              time={alert.time}
            >
              {alert.type === 'warning' ?
                <div>
                  <MdRemoveCircle className={'icon-btn'} color='var(--wrong)' style={{ left: '10%' }} onClick={() => setAlert(s => ({ ...s, active: false }))} />
                  <FaCheck className={'icon-btn'} color='var(--success)' style={{ right: '10%' }} onClick={deleteClick} />
                </div>
                :
                null}
            </AlertFade>
          </div>
        </animated.div >
      </>
    )
  }

  else if (checkActiveUser === false) {
    sessionStorage.setItem('isMan', false);
    return <Redirect to='/login' />
  }
  else {
    return <div className='loader' />
  }
}



export default ManageUser;
