import React from 'react'
import { useController } from "react-hook-form"
import { MdCheckCircle, MdCancel } from 'react-icons/md'
import './InputForm.css'

export const regex = {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
    phone: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    link: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
}


export function InputTextTab(props) {

    const { field, fieldState } = useController(props);

    return (
        <>

            <label>{props.label}</label>
            <input
                {...field}
                placeholder={props.placeholder}
                type={props.type}
            />
            {fieldState.isDirty ? fieldState.invalid ? <MdCancel className='icon wrong' /> : <MdCheckCircle className='icon correct' /> : null}


            <span className='input-error'>
                {fieldState.error && fieldState.error.message}
                {/* {fieldState.isTouched && "Touched"}
                {fieldState.isDirty} */}
            </span>
        </>
    );
}

export const InputText = (props) => {

    const { field, fieldState } = useController(props);

    return (
        <>
            <div className={props.className ? props.className : 'input-field'}>
                <label>{props.label}</label>
                <input
                    {...field}
                    placeholder={props.placeholder}
                    type={props.type}
                    disabled={props.disabled}
                    className={props.inputClassName}
                />
                {fieldState.isDirty ? fieldState.invalid ? <MdCancel className='icon wrong' /> : <MdCheckCircle className='icon correct' /> : null}
            </div>

            <span className={props.errorClass ? props.errorClass : 'input-error'}>
                {fieldState.error && fieldState.error.message}
                {/* {fieldState.isTouched && "Touched"}
                {fieldState.isDirty} */}
            </span>
        </>
    );
}

export const Switch = (props) => {
    const { field } = useController(props);

    return (
        <>
            <div className='input-switch onoffswitch'>
                <span style={{margin: '0px -5px  0 20px'}}>{props.label}</span>
                <input
                    {...field}
                    checked={field.value}
                    type={props.type}
                    className="onoffswitch-checkbox"
                    tabIndex="0"
                    id={field.name}
                />

                <label className="onoffswitch-label" htmlFor={field.name} >
                    <span className="onoffswitch-inner" content_t={props.content_t} content_f={props.content_f}></span>
                    <span className="onoffswitch-switch"></span>
                </label>
            </div>

        </>
    );
}

export const TextArea = (props) => {
    const { field } = useController(props);

    return (
        <>
            <div className={props.className ? props.className : 'input-field no-border'}>
                <label>{props.label}</label>
                <textarea
                    {...field}
                    className={props.inputClassName}
                    placeholder={props.placeholder}
                />
            </div>

        </>
    );
}


