import './App.css';
import "uikit/dist/css/uikit.min.css";
import { useCookies } from 'react-cookie';
import React, { useState } from 'react'
import Navbar from './components/Navbar/Navbar';
import { Switch, Route, useLocation } from "react-router-dom"
import Home from './components/pages/Home/Home'
import Login from './components/pages/Login/Login';
import ManageUser from './components/pages/ManageUser/ManageUser';
import MyUser from './components/pages/MyUser/MyUser';
import Team from './components/pages/Team/Team'
import Footer from './components/Footer/Footer';
import Projects from './components/pages/Projects/Projects';
import Sidebar from './components/Sidebar/Sidebar';
import ManageProjects from './components/pages/ManageProjects/ManageProjects';
import TeamUser from './components/pages/Team/TeamUser';
import PersonalInfo from './components/pages/PersonalInfo/PersonalInfo';
import CustomPage from './components/pages/Projects/CustomPage';
import ManageHome from './components/pages/ManageHome/ManageHome';
import Privacy from './components/utilities/Privacy';
import { AnimatePresence } from 'framer-motion';
import NotFoundPage from './NotFoundPage';

function App() {

  const [cookies, setCookie] = useCookies(['cookiePolicy']);
  const location = useLocation();
  const [user, setUser] = useState(null);


  return (
    <>
      <Navbar  setCookie={setCookie} setUser={setUser} user={user} />
      {sessionStorage.getItem('isMan') === 'true' ? <Sidebar user={user} /> : ''}
      <AnimatePresence exitBeforeEnter initial={false} >
        <Switch location={location} key={location.pathname}>
          <Route path='/' exact component={Home} />

          <Route exact path='/login' >
            <Login setCookie={setCookie} setUser={setUser} />
          </Route>

          <Route exact path='/manage/myuser'>
            <MyUser user={user} />
          </Route>

          <Route path='/manage/personal-info'>
            <PersonalInfo user={user} />
          </Route>

          <Route path='/manage/manage-users' >
            <ManageUser user={user} />
          </Route>

          <Route path='/manage/manage-projects'>
            <ManageProjects user={user} />
          </Route>

          <Route path='/manage/manage-home' >
            <ManageHome user={user} />
          </Route>

          <Route exact path='/team' >
            <Team />
          </Route>

          <Route exact path='/team/:email' component={TeamUser} />

          <Route path='/projects' >
            <Projects />
          </Route>

          <Route path='/project/:title' exact component={CustomPage} />
          <Route path="*" exact component={NotFoundPage} />

        </Switch>
      </AnimatePresence>
      <Privacy cookies={cookies.cookiePolicy} setCookie={setCookie} />
      <Footer />

    </>
  );
}

export default App;
