import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './NotFound.module.css'
import { useHistory } from 'react-router-dom';




function NotFoundPage() {
  const history = useHistory()

  sessionStorage.setItem('isMan', false);
  const ref = useRef(0)
  const [width, setWidth] = useState(0);
  

  useEffect(() => {
    history.push('/404.html');
    setWidth(ref.current.clientWidth);
  }, []);

  const resize =()=>{
    setWidth(ref.current.clientWidth);
  }

  window.addEventListener('resize', resize);

  return (
    <>
      <div className={styles.container} ref={ref}>
        <div  className={styles.image_404}  style={{marginLeft:width/3}}>
          <Link  to="/" ><img  src='/images/404.png' alt='src'/> </Link>
        </div>
      </div>
    </>
  );


}
export default NotFoundPage;