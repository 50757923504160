import React, { useState, useEffect } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { FiExternalLink } from 'react-icons/fi'
import styles from './TeamUser.module.css'
import TeamUserInfo from './TeamUserInfo'
import TeamUserTabInfo from './TeamUserTabInfo'
import { regex } from '../../utilities/InputForm'
import Carousel from 'react-multi-carousel';
import MotionHoC from '../../utilities/MotionHoC'


function TeamUserComponent({ email }) {

    let emailParamas = useParams(email)

    if (emailParamas) {
        email = emailParamas['email']
    }

    const [user, setUser] = useState({
        name: '',
        channel: '',
        role: '',
        email: '',
        address: '',
        phone: '',
        img_URL: '',
        description: '',
    })

    const [info, setInfo] = useState({
        email: '',
        menu: [{
            id: 0,
            name: '',
            link: ''
        }]
    })

    const [active, setActive] = useState({
        id: 0,
        tabName: 'Description'
    });

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1500 },
            items: 3,

        },
        mDesktop: {
            breakpoint: { max: 1500, min: 960 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 960, min: 600 },
            items: 2,

        },
        mobile: {
            breakpoint: { max: 600, min: 0 },
            items: 1,

        }
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        return <button className={`${styles.arrow} ${styles.arrowLeft}`} onClick={() => onClick()} />;
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        return <button className={`${styles.arrow} ${styles.arrowRight}`} onClick={() => onClick()} />;
    };


    useEffect(() => {
        fetch(`/team/get-team-user?email=${email}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(r => {                    //il primo then ritorna la promise relativa allo stato
                if (r.status === 200) {
                    return r.json();
                } else {
                    throw new Error("Error");
                }
            })
            .then((res) => {       //il secondo il risultato
                if (res.success === true) {
                    setUser(s => ({
                        ...s,
                        name: res.user.name,
                        channel: res.user.channel,
                        role: res.user.role,
                        email: res.user.email,
                        address: res.user.address,
                        phone: res.user.phone,
                        img_URL: res.user.img_URL,
                        description: res.user.description
                    }));
                    setInfo(s => ({ ...s, email: res.user.email, menu: res.info.menu }));
                }
                else {
                    window.alert(res.message)
                }
            })
            .catch((err) => {
            });
        window.scrollTo(0, 0);

    }, [email])


    return (
        <div className={styles.container}>
            <div className={styles.name} children={user.name} />

            <Carousel
                responsive={responsive}
                containerClass={styles.nav_wrapper}
                itemClass={styles.nav_items}
                removeArrowOnDeviceType={["tablet"]}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
            >
                {
                    info.menu.map((item, index) =>
                        <span
                            className={active.id === index ? styles.item_active : styles.item}
                            key={index}
                            onClick={() => setActive(s => ({ ...s, id: index, tabName: item.name }))}>
                            {item.name + '\t'}
                            {regex['link'].test(item.link) ? <FiExternalLink /> : null}
                        </span>
                    )
                }

            </Carousel>


            <div className={styles.wrapper}>

                <TeamUserInfo
                    name={user.name}
                    role={user.role}
                    email={email}
                    address={user.address}
                    phone={user.phone}
                    img_URL={user.img_URL}
                    ariel_site={info.menu[0].ariel}
                    cv={info.menu[0].ariel}
                />

                <div >
                    <TeamUserTabInfo
                        name={user.name}
                        channel={user.channel}
                        active={active}
                        description={user.description}
                        info={info}
                    />
                </div>
            </div>
        </div >
    )
}

const TeamUser = MotionHoC(TeamUserComponent)

export default withRouter(TeamUser)

