import React, { useState, useEffect, useRef } from 'react'
import Feed from '../../Feed/Feed';
import { regex } from '../../utilities/InputForm'
import styles from './TeamUser.module.css'

function TeamUserTabInfo({ active, info, description, name, channel }) {

    const [numberOfFeed, setNumberOfFeed] = useState(0);
    const iframeRef = useRef();
    const descriptionRef = useRef(null);
    const [mobile, setMobile] = useState(false);


    useEffect(() => {

        window.addEventListener("message", (event) => {
            if (iframeRef.current)
                iframeRef.current.style.height = `${event.data + 70}px`
        });

        setMobile(window.innerWidth <960) 

    }, [active])



    if (active.tabName === 'Description') {
        return (
            <div className={styles.grid} style={numberOfFeed > 0 ? {} : { gridTemplateColumns: 'repeat(1, auto)' }}>
                <div className={styles.description_container}>
                    <Feed
                        channel={channel}
                        options={numberOfFeed > 0 ? { height: descriptionRef.current?.clientHeight > 450 && mobile ? descriptionRef.current?.clientHeight : 450} : { display: 'none' }}
                        numberOfFeed={setNumberOfFeed}
                    />
                </div>

                <div ref={descriptionRef} className={styles.description_container}>
                    <div className={styles.user_title}>Description</div>
                    <div className={styles.description}>
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </div>
            </div >
        );
    }

    else {
        if (regex['link'].test(info.menu[active.id].link)) {
            window.open(info.menu[active.id].link, "_blank");
            return (
                <></>
            )
        }

        else {
            if (info.menu[active.id].link) {
                return (
                    <div className={styles.user_title}>
                        {active.tabName}
                        <div className={styles.description_container}>
                            <iframe className={styles.description} id={active.tabName} title={active.tabName} ref={iframeRef} src={info.menu[active.id].link} style={{ width: '90%' }} />
                        </div>
                    </div >
                )
            }
            else
                return null
        }
    }

}

export default TeamUserTabInfo
