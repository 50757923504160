
import React, { useEffect, useState } from 'react'
import { FaPlusCircle, FaSave } from 'react-icons/fa';
import DragDrop, { UploadUserFiles } from '../../utilities/DragDrop';
import { Btn } from '../../utilities/Button';
import styles from './AddFileWithDrag.module.css'


function AddFileWithDrag(props) {
    const [showDragDrop, setShowDragDrop] = useState(false)

    const [newFiles, setNewFiles] = useState();
    const [correctSave, setCorrectSave] = useState(true)

    const upload = () => {
        if (UploadUserFiles(newFiles, props.email, props.tabName, props.id))
            window.location.reload();
        else
            props.setAlert(s => ({ ...s, active: true, message: 'Please insert File HTML or CSS', type: 'wrong' }));
    }

    useEffect(() => {
        if (props.html_exist()) {
            setCorrectSave(true);
            for (var i = 0; i < newFiles?.length; i++) {
                if (newFiles[i].type === 'text/html') {
                    props.setAlert(s => ({ ...s, active: true, message: 'The HTML file already exists, before uploading a new file you need to remove the old one', type: 'wrong' }));
                    setCorrectSave(false);
                }
            }
        }
    }, [newFiles])


    if (showDragDrop) {
        return (
            <>
                <FaPlusCircle className={styles.close} onClick={() => setShowDragDrop(false)} />
                <DragDrop setFiles={setNewFiles} />

                {
                    newFiles && correctSave ?
                        <Btn size={'btn--sm'} styles={'btn--save'} onClick={upload}>
                            <FaSave /> Save
                        </Btn>
                        :
                        null
                }
            </>
        )
    }
    return (
        <>
            <FaPlusCircle className={styles.addIcon} onClick={() => setShowDragDrop(true)} />
        </>
    )
}

export default AddFileWithDrag
