import React from 'react'
import { useForm, useWatch } from "react-hook-form"
import { pswForm } from '../Cards/MyUserCardItem';
import { InputText, regex } from './InputForm';
import { FaSave } from 'react-icons/fa'
import Modal, { ModalFooter } from '../utilities/Modal';
import { Btn } from './Button';


function ChangePassword({ showModal, setShowModal, setAlert, email, admin }) {

    const { control, handleSubmit } = useForm({
        defaultValues: {
            oldPsw: '',
            newPsw: '',
            confirmPsw: '',
        },
        mode: 'onChange'
    });

    const onSubmit = data => {
        var x = { 'email': email }
        var res = { ...data, ...x }

        fetch(`/manage/update-psw`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(res)
        })
            .then(r => {                    //il primo then ritorna la promise relativa allo stato
                if (r.status === 200) {
                    return r.json();
                } else {
                    setAlert(s => ({ ...s, active: true, message: 'Error', type: 'wrong' }));
                    throw new Error('Error');
                }
            })
            .then((res) => {       //il secondo il risultato
                if (res.success === true) {
                    window.location.reload();
                }
                else {
                    setAlert(s => ({ ...s, active: true, message: res.message, type: 'wrong' }));
                }
            })
            .catch((err) => {
                ;
            });
    };


    const checkPsw = useWatch({
        control,
        name: 'newPsw'
    })

    return (
        <>
            <Modal showModal={showModal} setShowModal={setShowModal} title={'Change Password'} manage={true}>
                <form className='form-wrapper' >
                    {
                        pswForm.map((item, index) => {

                            if (item.name === 'oldPsw' && admin)
                                return null;
                            else {
                                return (
                                    <InputText
                                        key={index}
                                        name={item.name}
                                        placeholder={item.placeholder}
                                        type={item.type}
                                        control={control}
                                        label={item.label}
                                        rules={{
                                            required: `${item.placeholder} is required.`,
                                            pattern: {
                                                value: regex['password'],
                                                message: `${item.placeholder} must be contain number, upper case and lower case letter`
                                            },
                                            minLength: {
                                                value: 8,
                                                message: `${item.placeholder} must exceed 7 characters`
                                            },
                                            validate: {
                                                equals: v => item.name === 'confirmPsw' ? v === checkPsw || 'Passwords are different' : null,
                                                checkPswApi: async (x) => item.name === 'oldPsw' ?
                                                    await fetch(`/manage/check-psw`, {
                                                        method: 'POST',
                                                        headers: {
                                                            'Accept': 'application/json',
                                                            'Content-Type': 'application/json'
                                                        },
                                                        body: JSON.stringify({ 'email': email, 'oldPsw': x })
                                                    })
                                                        .then(r => {                    //il primo then ritorna la promise relativa allo stato
                                                            if (r.status === 200) {
                                                                return r.json();
                                                            } else {
                                                                setAlert(s => ({ ...s, active: true, message: 'Error', type: 'wrong' }));
                                                                throw new Error('Error');
                                                            }
                                                        })
                                                        .then((res) => {       //il secondo il risultato
                                                            return res.success
                                                        }).catch((err) => {
                                                            ;
                                                        }) || 'Old Password not correct'
                                                    : null
                                            }
                                        }}
                                    />
                                )
                            }
                        })
                    }
                </form>

                <ModalFooter>
                    <Btn size={'btn--sm'} styles={'btn--save'} onClick={handleSubmit(onSubmit)} >
                        <FaSave /> Change
                        </Btn>
                </ModalFooter>
            </Modal >
        </>
    );
}

export default ChangePassword
