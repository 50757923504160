import React, { useMemo, useState } from 'react'
import { Redirect } from 'react-router';
import MotionHoC from '../../utilities/MotionHoC';
import { fetchCheckActiveUser } from '../../utilities/utilityFetch';
import MyUserCard from './MyUserCard';

function MyUserComponent(props) {
  const [checkActiveUser, setCheckActiveUser] = useState()
  useMemo(() => fetchCheckActiveUser().then(res => setCheckActiveUser(res)), [])

  if (checkActiveUser) {
    return <MyUserCard manageUsers={false} user={props.user} />
  }
  else if (checkActiveUser === false) {
    sessionStorage.setItem('isMan', false);
    return <Redirect to='/login' />
  }
  else {
    return <div className='loader' />
  }

}

const MyUser = MotionHoC(MyUserComponent)

export default MyUser
