import React, { useState, useEffect } from 'react'
import './TabInfo.css'
import { useForm } from 'react-hook-form';
import { MdInsertLink } from "react-icons/md";
import { regex, InputText } from '../../utilities/InputForm';
import AlertFade from '../../utilities/Alert';
import { Btn } from '../../utilities/Button';
import { FaSave } from 'react-icons/fa';

function TabInfoDescription({ info }) {

    const { control, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            ariel: '',
        }
    });

    const [alert, setAlert] = useState({
        active: false,
        message: '',
        type: '',
        time: true
    });

    useEffect(() => {
        reset({ ariel: info.menu[0]?.ariel });
    }, [info, reset])


    const onSubmit = (data) => {

        var x = { 'email': info.email, 'id': 0, 'tabName': 'Description' }
        var newData = { ...data, ...x, }

        fetch(`/manage/update-link`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(r => {                    //il primo then ritorna la promise relativa allo stato
                if (r.status === 200) {
                    return r.json();
                }
                else {
                    window.alert(r.message)
                    return false;
                }
            })
            .then((res) => {         //il secondo il risultato
                if (res.success === true) {
                    window.location.reload()
                }
                else {
                    window.alert(res.message)
                }
            })
            .catch((err) => {
                ;
                return false;
            });
    }


    return (
        <>
            <div className='form-wrapper' >
                <InputText
                    name={'ariel'}
                    placeholder={'Ariel Link'}
                    control={control}
                    errorClass='error-message'
                    label={<MdInsertLink className='icon' />}
                    rules={{
                        required: "Ariel Link is Required",
                        pattern:
                        {
                            value: regex['link'],
                            message: `Insert valid format of Link`
                        }
                    }}
                />

                <div style={{ float: 'right', marginTop: '1rem' }}>
                    <Btn size={'btn--sm'} styles={'btn--save'} onClick={handleSubmit(onSubmit)}>
                        <FaSave /> Save
                    </Btn>
                </div>
               {/*  <div style={{ marginTop: '2rem', }}>
                    <span className='icon'>
                        CV
                        <AddFileWithDrag
                            id={0}
                            tabName={'Description'}
                            email={info.email}
                            setAlert={setAlert}
                            option={'no-margin'}
                        />
                    </span>
                </div> */}
            </div>




            <AlertFade
                showAlert={alert.active}
                onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
                message={alert.message}
                type={alert.type}
                time={alert.time}
            />

        </>
    );
}


export default TabInfoDescription

