import React from 'react'
import styles from './Footer.module.css'
import { FaArrowUp, FaPhone } from 'react-icons/fa'
import { CgWebsite } from 'react-icons/cg'
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

function Footer({ manage }) {

    const [ref, inView] = useInView({
        /* Optional options */
        threshold: 0.5,
        triggerOnce: false
    });


    const variants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.3, ease: 'easeInOut' }
        },
        hidden: {
            opacity: 0,
            y: 100,
            transition: { duration: 0.3, ease: 'easeInOut' }
        }
    };

    const smoothScroll = (h) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <>
            {sessionStorage.getItem('isMan') !== 'true' && <motion.div ref={ref}
                initial={'hidden'}
                animate={inView ? 'visible' : 'hidden'}
                variants={variants}
                transition={{ duration: 2, ease: 'easeOut' }}
                className={styles.footerContainer}>
                <FaArrowUp className={styles.arrowIcon} onClick={() => smoothScroll()} />
                <div className={styles.footerWrapper}>
                    <div>
                        <Link to='/' onClick={() => sessionStorage.setItem('isMan', false)}>
                            <img className={styles.logo} src="/images/islab_logo.png" alt="1" />
                        </Link>
                    </div>

                    <div>
                        <FaPhone /> {'\t'}
                            +39 0250316354
                            <div className={styles.item}>
                            <CgWebsite fontSize='20px' /> {'\t'}
                            <span className={styles.link} onClick={() => window.open("https://islab.di.unimi.it", "_blank")}>
                                https://islab.di.unimi.it
                                </span>
                        </div>
                    </div>

                    <div>
                        Via Celoria, 18
                            <div className={styles.item}>
                            20133 - Milano
                            </div>
                    </div>
                    <Maps />
                </div>
            </motion.div>
            }
        </>
    );

}

export default Footer


export function Maps() {
    return (
        <>

            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1257.0330864731354!2d9.232155295793802!3d45.475592322725944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6f5eb22b387%3A0x7d2bcc158bfd9411!2sVia%20Giovanni%20Celoria%2C%2018%2C%2020133%20Milano%20MI!5e1!3m2!1sit!2sit!4v1618236217611!5m2!1sit!2sit"
                title='maps'
                style={{
                    border: 'none',
                    allowfullscreen: '',
                    loading: "lazy"
                }}
                className={styles.maps}
            >
            </iframe>
        </>
    );
}
