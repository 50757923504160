import { motion } from 'framer-motion'
import React from 'react'
import styles from './Privacy.module.css'

const privacyVariant = {
    hidden: {
        y: 500,
        opacity: 0,
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            delay: 2,
            duration: 1,
            ease: 'easeInOut'
        }
    }
}

function Privacy({ cookies, setCookie }) {



    return (
        <motion.div
            initial='hidden'
            animate={cookies ? 'hidden' : 'visible'}
            exit='hidden'
            variants={privacyVariant}
            className={styles.container}
        >
            <div className={styles.wrapper}>
                <div style={{ fontSize: 'var(--font-subtitle)' }}>
                    This site doesn't make use of profiling cookies.
                </div>
                <div style={{ fontSize: '16px' }}>
                    Third-party cookies are used for access monitoring and map exploration.
                    If you decide to continue browsing this website, you accept to use cookies.
                    To learn more about cookies and read how to disable them, exploit the <a href='https://contents.islab.di.unimi.it/privacy_info/informativa.en.html' target="_blank" rel="noreferrer" >extensive information on privacy and use of cookies.</a>
                </div>

                <button
                    className={styles.btn}
                    onClick={() => setCookie('cookiePolicy', true)}>
                    I agree
                </button>
            </div>

        </motion.div>
    )
}

export default Privacy

