import React, { useEffect } from 'react'
import Modal, { ModalFooter } from '../../utilities/Modal';
import { newUserForm } from '../../Cards/MyUserCardItem'
import { useForm } from "react-hook-form"
import { InputText, regex, Switch } from '../../utilities/InputForm';
import { Btn } from '../../utilities/Button';
import { FaSave } from 'react-icons/fa'
import { fetchGetAllUser, fetchSetUser } from '../../utilities/utilityFetch';


function AddUser({ showModal, setShowModal, setAlert, setUsers, position }) {

    const defaultValues = {
        name: '',
        email: '',
        password: '',
        admin: false,
        id: 0,
        active: true
    }


    const { control, handleSubmit, reset } = useForm({
        defaultValues: defaultValues,
        mode: 'onChange'
    });

    useEffect(() => {
    }, [showModal])

    const onSubmit = data => {
        position ?
            data.id = position
            :
            data.id = 0;


        fetchSetUser(data).then(r => r.success ?
            fetchGetAllUser()
                .then(r1 => r1.success && setUsers(r1.users))
                .then(() => setShowModal(false))
                .then(reset(defaultValues))
            : setAlert(s => ({ ...s, active: true, message: r.message, type: 'wrong' }))
        )
    }

    return (
        <Modal showModal={showModal} setShowModal={setShowModal} title={'Create User'} manage={true}>
            <form className='form-wrapper' >
                {
                    newUserForm.map((item, index) => {
                        if (item.name === 'admin') {
                            return (

                                <Switch
                                    key={index}
                                    name={item.name}
                                    type={item.type}
                                    control={control}
                                    label={item.icon}
                                    content_t={'ADMIN'}
                                    content_f={'USER'}
                                />

                            );
                        }
                        else {
                            return (
                                <InputText
                                    key={index}
                                    name={item.name}
                                    placeholder={item.placeholder}
                                    type={item.type}
                                    control={control}
                                    label={item.icon}
                                    disabled={item.disabled}
                                    rules={{
                                        required: item.required ? `${item.placeholder} is required.` : false,
                                        pattern: (item.name === 'password') ?
                                            {
                                                value: regex[item.name],
                                                message: `${item.placeholder} must be contain number, upper case and lower case letter`
                                            }
                                            :
                                            {
                                                value: regex[item.name],
                                                message: `Insert valid format of ${item.name}`
                                            },
                                        minLength: (item.name === 'password') ?
                                            {
                                                value: 8,
                                                message: `${item.placeholder} must exceed 7 characters`
                                            }
                                            :
                                            {
                                                value: 3,
                                                message: `${item.name} must exceed 2 characters`
                                            },
                                        validate: {
                                            checkUserApi: async (x) => item.name === 'email' ?
                                                await fetch(`/manage/user-exist?email=${x.toLowerCase()}`, {
                                                    method: 'GET',
                                                    credentials: 'include',
                                                })
                                                    .then(r => {                    //il primo then ritorna la promise relativa allo stato
                                                        if (r.status === 200) {
                                                            return r.json();
                                                        } else {
                                                            throw new Error('Error');
                                                        }
                                                    })
                                                    .then((res) => res.success) || 'User just Exist'
                                                : null
                                        }
                                    }}
                                />

                            );
                        }
                    })
                }
            </form>
            <ModalFooter>
                <Btn size={'btn--sm'} styles={'btn--save'} onClick={handleSubmit(onSubmit)}>
                    <FaSave /> Create
                </Btn>
            </ModalFooter>

        </Modal >
    );
}
export default AddUser