
import React, { useState, useEffect, useRef } from 'react'
import styles from './DropDown.module.css';
import { AiOutlineDown } from 'react-icons/ai'

function DropDown(props) {

    const [open, setOpen] = useState(false);
    const [menuHeight, setMenuHeight] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setMenuHeight(dropdownRef.current?.children.offsetHeight)
    }, [open]);

    function DropdownItem(props) {
        
        return (
            <span className={styles.menuItem} onClick={() => {
                if (props.pos !== undefined) {
                    setOpen(!open);
                    props.setEmailSelect(s => ({ ...s, emailSelect: props.children }))
                }
            }}>
                <span className={styles.iconButton}>{props.leftIcon}</span>
                {props.children}
                <span className={styles.iconRight}>{props.rightIcon}</span>
            </span>
        );
    }

    return (
        <div className={styles.dropdown} height={menuHeight} ref={dropdownRef}>
            <span>
                <DropdownItem>{props.select}</DropdownItem>
                <AiOutlineDown className={open ? styles.down_icon : styles.left_icon} onClick={() => { setOpen(!open) }} />
            </span>
            <div className={styles.menu}>
                {
                    open &&
                    props.list.map((item, index) => {
                        return (
                            <DropdownItem
                                key={index}
                                pos={index}
                                email={item}
                                setEmailSelect={props.setEmailSelect}>
                                {item}
                            </DropdownItem>
                        );
                    })

                }

            </div>

        </div>
    )
}

export default DropDown
