import React  from 'react'
import './UserCard.css'
import { MdCancel  } from 'react-icons/md';

import styles from './UserCard.module.css'

const UserCard = ({ email, name, admin, image, setShowModal, setEditEmail, moveMode, setAlert, setDelUser }) => {

    const handleClick = () => {
        setAlert(s => ({
            ...s,
            active: true,
            type: 'warning',
            message: `Do you Want Delete ${name} ?`,
            time: false
        }));
        setDelUser(email);
    }

    return (
        <>
            <div className={styles.card + ` ${moveMode ? styles.move : ''}`}>
                {!moveMode && <MdCancel className={styles.icon_btn} onClick={handleClick} />}
                <div onClick={() => { if (moveMode !== true) { setShowModal(prev => !prev); setEditEmail(email) } }}>
                    <figure className={styles.pic_wrap} data-category={admin ? "admin" : "user"} >
                        <img className={styles.pic} alt={'1'} src={image} />
                    </figure>

                    <div className={styles.title} children={name} />
                </div>
            </div>
        </>

    )
}

export default UserCard
