import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Button.css'
import { fetchLogout } from './utilityFetch';

const STYLES = ['btn--primary', 'btn--outline'];

const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({ children, type, onClick, buttonStyle, buttonSize, destinationPath }) => {

    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];



    return (
        <Link to={destinationPath} className='my__btn__mobile'>
            <button className={`my__btn ${checkButtonStyle} ${checkButtonSize}`} onClick={onClick} type={type} >
                {children}
            </button>
        </Link>

    )
};


export const Btn = (props) => {

    const [size] = useState(props.size);
    const [variant] = useState(props.variant);
    const [style] = useState(props.styles);
    const [className] = useState(props.className);

    return (
        <button className={`my__btn ${size} ${variant} ${className} ${style}`} onClick={props.onClick}>
            {props.children}
        </button>
    );

}


export const LogoutButton = ({ type, onClick, buttonStyle, buttonSize }) => {
    const history = useHistory()


    const logout = () => {

        if (sessionStorage.getItem('casLog')) {


        }
        else {

            fetchLogout().then(r => {
                if (r.success) {
                    history.push('/');
                    window.location.reload('/');
                }
                else {
                    window.alert(r.message)
                }
            });
        }

    };


    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    if (type === 'mobile') {
        return (
            <span onClick={logout} >
                LOGOUT
            </span>
        )
    }

    else {
        return (

            <div className='my__btn__mobile'>
                <button className={`btn ${checkButtonStyle} ${checkButtonSize}`} onClick={logout}>
                    LOGOUT
                </button>
            </div>
        )
    }
}


export const Switch = ({ label, value, setValue, }) => {

    const handleClick = () => {
        setValue(!value)
    }

    return (
        <label children={label}>
            <span style={{ margin: '0px 5px  0 20px' }}>{label}</span>
            <input className='my__switch' style={{ fontSize: '30px' }} type="radio" checked={value} onClick={handleClick} />
            <span className={`my__slider round `}></span>
        </label>
    )
}