import React, { useState, useEffect } from 'react'
import styles from './HomeProjectCard.module.css'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

function HomeProjectCard(props) {

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch(`/projects/show-all-projects`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(r => {                    //il primo then ritorna la promise relativa allo stato
                if (r.status === 200) {
                    return r.json();
                } else {
                    throw new Error("Error");
                }
            })
            .then((res) => {       //il secondo il risultato
                if (res.success === true) {
                    if (window.innerWidth > 960)
                        setProjects(res.projects.slice(0, 5));
                    else
                        setProjects(res.projects.slice(0, 3));
                }
            })
            .catch((err) => {
            });

    }, []);

    return (
        <div className={styles.cardsWrapper} >
            {
                projects.map((item, index) => {
                    if (item.html_switch) {
                        return (
                            <motion.div
                                onClick={() =>item.link!=='' && window.open(item.link, "_blank")} key={index} style={{ textDecoration: 'none', color: 'var(--secondary)' }}
                                className={styles.card}
                                whileHover={{ scale: 1.1 }}
                                transition={{ type: 'spring', stiffness: 300, duration: 0.1 }}
                            >
                                <div>
                                    <div className={styles.imgWrap}>
                                        <img className={styles.img}  src={item.img_URL} alt="1" />
                                    </div>
                                </div>
                                <div className={styles.title}>
                                    {item.title}
                                </div>
                                <div className={styles.subtitle}>
                                    {item.subtitle.length > 80 ? item.subtitle.substring(0, 80) + '...' : item.subtitle}
                                </div>
                            </motion.div>
                        )
                    }
                    else {
                        return (
                            <Link to={"/project/" + item.title} key={index} style={{ textDecoration: 'none', color: 'var(--secondary)' }}>
                                <motion.div
                                    className={styles.card}
                                    whileHover={{ scale: 1.1 }}
                                    transition={{ type: 'spring', stiffness: 300, duration: 0.1 }}
                                >

                                    <div className={styles.imgWrap}>
                                        <img className={styles.img} src={item.img_URL} alt="1" />
                                    </div>
                                    <div className={styles.title}>
                                        {item.title}
                                    </div>
                                    <div className={styles.subtitle}>
                                        {item.subtitle.length > 80 ? item.subtitle.substring(0, 80) + '...' : item.subtitle}
                                    </div>
                                </motion.div>
                            </Link >
                        )
                    }
                })
            }
            <div className={styles.cardMore} >
                <Link to={'/projects'} className={styles.text}>
                    MORE...
                </Link>
            </div>
        </div >
    )

}

export default HomeProjectCard;
