import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import './Alert.css'

function AlertFade({ showAlert, onHideAlert, type, time = true, children, message, manage = true }) {

    const [customClass, setCustomClass] = useState('alertEnter')

    useEffect(() => {
        if (!showAlert) return;
        if (time) {
            setTimeout(() => {
                setCustomClass('alertExit');
                setTimeout(() => {
                    setCustomClass('alertEnter')
                    onHideAlert(false);
                }, time ? 500 : null);
            }, time ? 3000 : null);
        }
        return () => clearTimeout();
    }, [showAlert, time, onHideAlert]);

    const closeAlert = () => {
        setCustomClass('alertExit');
        setTimeout(() => {
            setCustomClass('alertEnter')
            onHideAlert(false);
        }, 2000);
    }


    if (!showAlert) return null;

    return (
        <>
            <div className={`alert-background ${manage ? 'manage' : ''}`}>
                <div className={`alert-wrapper ${customClass} `} style={{ background: time ? `var(--${type})` : null }} >
                    <div className={time ? null : `alert-header ${type}`}>
                        <span >
                            {time ? null : type}
                        </span>
                        {time ?
                            null
                            :
                            <IoClose className='alert-close-button' onClick={closeAlert} />
                        }

                    </div>
                    <div className={`alert-body`} style={time ? { color: 'var(--primary)', } : { paddingBottom: '4rem' }}>
                        {message}
                        {children}
                    </div>
                </div>
            </div>
        </>

    )
}

export default AlertFade
