import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form"
import { MyUserForm } from '../../Cards/MyUserCardItem';
import ChangePhoto from '../../utilities/ChangePhoto';
import { InputText, regex, TextArea, Switch } from '../../utilities/InputForm';
import AlertFade from '../../utilities/Alert';
import { FaExchangeAlt, FaSave } from 'react-icons/fa'
import { Btn } from '../../utilities/Button';
import './MyUserCard.css'
import ChangePassword from '../../utilities/ChangePassword';
import { fetchUpdateUser, fetchUploadPhoto } from '../../utilities/utilityFetch';

function MyUserCard(props) {

    const history = useHistory();
    const [loadingIcon, setLoadingIcon] = useState(false);

    const [showModal, setShowModal] = useState();

    const [img, setImg] = useState('');

    const [user, setUser] = useState({
        name: '',
        channel : '',
        role: '',
        email: '',
        phone: '',
        address: '',
        password: '',
        description: '',
        admin: false,
        img_URL: '',
        active: false,
        visible: false,
    });

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            name: '',
            channel:'',
            role: '',
            email: '',
            phone: '',
            address: '',
            password: '',
            description: '',
            admin: false,
            img_URL: '',
            active: false,
            visible: false,
        },
        mode: "onChange"
    });

    const [alert, setAlert] = useState({
        active: false,
        message: '',
        type: ''
    });

    useEffect(() => {
        reset(user);
    }, [user, reset])

    useEffect(() => {
        fetch(`/manage/get-user${props.editEmail ? '?email=' + props.editEmail : ''}`, {
            method: 'GET',
            credentials: "include"
        })
            .then(r => {                    //il primo then ritorna la promise relativa allo stato
                if (r.status === 200) {
                    return r.json();
                } else {
                    throw new Error('Error');
                }
            })
            .then((res) => {       //il secondo il risultato
                if (res.success === true) {
                    setUser(s => ({
                        ...s,
                        name: res.user.name,
                        channel : res.user.channel,
                        role: res.user.role,
                        email: res.user.email,
                        phone: res.user.phone,
                        address: res.user.address,
                        description: res.user.description,
                        admin: res.user.admin,
                        img_URL: res.user.img_URL,
                        active: res.user.active,
                        visible: res.user.visible
                    }))
                }
                else {
                    setAlert(s => ({ ...s, active: true, message: res.message, type: 'wrong' }));
                }
            })
            .catch((err) => {
                ;
            });
        window.scrollTo(0, 0);

    }, [props.editEmail]);


    const onSubmit = data => {
        setLoadingIcon(true)
        data.email = user.email

        fetchUpdateUser(data).then(r => {
            if (r.success) {
                if (img) {
                    fetchUploadPhoto(img, user.email, 'user').then(res => {
                        setAlert(s => ({ ...s, active: true, message: res.success ? 'User Update' : res.message, type: res.success ? 'success' : 'wrong' }))
                    })
                        .then(() => setLoadingIcon(false))
                        .then(() => (props.editEmail ? history.push('/manage/manage-users') : history.push('/manage/myuser')))
                }
                else {
                    setLoadingIcon(false)
                }
            }
            else {
                setAlert(s => ({ ...s, active: true, message: r.message }));
            }
        })

    };

    if (user.length === 0) {
        return (
            <div className="my-user-card-background">
                <div className='loader' />
            </div>
        )
    }

    return (
        <>
            {loadingIcon && <div className='loader' style={{ position: 'absolute', margin: '50% 50% 50% 55%', zIndex: 1000 }} />}

            <div className={props.editEmail ? '' : 'my-user-card-background'} style={loadingIcon ? { opacity: 0.1 } : {}}>
                <div className={props.editEmail ? '' : 'my-user-card-container'}>
                    <div style={{ marginTop: '2rem' }}>
                        <ChangePhoto image={user.img_URL} setImage={setImg} />
                    </div>
                    <div className='form-wrapper' >
                        {
                            MyUserForm.map((item, index) => {
                                switch (item.name) {
                                    case 'description':
                                        return (
                                            <TextArea
                                                key={index}
                                                name={item.name}
                                                placeholder={item.placeholder}
                                                control={control}
                                                label={item.icon}
                                            />
                                        );
                                    case 'admin':
                                        if (props.editEmail) {
                                            return (
                                                <Switch
                                                    key={index}
                                                    name={item.name}
                                                    type={item.type}
                                                    control={control}
                                                    label={item.icon}
                                                    content_t={'ADMIN'}
                                                    content_f={'USER'}
                                                />
                                            );
                                        } return null;

                                    case 'active':
                                        if (props.editEmail) {
                                            return (
                                                <Switch
                                                    key={index}
                                                    name={item.name}
                                                    type={item.type}
                                                    control={control}
                                                    label={item.icon}
                                                    content_t={'active'}
                                                    content_f={'disabled'}
                                                />
                                            );
                                        } return null;

                                    case 'visible':
                                        if (props.editEmail) {
                                            return (
                                                <Switch
                                                    key={index}
                                                    name={item.name}
                                                    type={item.type}
                                                    control={control}
                                                    label={item.icon}
                                                    content_t={'visible'}
                                                    content_f={'hidden'}
                                                />
                                            );
                                        } return null;

                                    default:
                                        return (
                                            <InputText
                                                key={index}
                                                name={item.name}
                                                placeholder={item.placeholder}
                                                type={item.type}
                                                control={control}
                                                label={item.icon}
                                                disabled={item.disabled}
                                                rules={{
                                                    required: item.required ? `${item.placeholder} is required.` : false,
                                                    pattern: {
                                                        value: regex[item.name],
                                                        message: `Insert valid ${item.name} number`
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: `${item.name} must exceed 2 characters`
                                                    },

                                                }}
                                            />
                                        );
                                }
                            })
                        }
                        <div style={{ display: 'inline-flex', marginTop: '1rem' }}>
                            <span style={{ margin: '1rem' }}>
                                <Btn size={'btn--md'} styles={'btn--save'} onClick={() => setShowModal(!showModal)}>
                                    <FaExchangeAlt /> Change Password
                                </Btn>
                            </span>

                            <span style={{ margin: '1rem' }}>
                                <Btn size={'btn--md'} styles={'btn--save'} onClick={handleSubmit(onSubmit)}>
                                    <FaSave /> Save
                                </Btn>
                            </span>
                        </div>
                    </div >
                </div>
            </div>
            <ChangePassword
                showModal={showModal}
                setShowModal={setShowModal}
                setAlert={setAlert}
                email={props.editEmail ? props.editEmail : user.email}
                admin={props?.user?.admin}
            />
            <AlertFade
                showAlert={alert.active}
                onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
                message={alert.message}
                type={alert.type}
            />
        </>

    );
}
export default MyUserCard