import React, { useState, useEffect, useMemo } from 'react';
import styles from './ManageHome.module.css'
import { FaEdit, FaCheck, FaPlusCircle } from 'react-icons/fa';
import { MdCancel, MdRemoveCircle } from 'react-icons/md'
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import NewHome from './NewHome';
import UpdateHome from './UpdateHome';
import AlertFade from '../../utilities/Alert';
import { Redirect } from 'react-router-dom';
import MotionHoC from '../../utilities/MotionHoC';
import { fetchCheckActiveUser, fetchDeleteInfoHome, fetchGetInfoHome, fetchUpdateInfoHome } from '../../utilities/utilityFetch';


function ManageHomeComponent(props) {

    const [checkActiveUser, setCheckActiveUser] = useState()
    useMemo(() => setCheckActiveUser(fetchCheckActiveUser()), [])

    const [text, setText] = useState([]);
    const [open, setOpen] = useState(false);
    const [newHome, setNewHome] = useState(false);
    const [id, setId] = useState();

    const [alert, setAlert] = useState({
        active: false,
        message: '',
        type: ''
    });

    useEffect(() => {
        fetchGetInfoHome()
            .then(r => r.success ?
                setText(r.text)
                : setAlert(s => ({ ...s, active: true, message: r.message, type: 'wrong' }))
            )
    }, []);


    const deleteClick = () => {
        fetchDeleteInfoHome(id)
            .then(r => {
                if (r.success) {
                    setAlert(s => ({ ...s, active: false, }))
                    fetchGetInfoHome()
                        .then(res => res.success && setText(res.text))

                } else {
                    setAlert(s => ({ ...s, active: true, message: r.message, type: 'wrong', time: true }))
                }
            })
    }


    const handleClick = (item) => {
        setId(item.id);
        setAlert(s => ({
            ...s,
            active: true,
            type: 'warning',
            message: `Do you Want Delete ${text[item.id].question?.length > 10 ? text[item.id].question.slice(0, 10) + '...' : text[item.id].question} ?`,
            time: false
        }));
    }

    const handleRLDDChange = (newItems) => {
        setText(newItems);

        newItems.map((item, index) =>
            item.id = index);

        var data = { 'list': newItems }

        fetchUpdateInfoHome(data).then(r => setAlert(s => ({ ...s, active: true, message: r.message, type: r.success ? 'success' : 'wrong' })))
    }

    if (text.length === 0) {
        return (
            <div className="my-user-card-background">
                <div className='loader' />
            </div>
        )
    }


    if (checkActiveUser && props.user.admin) {
        
        return (
            <>
                <div className={styles.container}>
                    <div className={styles.wrapper} >
                        <RLDD
                            items={text}
                            itemRenderer={item => {
                                return (
                                    <>
                                        <div className={styles.cardContainer}>
                                            <div className={styles.cardGrid} >
                                                <li>
                                                    <MdCancel className={styles.deleteIcon} onClick={() => { handleClick(item, true); }} />
                                                </li>
                                                <li className={styles.text}>
                                                    {
                                                        item.question !== '' ?
                                                            `${item.question?.slice(0, 10)} ${item.question?.length > 10 ? '...' : ''}`
                                                            :
                                                            `${item.answer?.slice(0, 10)} ${item.answer?.length > 10 ? '...' : ''}`
                                                    }
                                                </li>
                                                <li>
                                                    <FaEdit className={styles.editIcon} onClick={() => { setOpen(true); setId(item.id) }} />
                                                </li>
                                            </div>
                                        </div>
                                    </>
                                );
                            }}
                            onChange={handleRLDDChange}
                        />
                        <FaPlusCircle className={styles.add_icon} onClick={() => setNewHome(true)} />
                    </div>

                    <NewHome
                        position={text.length}
                        showModal={newHome}
                        setShowModal={setNewHome}
                        setAlert={setAlert}
                        setText={setText}
                    />

                    <UpdateHome
                        text={text[id]}
                        showModal={open}
                        setShowModal={setOpen}
                        setAlert={setAlert}
                        setText={setText}
                    />
                </div>

                <AlertFade
                    showAlert={alert.active}
                    onHideAlert={() => setAlert(s => ({ ...s, active: false }))}
                    message={alert.message}
                    type={alert.type}
                    time={alert.time}
                >
                    {alert.type === 'warning' ?
                        <div>
                            <MdRemoveCircle className={'icon-btn'} color='var(--wrong)' style={{ left: '10%' }} onClick={() => setAlert(s => ({ ...s, active: false }))} />
                            <FaCheck className={'icon-btn'} color='var(--success)' style={{ right: '10%' }} onClick={deleteClick} />
                        </div>
                        :
                        null}
                </AlertFade>
            </>
        );
    }
    else if (checkActiveUser === false) {
        sessionStorage.setItem('isMan', false);
        return <Redirect to='/login' />
    }
    else {
        return <div className='loader' />
    }
}

const ManageHome = MotionHoC(ManageHomeComponent)

export default ManageHome;
